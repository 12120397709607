@charset "UTF-8";
/**
 * Options
 */
/**
 * Colors
 */
/**
 * Fonts
 */
@import url("https://fonts.bunny.net/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.bunny.net/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap");
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #ffffff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #e3000b;
  --bs-secondary: #666666;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f7f8f9;
  --bs-dark: #19191c;
  --bs-primary-rgb: 227, 0, 11;
  --bs-secondary-rgb: 102, 102, 102;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 247, 248, 249;
  --bs-dark-rgb: 25, 25, 28;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 25, 25, 28;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: Helvetica, Arial, sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1.15rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 2;
  --bs-body-color: #19191c;
  --bs-body-bg: #ffffff; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Dosis", Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.413rem + 1.95vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.875rem; } }

h2, .h2 {
  font-size: calc(1.355rem + 1.26vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2.3rem; } }

h3, .h3 {
  font-size: calc(1.326rem + 0.915vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 2.012rem; } }

h4, .h4 {
  font-size: calc(1.298rem + 0.57vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.725rem; } }

h5, .h5 {
  font-size: calc(1.269rem + 0.225vw); }
  @media (min-width: 1200px) {
    h5, .h5 {
      font-size: 1.438rem; } }

h6, .h6 {
  font-size: 1.15rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: 800; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #e3000b;
  text-decoration: underline; }
  a:hover {
    color: #b60009; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #19191c;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: calc(1.269rem + 0.225vw);
  font-weight: 300; }
  @media (min-width: 1200px) {
    .lead {
      font-size: 1.438rem; } }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: calc(1.269rem + 0.225vw); }
  @media (min-width: 1200px) {
    .blockquote {
      font-size: 1.438rem; } }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.333%; }

.offset-2 {
  margin-left: 16.667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333%; }

.offset-5 {
  margin-left: 41.667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333%; }

.offset-8 {
  margin-left: 66.667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333%; }

.offset-11 {
  margin-left: 91.667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333%; }
  .offset-sm-2 {
    margin-left: 16.667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333%; }
  .offset-sm-5 {
    margin-left: 41.667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333%; }
  .offset-sm-8 {
    margin-left: 66.667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333%; }
  .offset-sm-11 {
    margin-left: 91.667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333%; }
  .offset-md-2 {
    margin-left: 16.667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333%; }
  .offset-md-5 {
    margin-left: 41.667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333%; }
  .offset-md-8 {
    margin-left: 66.667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333%; }
  .offset-md-11 {
    margin-left: 91.667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333%; }
  .offset-lg-2 {
    margin-left: 16.667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333%; }
  .offset-lg-5 {
    margin-left: 41.667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333%; }
  .offset-lg-8 {
    margin-left: 66.667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333%; }
  .offset-lg-11 {
    margin-left: 91.667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333%; }
  .offset-xl-2 {
    margin-left: 16.667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333%; }
  .offset-xl-5 {
    margin-left: 41.667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333%; }
  .offset-xl-8 {
    margin-left: 66.667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333%; }
  .offset-xl-11 {
    margin-left: 91.667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.333%; }
  .offset-xxl-2 {
    margin-left: 16.667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.333%; }
  .offset-xxl-5 {
    margin-left: 41.667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.333%; }
  .offset-xxl-8 {
    margin-left: 66.667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.333%; }
  .offset-xxl-11 {
    margin-left: 91.667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #19191c;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #19191c;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #19191c;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #19191c;
  vertical-align: top;
  border-color: #dee2e6; }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }
  .table > :not(:first-child) {
    border-top: 2px solid currentColor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #f9ccce;
  --bs-table-striped-bg: #edc2c4;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e0b8b9;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e6bdbf;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #e0b8b9; }

.table-secondary {
  --bs-table-bg: #e0e0e0;
  --bs-table-striped-bg: #d5d5d5;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #cacaca;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #cfcfcf;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #cacaca; }

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #bcd0c7; }

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #badce3; }

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #e6dbb9; }

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #dfc2c4; }

.table-light {
  --bs-table-bg: #f7f8f9;
  --bs-table-striped-bg: #ebeced;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #dedfe0;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e4e5e6;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #dedfe0; }

.table-dark {
  --bs-table-bg: #19191c;
  --bs-table-striped-bg: #252527;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #303033;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #2a2a2d;
  --bs-table-hover-color: #ffffff;
  color: #ffffff;
  border-color: #303033; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 2; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: calc(1.269rem + 0.225vw); }
  @media (min-width: 1200px) {
    .col-form-label-lg {
      font-size: 1.438rem; } }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 1.006rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #19191c; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.15rem;
  font-weight: 400;
  line-height: 2;
  color: #19191c;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #19191c;
    background-color: #ffffff;
    border-color: #f18085;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(227, 0, 11, 0.25); }
  .form-control::-webkit-date-and-time-value {
    height: 2em; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #19191c;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }
  .form-control::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #19191c;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 2;
  color: #19191c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(2em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 1.006rem;
  border-radius: 0.2rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(2em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: calc(1.269rem + 0.225vw);
  border-radius: 0.3rem; }
  @media (min-width: 1200px) {
    .form-control-lg {
      font-size: 1.438rem; } }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(2em + 0.75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(2em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(2em + 1rem + 2px); }

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 2em;
    border-radius: 0.25rem; }
  .form-control-color::-webkit-color-swatch {
    height: 2em;
    border-radius: 0.25rem; }

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1.15rem;
  font-weight: 400;
  line-height: 2;
  color: #19191c;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  .form-select:focus {
    border-color: #f18085;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(227, 0, 11, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #19191c; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 1.006rem;
  border-radius: 0.2rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.269rem + 0.225vw);
  border-radius: 0.3rem; }
  @media (min-width: 1200px) {
    .form-select-lg {
      font-size: 1.438rem; } }

.form-check {
  display: block;
  min-height: 2.3rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.5em;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #f18085;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(227, 0, 11, 0.25); }
  .form-check-input:checked {
    background-color: #e3000b;
    border-color: #e3000b; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #e3000b;
    border-color: #e3000b;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f18085'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(227, 0, 11, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(227, 0, 11, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #e3000b;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    .form-range::-webkit-slider-thumb:active {
      background-color: #f7b3b6; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #e3000b;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    .form-range::-moz-range-thumb:active {
      background-color: #f7b3b6; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
  .form-floating > .form-control {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1.15rem;
  font-weight: 400;
  line-height: 2;
  color: #19191c;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.269rem + 0.225vw);
  border-radius: 0.3rem; }
  @media (min-width: 1200px) {
    .input-group-lg > .form-control,
    .input-group-lg > .form-select,
    .input-group-lg > .input-group-text,
    .input-group-lg > .btn {
      font-size: 1.438rem; } }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1.006rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 1.006rem;
  color: #ffffff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(2em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.5em + 0.188rem) center;
  background-size: calc(1em + 0.375rem) calc(1em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(2em + 0.75rem);
  background-position: top calc(0.5em + 0.188rem) right calc(0.5em + 0.188rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(1em + 0.375rem) calc(1em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #198754; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #198754; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
  .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 1.006rem;
  color: #ffffff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(2em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.5em + 0.188rem) center;
  background-size: calc(1em + 0.375rem) calc(1em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(2em + 0.75rem);
  background-position: top calc(0.5em + 0.188rem) right calc(0.5em + 0.188rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(1em + 0.375rem) calc(1em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #dc3545; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
  .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3; }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 2;
  color: #19191c;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1.15rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover {
    color: #19191c; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(227, 0, 11, 0.25); }
  .btn-check:checked + .btn,
  .btn-check:active + .btn, .btn:active, .btn.active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
    .btn-check:checked + .btn:focus,
    .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
      box-shadow: 0 0 0 0.25rem rgba(227, 0, 11, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65;
    box-shadow: none; }

.btn-primary {
  color: #ffffff;
  background-color: #e3000b;
  border-color: #e3000b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #c10009;
    border-color: #b60009; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #ffffff;
    background-color: #c10009;
    border-color: #b60009;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(231, 38, 48, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #b60009;
    border-color: #aa0008; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(231, 38, 48, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #ffffff;
    background-color: #e3000b;
    border-color: #e3000b; }

.btn-secondary {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-secondary:hover {
    color: #ffffff;
    background-color: #575757;
    border-color: #525252; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #ffffff;
    background-color: #575757;
    border-color: #525252;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(125, 125, 125, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #525252;
    border-color: #4d4d4d; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(125, 125, 125, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #ffffff;
    background-color: #666666;
    border-color: #666666; }

.btn-success {
  color: #ffffff;
  background-color: #198754;
  border-color: #198754;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-success:hover {
    color: #ffffff;
    background-color: #157347;
    border-color: #146c43; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #ffffff;
    background-color: #157347;
    border-color: #146c43;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #146c43;
    border-color: #13653f; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #ffffff;
    background-color: #198754;
    border-color: #198754; }

.btn-info {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-info:hover {
    color: #000000;
    background-color: #31d2f2;
    border-color: #25cff2; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #000000;
    background-color: #31d2f2;
    border-color: #25cff2;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #000000;
    background-color: #3dd5f3;
    border-color: #25cff2; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #000000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }

.btn-warning {
  color: #000000;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-warning:hover {
    color: #000000;
    background-color: #ffca2c;
    border-color: #ffc720; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #000000;
    background-color: #ffca2c;
    border-color: #ffc720;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #000000;
    background-color: #ffcd39;
    border-color: #ffc720; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #000000;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-danger {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #bb2d3b;
    border-color: #b02a37; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #ffffff;
    background-color: #bb2d3b;
    border-color: #b02a37;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #b02a37;
    border-color: #a52834; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #ffffff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-light {
  color: #000000;
  background-color: #f7f8f9;
  border-color: #f7f8f9;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-light:hover {
    color: #000000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #000000;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(210, 211, 212, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #000000;
    background-color: #f9f9fa;
    border-color: #f8f9fa; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(210, 211, 212, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #000000;
    background-color: #f7f8f9;
    border-color: #f7f8f9; }

.btn-dark {
  color: #ffffff;
  background-color: #19191c;
  border-color: #19191c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #151518;
    border-color: #141416; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #ffffff;
    background-color: #151518;
    border-color: #141416;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(60, 60, 62, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #141416;
    border-color: #131315; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(60, 60, 62, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #ffffff;
    background-color: #19191c;
    border-color: #19191c; }

.btn-outline-primary {
  color: #e3000b;
  border-color: #e3000b; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #e3000b;
    border-color: #e3000b; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(227, 0, 11, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #ffffff;
    background-color: #e3000b;
    border-color: #e3000b; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(227, 0, 11, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #e3000b;
    background-color: transparent; }

.btn-outline-secondary {
  color: #666666;
  border-color: #666666; }
  .btn-outline-secondary:hover {
    color: #ffffff;
    background-color: #666666;
    border-color: #666666; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.25rem rgba(102, 102, 102, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #ffffff;
    background-color: #666666;
    border-color: #666666; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(102, 102, 102, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #666666;
    background-color: transparent; }

.btn-outline-success {
  color: #198754;
  border-color: #198754; }
  .btn-outline-success:hover {
    color: #ffffff;
    background-color: #198754;
    border-color: #198754; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #ffffff;
    background-color: #198754;
    border-color: #198754; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #198754;
    background-color: transparent; }

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-outline-info:hover {
    color: #000000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #000000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #0dcaf0;
    background-color: transparent; }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #000000;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #000000;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #ffc107;
    background-color: transparent; }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #ffffff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #dc3545;
    background-color: transparent; }

.btn-outline-light {
  color: #f7f8f9;
  border-color: #f7f8f9; }
  .btn-outline-light:hover {
    color: #000000;
    background-color: #f7f8f9;
    border-color: #f7f8f9; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.25rem rgba(247, 248, 249, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #000000;
    background-color: #f7f8f9;
    border-color: #f7f8f9; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(247, 248, 249, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #f7f8f9;
    background-color: transparent; }

.btn-outline-dark {
  color: #19191c;
  border-color: #19191c; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #19191c;
    border-color: #19191c; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 25, 28, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #ffffff;
    background-color: #19191c;
    border-color: #19191c; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(25, 25, 28, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #19191c;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #e3000b;
  text-decoration: underline; }
  .btn-link:hover {
    color: #b60009; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.269rem + 0.225vw);
  border-radius: 0.3rem; }
  @media (min-width: 1200px) {
    .btn-lg, .btn-group-lg > .btn {
      font-size: 1.438rem; } }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1.006rem;
  border-radius: 0.2rem; }

.fade {
  transition: opacity 0.15s linear; }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1.15rem;
  color: #19191c;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem; }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #1e2125;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #e3000b; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1.006rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #ffffff;
      background-color: #e3000b; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #adb5bd; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6; }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.562rem;
  padding-left: 0.562rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #e3000b;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  .nav-link:hover, .nav-link:focus {
    color: #b60009; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6;
      isolation: isolate; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #e3000b; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.212rem;
  padding-bottom: 0.212rem;
  margin-right: 1rem;
  font-size: calc(1.269rem + 0.225vw);
  text-decoration: none;
  white-space: nowrap; }
  @media (min-width: 1200px) {
    .navbar-brand {
      font-size: 1.438rem; } }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: calc(1.269rem + 0.225vw);
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out; }
  @media (min-width: 1200px) {
    .navbar-toggler {
      font-size: 1.438rem; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas-header {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas-header {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas-header {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas-header {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas-header {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas-header {
    display: none; }
  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none; }
  .navbar-expand .offcanvas-top,
  .navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0; }
  .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #ffffff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #ffffff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #ffffff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: 1rem; }

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-group > .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1.15rem;
  color: #19191c;
  text-align: left;
  background-color: #ffffff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
  .accordion-button:not(.collapsed) {
    color: #cc000a;
    background-color: #fce6e7;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23cc000a'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(-180deg); }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2319191c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out; }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: #f18085;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(227, 0, 11, 0.25); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .accordion-item:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #e3000b;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .page-link:hover {
    z-index: 2;
    color: #b60009;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    color: #b60009;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(227, 0, 11, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #e3000b;
  border-color: #e3000b; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: calc(1.269rem + 0.225vw); }
  @media (min-width: 1200px) {
    .pagination-lg .page-link {
      font-size: 1.438rem; } }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 1.006rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  color: #880007;
  background-color: #f9ccce;
  border-color: #f7b3b6; }
  .alert-primary .alert-link {
    color: #6d0006; }

.alert-secondary {
  color: #3d3d3d;
  background-color: #e0e0e0;
  border-color: #d1d1d1; }
  .alert-secondary .alert-link {
    color: #313131; }

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc; }
  .alert-success .alert-link {
    color: #0c4128; }

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb; }
  .alert-info .alert-link {
    color: #04414d; }

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5; }
  .alert-warning .alert-link {
    color: #523e02; }

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7; }
  .alert-danger .alert-link {
    color: #6a1a21; }

.alert-light {
  color: #636364;
  background-color: #fdfefe;
  border-color: #fdfdfd; }
  .alert-light .alert-link {
    color: #4f4f50; }

.alert-dark {
  color: #0f0f11;
  background-color: #d1d1d2;
  border-color: #bababb; }
  .alert-dark .alert-link {
    color: #0c0c0e; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.863rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); }
  @media (min-width: 1200px) {
    .progress {
      font-size: 0.862rem; } }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #e3000b;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #19191c;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #ffffff; }
  .list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #e3000b;
    border-color: #e3000b; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #880007;
  background-color: #f9ccce; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #880007;
    background-color: #e0b8b9; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #880007;
    border-color: #880007; }

.list-group-item-secondary {
  color: #3d3d3d;
  background-color: #e0e0e0; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #3d3d3d;
    background-color: #cacaca; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #3d3d3d;
    border-color: #3d3d3d; }

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0f5132;
    background-color: #bcd0c7; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #0f5132;
    border-color: #0f5132; }

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #055160;
    background-color: #badce3; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #055160;
    border-color: #055160; }

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #664d03;
    background-color: #e6dbb9; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #664d03;
    border-color: #664d03; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #842029;
    background-color: #dfc2c4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #842029;
    border-color: #842029; }

.list-group-item-light {
  color: #636364;
  background-color: #fdfefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #636364;
    background-color: #e4e5e5; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #636364;
    border-color: #636364; }

.list-group-item-dark {
  color: #0f0f11;
  background-color: #d1d1d2; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #0f0f11;
    background-color: #bcbcbd; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #0f0f11;
    border-color: #0f0f11; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(227, 0, 11, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: 0.75rem; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem; }

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 2; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 2;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.006rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      top: -1px;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000000; }

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      right: -1px;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000000; }

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      bottom: -1px;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000000; }

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      left: -1px;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 2;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.006rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #ffffff; }

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #ffffff; }

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #ffffff; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0; }

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #ffffff; }

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1.15rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 1rem 1rem;
  color: #19191c; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000; }

.carousel-dark .carousel-caption {
  color: #000000; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #ffffff;
  background-clip: padding-box;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  transition: transform 0.3s ease-in-out; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 2; }

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%); }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%); }

.offcanvas.show {
  transform: none; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000000 55%, rgba(0, 0, 0, 0.8) 75%, #000000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #e3000b; }
  .link-primary:hover, .link-primary:focus {
    color: #b60009; }

.link-secondary {
  color: #666666; }
  .link-secondary:hover, .link-secondary:focus {
    color: #525252; }

.link-success {
  color: #198754; }
  .link-success:hover, .link-success:focus {
    color: #146c43; }

.link-info {
  color: #0dcaf0; }
  .link-info:hover, .link-info:focus {
    color: #3dd5f3; }

.link-warning {
  color: #ffc107; }
  .link-warning:hover, .link-warning:focus {
    color: #ffcd39; }

.link-danger {
  color: #dc3545; }
  .link-danger:hover, .link-danger:focus {
    color: #b02a37; }

.link-light {
  color: #f7f8f9; }
  .link-light:hover, .link-light:focus {
    color: #f9f9fa; }

.link-dark {
  color: #19191c; }
  .link-dark:hover, .link-dark:focus {
    color: #141416; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #dee2e6 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #dee2e6 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #e3000b !important; }

.border-secondary {
  border-color: #666666 !important; }

.border-success {
  border-color: #198754 !important; }

.border-info {
  border-color: #0dcaf0 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f7f8f9 !important; }

.border-dark {
  border-color: #19191c !important; }

.border-white {
  border-color: #ffffff !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.413rem + 1.95vw) !important; }

.fs-2 {
  font-size: calc(1.355rem + 1.26vw) !important; }

.fs-3 {
  font-size: calc(1.326rem + 0.915vw) !important; }

.fs-4 {
  font-size: calc(1.298rem + 0.57vw) !important; }

.fs-5 {
  font-size: calc(1.269rem + 0.225vw) !important; }

.fs-6 {
  font-size: 1.15rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: 200 !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: 800 !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 2 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #19191c !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.2rem !important; }

.rounded-2 {
  border-radius: 0.25rem !important; }

.rounded-3 {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.875rem !important; }
  .fs-2 {
    font-size: 2.3rem !important; }
  .fs-3 {
    font-size: 2.012rem !important; }
  .fs-4 {
    font-size: 1.725rem !important; }
  .fs-5 {
    font-size: 1.438rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/*!
 * Font Awesome Pro 6.0.0-beta1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900); }

.fa,
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin,
.fad,
.fa-duotone,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em; }

.fa-xs {
  font-size: 0.75em;
  line-height: 0.083em;
  vertical-align: 0.125em; }

.fa-sm {
  font-size: 0.875em;
  line-height: 0.071em;
  vertical-align: 0.054em; }

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em; }

.fa-xl {
  font-size: 1.5em;
  line-height: 0.042em;
  vertical-align: -0.125em; }

.fa-2xl {
  font-size: 2em;
  line-height: 0.031em;
  vertical-align: -0.188em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit; }

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em); }

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em); }

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em); }

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-flash {
  animation-name: fa-flash;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin-reverse {
  --fa-animation-direction: reverse; }

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8)); }

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-fade,
  .fa-flash,
  .fa-flip,
  .fa-pulse,
  .fa-spin,
  .fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s; } }

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1); }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25)); } }

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4); } }

@keyframes fa-flash {
  0%, 100% {
    opacity: var(--fa-flash-opacity, 0.4);
    transform: scale(1); }
  50% {
    opacity: 1;
    transform: scale(var(--fa-flash-scale, 1.125)); } }

@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  transform: rotate(90deg); }

.fa-rotate-180 {
  transform: rotate(180deg); }

.fa-rotate-270 {
  transform: rotate(270deg); }

.fa-flip-horizontal {
  transform: scale(-1, 1); }

.fa-flip-vertical {
  transform: scale(1, -1); }

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1); }

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, none)); }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto); }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: var(--fa-inverse, #fff); }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0::before {
  content: "\30"; }

.fa-1::before {
  content: "\31"; }

.fa-2::before {
  content: "\32"; }

.fa-3::before {
  content: "\33"; }

.fa-4::before {
  content: "\34"; }

.fa-5::before {
  content: "\35"; }

.fa-6::before {
  content: "\36"; }

.fa-7::before {
  content: "\37"; }

.fa-8::before {
  content: "\38"; }

.fa-9::before {
  content: "\39"; }

.fa-360-degrees::before {
  content: "\e2dc"; }

.fa-a::before {
  content: "\41"; }

.fa-abacus::before {
  content: "\1f9ee"; }

.fa-accent-grave::before {
  content: "\60"; }

.fa-acorn::before {
  content: "\f6ae"; }

.fa-address-book::before {
  content: "\f2b9"; }

.fa-contact-book::before {
  content: "\f2b9"; }

.fa-address-card::before {
  content: "\f2bb"; }

.fa-contact-card::before {
  content: "\f2bb"; }

.fa-vcard::before {
  content: "\f2bb"; }

.fa-air-conditioner::before {
  content: "\f8f4"; }

.fa-airplay::before {
  content: "\e089"; }

.fa-alarm-clock::before {
  content: "\23f0"; }

.fa-alarm-exclamation::before {
  content: "\f843"; }

.fa-alarm-plus::before {
  content: "\f844"; }

.fa-alarm-snooze::before {
  content: "\f845"; }

.fa-album::before {
  content: "\1f4bd"; }

.fa-album-collection::before {
  content: "\f8a0"; }

.fa-alicorn::before {
  content: "\f6b0"; }

.fa-alien::before {
  content: "\1f47d"; }

.fa-alien-8bit::before {
  content: "\1f47e"; }

.fa-alien-monster::before {
  content: "\1f47e"; }

.fa-align-center::before {
  content: "\f037"; }

.fa-align-justify::before {
  content: "\f039"; }

.fa-align-left::before {
  content: "\f036"; }

.fa-align-right::before {
  content: "\f038"; }

.fa-align-slash::before {
  content: "\f846"; }

.fa-alt::before {
  content: "\e08a"; }

.fa-amp-guitar::before {
  content: "\f8a1"; }

.fa-ampersand::before {
  content: "\26"; }

.fa-anchor::before {
  content: "\2693"; }

.fa-angel::before {
  content: "\f779"; }

.fa-angle::before {
  content: "\e08c"; }

.fa-angle-90::before {
  content: "\e08d"; }

.fa-angle-down::before {
  content: "\2304"; }

.fa-angle-left::before {
  content: "\2039"; }

.fa-angle-right::before {
  content: "\203a"; }

.fa-angle-up::before {
  content: "\2303"; }

.fa-angles-down::before {
  content: "\f103"; }

.fa-angle-double-down::before {
  content: "\f103"; }

.fa-angles-left::before {
  content: "\ab"; }

.fa-angle-double-left::before {
  content: "\ab"; }

.fa-angles-right::before {
  content: "\bb"; }

.fa-angle-double-right::before {
  content: "\bb"; }

.fa-angles-up::before {
  content: "\f102"; }

.fa-angle-double-up::before {
  content: "\f102"; }

.fa-ankh::before {
  content: "\2625"; }

.fa-aperture::before {
  content: "\e2df"; }

.fa-apostrophe::before {
  content: "\27"; }

.fa-apple-core::before {
  content: "\e08f"; }

.fa-apple-whole::before {
  content: "\1f34e"; }

.fa-apple-alt::before {
  content: "\1f34e"; }

.fa-archway::before {
  content: "\f557"; }

.fa-arrow-down::before {
  content: "\2193"; }

.fa-arrow-down-1-9::before {
  content: "\f162"; }

.fa-sort-numeric-asc::before {
  content: "\f162"; }

.fa-sort-numeric-down::before {
  content: "\f162"; }

.fa-arrow-down-9-1::before {
  content: "\f886"; }

.fa-sort-numeric-desc::before {
  content: "\f886"; }

.fa-sort-numeric-down-alt::before {
  content: "\f886"; }

.fa-arrow-down-a-z::before {
  content: "\f15d"; }

.fa-sort-alpha-asc::before {
  content: "\f15d"; }

.fa-sort-alpha-down::before {
  content: "\f15d"; }

.fa-arrow-down-arrow-up::before {
  content: "\f883"; }

.fa-sort-alt::before {
  content: "\f883"; }

.fa-arrow-down-big-small::before {
  content: "\f88c"; }

.fa-sort-size-down::before {
  content: "\f88c"; }

.fa-arrow-down-from-dotted-line::before {
  content: "\e090"; }

.fa-arrow-down-from-line::before {
  content: "\21a7"; }

.fa-arrow-from-top::before {
  content: "\21a7"; }

.fa-arrow-down-left::before {
  content: "\e091"; }

.fa-arrow-down-left-and-arrow-up-right-to-center::before {
  content: "\e092"; }

.fa-arrow-down-long::before {
  content: "\f175"; }

.fa-long-arrow-down::before {
  content: "\f175"; }

.fa-arrow-down-right::before {
  content: "\e093"; }

.fa-arrow-down-short-wide::before {
  content: "\f884"; }

.fa-sort-amount-desc::before {
  content: "\f884"; }

.fa-sort-amount-down-alt::before {
  content: "\f884"; }

.fa-arrow-down-small-big::before {
  content: "\f88d"; }

.fa-sort-size-down-alt::before {
  content: "\f88d"; }

.fa-arrow-down-square-triangle::before {
  content: "\f889"; }

.fa-sort-shapes-down-alt::before {
  content: "\f889"; }

.fa-arrow-down-to-bracket::before {
  content: "\e094"; }

.fa-arrow-down-to-dotted-line::before {
  content: "\e095"; }

.fa-arrow-down-to-line::before {
  content: "\f33d"; }

.fa-arrow-to-bottom::before {
  content: "\f33d"; }

.fa-arrow-down-to-square::before {
  content: "\e096"; }

.fa-arrow-down-triangle-square::before {
  content: "\f888"; }

.fa-sort-shapes-down::before {
  content: "\f888"; }

.fa-arrow-down-wide-short::before {
  content: "\f160"; }

.fa-sort-amount-asc::before {
  content: "\f160"; }

.fa-sort-amount-down::before {
  content: "\f160"; }

.fa-arrow-down-z-a::before {
  content: "\f881"; }

.fa-sort-alpha-desc::before {
  content: "\f881"; }

.fa-sort-alpha-down-alt::before {
  content: "\f881"; }

.fa-arrow-left::before {
  content: "\2190"; }

.fa-arrow-left-from-line::before {
  content: "\21a4"; }

.fa-arrow-from-right::before {
  content: "\21a4"; }

.fa-arrow-left-long::before {
  content: "\f177"; }

.fa-long-arrow-left::before {
  content: "\f177"; }

.fa-arrow-left-to-line::before {
  content: "\21e4"; }

.fa-arrow-to-left::before {
  content: "\21e4"; }

.fa-arrow-pointer::before {
  content: "\f245"; }

.fa-mouse-pointer::before {
  content: "\f245"; }

.fa-arrow-right::before {
  content: "\2192"; }

.fa-arrow-right-arrow-left::before {
  content: "\21c4"; }

.fa-exchange::before {
  content: "\21c4"; }

.fa-arrow-right-from-bracket::before {
  content: "\f08b"; }

.fa-sign-out::before {
  content: "\f08b"; }

.fa-arrow-right-from-line::before {
  content: "\21a6"; }

.fa-arrow-from-left::before {
  content: "\21a6"; }

.fa-arrow-right-long::before {
  content: "\f178"; }

.fa-long-arrow-right::before {
  content: "\f178"; }

.fa-arrow-right-to-bracket::before {
  content: "\f090"; }

.fa-sign-in::before {
  content: "\f090"; }

.fa-arrow-right-to-line::before {
  content: "\21e5"; }

.fa-arrow-to-right::before {
  content: "\21e5"; }

.fa-arrow-rotate-left::before {
  content: "\21ba"; }

.fa-arrow-left-rotate::before {
  content: "\21ba"; }

.fa-arrow-rotate-back::before {
  content: "\21ba"; }

.fa-arrow-rotate-backward::before {
  content: "\21ba"; }

.fa-undo::before {
  content: "\21ba"; }

.fa-arrow-rotate-right::before {
  content: "\21bb"; }

.fa-arrow-right-rotate::before {
  content: "\21bb"; }

.fa-arrow-rotate-forward::before {
  content: "\21bb"; }

.fa-redo::before {
  content: "\21bb"; }

.fa-arrow-trend-down::before {
  content: "\e097"; }

.fa-arrow-trend-up::before {
  content: "\e098"; }

.fa-arrow-turn-down::before {
  content: "\f149"; }

.fa-level-down::before {
  content: "\f149"; }

.fa-arrow-turn-down-left::before {
  content: "\e2e1"; }

.fa-arrow-turn-up::before {
  content: "\f148"; }

.fa-level-up::before {
  content: "\f148"; }

.fa-arrow-up::before {
  content: "\2191"; }

.fa-arrow-up-1-9::before {
  content: "\f163"; }

.fa-sort-numeric-up::before {
  content: "\f163"; }

.fa-arrow-up-9-1::before {
  content: "\f887"; }

.fa-sort-numeric-up-alt::before {
  content: "\f887"; }

.fa-arrow-up-a-z::before {
  content: "\f15e"; }

.fa-sort-alpha-up::before {
  content: "\f15e"; }

.fa-arrow-up-arrow-down::before {
  content: "\e099"; }

.fa-sort-up-down::before {
  content: "\e099"; }

.fa-arrow-up-big-small::before {
  content: "\f88e"; }

.fa-sort-size-up::before {
  content: "\f88e"; }

.fa-arrow-up-from-bracket::before {
  content: "\e09a"; }

.fa-arrow-up-from-dotted-line::before {
  content: "\e09b"; }

.fa-arrow-up-from-line::before {
  content: "\21a5"; }

.fa-arrow-from-bottom::before {
  content: "\21a5"; }

.fa-arrow-up-from-square::before {
  content: "\e09c"; }

.fa-arrow-up-left::before {
  content: "\e09d"; }

.fa-arrow-up-left-from-circle::before {
  content: "\e09e"; }

.fa-arrow-up-long::before {
  content: "\f176"; }

.fa-long-arrow-up::before {
  content: "\f176"; }

.fa-arrow-up-right::before {
  content: "\e09f"; }

.fa-arrow-up-right-and-arrow-down-left-from-center::before {
  content: "\e0a0"; }

.fa-arrow-up-right-from-square::before {
  content: "\f08e"; }

.fa-external-link::before {
  content: "\f08e"; }

.fa-arrow-up-short-wide::before {
  content: "\f885"; }

.fa-sort-amount-up-alt::before {
  content: "\f885"; }

.fa-arrow-up-small-big::before {
  content: "\f88f"; }

.fa-sort-size-up-alt::before {
  content: "\f88f"; }

.fa-arrow-up-square-triangle::before {
  content: "\f88b"; }

.fa-sort-shapes-up-alt::before {
  content: "\f88b"; }

.fa-arrow-up-to-dotted-line::before {
  content: "\e0a1"; }

.fa-arrow-up-to-line::before {
  content: "\f341"; }

.fa-arrow-to-top::before {
  content: "\f341"; }

.fa-arrow-up-triangle-square::before {
  content: "\f88a"; }

.fa-sort-shapes-up::before {
  content: "\f88a"; }

.fa-arrow-up-wide-short::before {
  content: "\f161"; }

.fa-sort-amount-up::before {
  content: "\f161"; }

.fa-arrow-up-z-a::before {
  content: "\f882"; }

.fa-sort-alpha-up-alt::before {
  content: "\f882"; }

.fa-arrows-cross::before {
  content: "\e0a2"; }

.fa-arrows-from-dotted-line::before {
  content: "\e0a3"; }

.fa-arrows-from-line::before {
  content: "\e0a4"; }

.fa-arrows-left-right::before {
  content: "\f07e"; }

.fa-arrows-h::before {
  content: "\f07e"; }

.fa-arrows-maximize::before {
  content: "\f31d"; }

.fa-expand-arrows::before {
  content: "\f31d"; }

.fa-arrows-minimize::before {
  content: "\e0a5"; }

.fa-compress-arrows::before {
  content: "\e0a5"; }

.fa-arrows-repeat::before {
  content: "\f364"; }

.fa-repeat-alt::before {
  content: "\f364"; }

.fa-arrows-repeat-1::before {
  content: "\f366"; }

.fa-repeat-1-alt::before {
  content: "\f366"; }

.fa-arrows-retweet::before {
  content: "\f361"; }

.fa-retweet-alt::before {
  content: "\f361"; }

.fa-arrows-rotate::before {
  content: "\1f5d8"; }

.fa-refresh::before {
  content: "\1f5d8"; }

.fa-sync::before {
  content: "\1f5d8"; }

.fa-arrows-to-dotted-line::before {
  content: "\e0a6"; }

.fa-arrows-to-line::before {
  content: "\e0a7"; }

.fa-arrows-up-down::before {
  content: "\f07d"; }

.fa-arrows-v::before {
  content: "\f07d"; }

.fa-arrows-up-down-left-right::before {
  content: "\f047"; }

.fa-arrows::before {
  content: "\f047"; }

.fa-asterisk::before {
  content: "\2a"; }

.fa-at::before {
  content: "\40"; }

.fa-atom::before {
  content: "\269b"; }

.fa-atom-simple::before {
  content: "\f5d3"; }

.fa-atom-alt::before {
  content: "\f5d3"; }

.fa-audio-description::before {
  content: "\f29e"; }

.fa-audio-description-slash::before {
  content: "\e0a8"; }

.fa-austral-sign::before {
  content: "\e0a9"; }

.fa-avocado::before {
  content: "\e0aa"; }

.fa-award::before {
  content: "\f559"; }

.fa-award-simple::before {
  content: "\e0ab"; }

.fa-axe::before {
  content: "\1fa93"; }

.fa-axe-battle::before {
  content: "\f6b3"; }

.fa-b::before {
  content: "\42"; }

.fa-baby::before {
  content: "\f77c"; }

.fa-baby-carriage::before {
  content: "\f77d"; }

.fa-carriage-baby::before {
  content: "\f77d"; }

.fa-backpack::before {
  content: "\1f392"; }

.fa-backward::before {
  content: "\23ea"; }

.fa-backward-fast::before {
  content: "\23ee"; }

.fa-fast-backward::before {
  content: "\23ee"; }

.fa-backward-step::before {
  content: "\f048"; }

.fa-step-backward::before {
  content: "\f048"; }

.fa-bacon::before {
  content: "\1f953"; }

.fa-bacteria::before {
  content: "\e059"; }

.fa-bacterium::before {
  content: "\e05a"; }

.fa-badge::before {
  content: "\f335"; }

.fa-badge-check::before {
  content: "\f336"; }

.fa-badge-dollar::before {
  content: "\f645"; }

.fa-badge-percent::before {
  content: "\f646"; }

.fa-badge-sheriff::before {
  content: "\f8a2"; }

.fa-badger-honey::before {
  content: "\1f9a1"; }

.fa-badminton::before {
  content: "\e33a"; }

.fa-bag-shopping::before {
  content: "\f290"; }

.fa-shopping-bag::before {
  content: "\f290"; }

.fa-bags-shopping::before {
  content: "\1f6cd"; }

.fa-bahai::before {
  content: "\f666"; }

.fa-baht-sign::before {
  content: "\e0ac"; }

.fa-ball-pile::before {
  content: "\f77e"; }

.fa-balloon::before {
  content: "\e2e3"; }

.fa-balloons::before {
  content: "\e2e4"; }

.fa-ballot::before {
  content: "\f732"; }

.fa-ballot-check::before {
  content: "\f733"; }

.fa-ban::before {
  content: "\1f6ab"; }

.fa-cancel::before {
  content: "\1f6ab"; }

.fa-ban-bug::before {
  content: "\f7f9"; }

.fa-debug::before {
  content: "\f7f9"; }

.fa-ban-parking::before {
  content: "\f616"; }

.fa-parking-circle-slash::before {
  content: "\f616"; }

.fa-ban-smoking::before {
  content: "\1f6ad"; }

.fa-smoking-ban::before {
  content: "\1f6ad"; }

.fa-banana::before {
  content: "\e2e5"; }

.fa-bandage::before {
  content: "\1fa79"; }

.fa-band-aid::before {
  content: "\1fa79"; }

.fa-bangladeshi-taka-sign::before {
  content: "\e2e6"; }

.fa-banjo::before {
  content: "\1fa95"; }

.fa-bank::before {
  content: "\f19c"; }

.fa-institution::before {
  content: "\f19c"; }

.fa-university::before {
  content: "\f19c"; }

.fa-barcode::before {
  content: "\f02a"; }

.fa-barcode-read::before {
  content: "\f464"; }

.fa-barcode-scan::before {
  content: "\f465"; }

.fa-bars::before {
  content: "\f0c9"; }

.fa-navicon::before {
  content: "\f0c9"; }

.fa-bars-filter::before {
  content: "\e0ad"; }

.fa-bars-progress::before {
  content: "\f828"; }

.fa-tasks-alt::before {
  content: "\f828"; }

.fa-bars-sort::before {
  content: "\e0ae"; }

.fa-bars-staggered::before {
  content: "\f550"; }

.fa-reorder::before {
  content: "\f550"; }

.fa-stream::before {
  content: "\f550"; }

.fa-baseball::before {
  content: "\26be"; }

.fa-baseball-ball::before {
  content: "\26be"; }

.fa-baseball-bat-ball::before {
  content: "\f432"; }

.fa-basket-shopping::before {
  content: "\f291"; }

.fa-shopping-basket::before {
  content: "\f291"; }

.fa-basket-shopping-simple::before {
  content: "\e0af"; }

.fa-shopping-basket-alt::before {
  content: "\e0af"; }

.fa-basketball::before {
  content: "\1f3c0"; }

.fa-basketball-ball::before {
  content: "\1f3c0"; }

.fa-basketball-hoop::before {
  content: "\f435"; }

.fa-bat::before {
  content: "\f6b5"; }

.fa-bath::before {
  content: "\1f6c1"; }

.fa-bathtub::before {
  content: "\1f6c1"; }

.fa-battery-bolt::before {
  content: "\f376"; }

.fa-battery-empty::before {
  content: "\f244"; }

.fa-battery-0::before {
  content: "\f244"; }

.fa-battery-exclamation::before {
  content: "\e0b0"; }

.fa-battery-full::before {
  content: "\1f50b"; }

.fa-battery::before {
  content: "\1f50b"; }

.fa-battery-5::before {
  content: "\1f50b"; }

.fa-battery-half::before {
  content: "\f242"; }

.fa-battery-3::before {
  content: "\f242"; }

.fa-battery-low::before {
  content: "\e0b1"; }

.fa-battery-1::before {
  content: "\e0b1"; }

.fa-battery-quarter::before {
  content: "\f243"; }

.fa-battery-2::before {
  content: "\f243"; }

.fa-battery-slash::before {
  content: "\f377"; }

.fa-battery-three-quarters::before {
  content: "\f241"; }

.fa-battery-4::before {
  content: "\f241"; }

.fa-bed::before {
  content: "\1f6cc"; }

.fa-bed-bunk::before {
  content: "\f8f8"; }

.fa-bed-empty::before {
  content: "\1f6cf"; }

.fa-bed-front::before {
  content: "\f8f7"; }

.fa-bed-alt::before {
  content: "\f8f7"; }

.fa-bed-pulse::before {
  content: "\f487"; }

.fa-procedures::before {
  content: "\f487"; }

.fa-bee::before {
  content: "\e0b2"; }

.fa-beer-mug::before {
  content: "\e0b3"; }

.fa-beer-foam::before {
  content: "\e0b3"; }

.fa-beer-mug-empty::before {
  content: "\f0fc"; }

.fa-beer::before {
  content: "\f0fc"; }

.fa-bell::before {
  content: "\1f514"; }

.fa-bell-concierge::before {
  content: "\1f6ce"; }

.fa-concierge-bell::before {
  content: "\1f6ce"; }

.fa-bell-exclamation::before {
  content: "\f848"; }

.fa-bell-on::before {
  content: "\1f56d"; }

.fa-bell-plus::before {
  content: "\f849"; }

.fa-bell-school::before {
  content: "\f5d5"; }

.fa-bell-school-slash::before {
  content: "\f5d6"; }

.fa-bell-slash::before {
  content: "\1f515"; }

.fa-bells::before {
  content: "\f77f"; }

.fa-bench-tree::before {
  content: "\e2e7"; }

.fa-bezier-curve::before {
  content: "\f55b"; }

.fa-bicycle::before {
  content: "\1f6b2"; }

.fa-binary::before {
  content: "\e33b"; }

.fa-binary-circle-check::before {
  content: "\e33c"; }

.fa-binary-lock::before {
  content: "\e33d"; }

.fa-binary-slash::before {
  content: "\e33e"; }

.fa-binoculars::before {
  content: "\f1e5"; }

.fa-biohazard::before {
  content: "\2623"; }

.fa-bitcoin-sign::before {
  content: "\e0b4"; }

.fa-blanket::before {
  content: "\f498"; }

.fa-blender::before {
  content: "\f517"; }

.fa-blender-phone::before {
  content: "\f6b6"; }

.fa-blinds::before {
  content: "\f8fb"; }

.fa-blinds-open::before {
  content: "\f8fc"; }

.fa-blinds-raised::before {
  content: "\f8fd"; }

.fa-block-quote::before {
  content: "\e0b5"; }

.fa-blog::before {
  content: "\f781"; }

.fa-blueberries::before {
  content: "\e2e8"; }

.fa-bold::before {
  content: "\f032"; }

.fa-bolt::before {
  content: "\26a1"; }

.fa-flash::before {
  content: "\26a1"; }

.fa-bolt-auto::before {
  content: "\e0b6"; }

.fa-bolt-lightning::before {
  content: "\e0b7"; }

.fa-bolt-slash::before {
  content: "\e0b8"; }

.fa-bomb::before {
  content: "\1f4a3"; }

.fa-bone::before {
  content: "\1f9b4"; }

.fa-bone-break::before {
  content: "\f5d8"; }

.fa-bong::before {
  content: "\f55c"; }

.fa-book::before {
  content: "\1f4d4"; }

.fa-book-arrow-right::before {
  content: "\e0b9"; }

.fa-book-arrow-up::before {
  content: "\e0ba"; }

.fa-book-atlas::before {
  content: "\f558"; }

.fa-atlas::before {
  content: "\f558"; }

.fa-book-bible::before {
  content: "\f647"; }

.fa-bible::before {
  content: "\f647"; }

.fa-book-blank::before {
  content: "\1f4d5"; }

.fa-book-alt::before {
  content: "\1f4d5"; }

.fa-book-bookmark::before {
  content: "\e0bb"; }

.fa-book-circle-arrow-right::before {
  content: "\e0bc"; }

.fa-book-circle-arrow-up::before {
  content: "\e0bd"; }

.fa-book-copy::before {
  content: "\e0be"; }

.fa-book-font::before {
  content: "\e0bf"; }

.fa-book-heart::before {
  content: "\f499"; }

.fa-book-journal-whills::before {
  content: "\f66a"; }

.fa-journal-whills::before {
  content: "\f66a"; }

.fa-book-medical::before {
  content: "\f7e6"; }

.fa-book-open::before {
  content: "\1f4d6"; }

.fa-book-open-cover::before {
  content: "\e0c0"; }

.fa-book-open-alt::before {
  content: "\e0c0"; }

.fa-book-open-reader::before {
  content: "\f5da"; }

.fa-book-reader::before {
  content: "\f5da"; }

.fa-book-quran::before {
  content: "\f687"; }

.fa-quran::before {
  content: "\f687"; }

.fa-book-section::before {
  content: "\e0c1"; }

.fa-book-law::before {
  content: "\e0c1"; }

.fa-book-skull::before {
  content: "\f6b7"; }

.fa-book-dead::before {
  content: "\f6b7"; }

.fa-book-sparkles::before {
  content: "\f6b8"; }

.fa-book-spells::before {
  content: "\f6b8"; }

.fa-book-tanakh::before {
  content: "\f827"; }

.fa-tanakh::before {
  content: "\f827"; }

.fa-book-user::before {
  content: "\f7e7"; }

.fa-bookmark::before {
  content: "\1f516"; }

.fa-bookmark-slash::before {
  content: "\e0c2"; }

.fa-books::before {
  content: "\1f4da"; }

.fa-books-medical::before {
  content: "\f7e8"; }

.fa-boombox::before {
  content: "\1f4fe"; }

.fa-boot::before {
  content: "\1f97e"; }

.fa-boot-heeled::before {
  content: "\e33f"; }

.fa-booth-curtain::before {
  content: "\f734"; }

.fa-border-all::before {
  content: "\f84c"; }

.fa-border-bottom::before {
  content: "\f84d"; }

.fa-border-bottom-right::before {
  content: "\f854"; }

.fa-border-style-alt::before {
  content: "\f854"; }

.fa-border-center-h::before {
  content: "\f89c"; }

.fa-border-center-v::before {
  content: "\f89d"; }

.fa-border-inner::before {
  content: "\f84e"; }

.fa-border-left::before {
  content: "\f84f"; }

.fa-border-none::before {
  content: "\f850"; }

.fa-border-outer::before {
  content: "\f851"; }

.fa-border-right::before {
  content: "\f852"; }

.fa-border-top::before {
  content: "\f855"; }

.fa-border-top-left::before {
  content: "\f853"; }

.fa-border-style::before {
  content: "\f853"; }

.fa-bow-arrow::before {
  content: "\1f3f9"; }

.fa-bowl-chopsticks::before {
  content: "\e2e9"; }

.fa-bowl-chopsticks-noodles::before {
  content: "\e2ea"; }

.fa-bowl-hot::before {
  content: "\f823"; }

.fa-soup::before {
  content: "\f823"; }

.fa-bowl-rice::before {
  content: "\e2eb"; }

.fa-bowling-ball::before {
  content: "\f436"; }

.fa-bowling-ball-pin::before {
  content: "\e0c3"; }

.fa-bowling-pins::before {
  content: "\f437"; }

.fa-box::before {
  content: "\1f4e6"; }

.fa-box-archive::before {
  content: "\f187"; }

.fa-archive::before {
  content: "\f187"; }

.fa-box-ballot::before {
  content: "\1f5f3"; }

.fa-box-check::before {
  content: "\f467"; }

.fa-box-circle-check::before {
  content: "\e0c4"; }

.fa-box-dollar::before {
  content: "\f4a0"; }

.fa-box-usd::before {
  content: "\f4a0"; }

.fa-box-heart::before {
  content: "\f49d"; }

.fa-box-open::before {
  content: "\f49e"; }

.fa-box-open-full::before {
  content: "\f49c"; }

.fa-box-full::before {
  content: "\f49c"; }

.fa-box-taped::before {
  content: "\f49a"; }

.fa-box-alt::before {
  content: "\f49a"; }

.fa-box-tissue::before {
  content: "\e05b"; }

.fa-boxes-stacked::before {
  content: "\f468"; }

.fa-boxes::before {
  content: "\f468"; }

.fa-boxes-alt::before {
  content: "\f468"; }

.fa-boxing-glove::before {
  content: "\1f94a"; }

.fa-glove-boxing::before {
  content: "\1f94a"; }

.fa-bracket-curly::before {
  content: "\7b"; }

.fa-bracket-curly-left::before {
  content: "\7b"; }

.fa-bracket-curly-right::before {
  content: "\7d"; }

.fa-bracket-round::before {
  content: "\28"; }

.fa-parenthesis::before {
  content: "\28"; }

.fa-bracket-round-right::before {
  content: "\29"; }

.fa-bracket-square::before {
  content: "\5b"; }

.fa-bracket::before {
  content: "\5b"; }

.fa-bracket-left::before {
  content: "\5b"; }

.fa-bracket-square-right::before {
  content: "\5d"; }

.fa-brackets-curly::before {
  content: "\f7ea"; }

.fa-brackets-round::before {
  content: "\e0c5"; }

.fa-parentheses::before {
  content: "\e0c5"; }

.fa-brackets-square::before {
  content: "\f7e9"; }

.fa-brackets::before {
  content: "\f7e9"; }

.fa-braille::before {
  content: "\f2a1"; }

.fa-brain::before {
  content: "\1f9e0"; }

.fa-brain-arrow-curved-right::before {
  content: "\f677"; }

.fa-mind-share::before {
  content: "\f677"; }

.fa-brain-circuit::before {
  content: "\e0c6"; }

.fa-brake-warning::before {
  content: "\e0c7"; }

.fa-bread-loaf::before {
  content: "\1f35e"; }

.fa-bread-slice::before {
  content: "\f7ec"; }

.fa-briefcase::before {
  content: "\1f4bc"; }

.fa-briefcase-arrow-right::before {
  content: "\e2f2"; }

.fa-briefcase-blank::before {
  content: "\e0c8"; }

.fa-briefcase-clock::before {
  content: "\f64a"; }

.fa-business-time::before {
  content: "\f64a"; }

.fa-briefcase-medical::before {
  content: "\f469"; }

.fa-brightness::before {
  content: "\e0c9"; }

.fa-brightness-low::before {
  content: "\e0ca"; }

.fa-bring-forward::before {
  content: "\f856"; }

.fa-bring-front::before {
  content: "\f857"; }

.fa-broom::before {
  content: "\1f9f9"; }

.fa-browser::before {
  content: "\1f5d4"; }

.fa-browsers::before {
  content: "\e0cb"; }

.fa-brush::before {
  content: "\f55d"; }

.fa-bug::before {
  content: "\f188"; }

.fa-building::before {
  content: "\1f3e2"; }

.fa-buildings::before {
  content: "\e0cc"; }

.fa-bullhorn::before {
  content: "\1f4e2"; }

.fa-bullseye::before {
  content: "\f140"; }

.fa-bullseye-arrow::before {
  content: "\1f3af"; }

.fa-bullseye-pointer::before {
  content: "\f649"; }

.fa-burger::before {
  content: "\f805"; }

.fa-hamburger::before {
  content: "\f805"; }

.fa-burger-cheese::before {
  content: "\1f354"; }

.fa-cheeseburger::before {
  content: "\1f354"; }

.fa-burger-fries::before {
  content: "\e0cd"; }

.fa-burger-glass::before {
  content: "\e0ce"; }

.fa-burger-soda::before {
  content: "\f858"; }

.fa-burrito::before {
  content: "\1f32f"; }

.fa-bus::before {
  content: "\1f68d"; }

.fa-bus-school::before {
  content: "\f5dd"; }

.fa-bus-simple::before {
  content: "\f55e"; }

.fa-bus-alt::before {
  content: "\f55e"; }

.fa-c::before {
  content: "\43"; }

.fa-cabinet-filing::before {
  content: "\1f5c4"; }

.fa-cable-car::before {
  content: "\e0cf"; }

.fa-cactus::before {
  content: "\1f335"; }

.fa-cake-candles::before {
  content: "\1f382"; }

.fa-birthday-cake::before {
  content: "\1f382"; }

.fa-calculator::before {
  content: "\1f5a9"; }

.fa-calculator-simple::before {
  content: "\f64c"; }

.fa-calculator-alt::before {
  content: "\f64c"; }

.fa-calendar::before {
  content: "\1f4c5"; }

.fa-calendar-arrow-down::before {
  content: "\e0d0"; }

.fa-calendar-download::before {
  content: "\e0d0"; }

.fa-calendar-arrow-up::before {
  content: "\e0d1"; }

.fa-calendar-upload::before {
  content: "\e0d1"; }

.fa-calendar-check::before {
  content: "\f274"; }

.fa-calendar-clock::before {
  content: "\e0d2"; }

.fa-calendar-time::before {
  content: "\e0d2"; }

.fa-calendar-day::before {
  content: "\f783"; }

.fa-calendar-days::before {
  content: "\f073"; }

.fa-calendar-alt::before {
  content: "\f073"; }

.fa-calendar-exclamation::before {
  content: "\f334"; }

.fa-calendar-heart::before {
  content: "\e0d3"; }

.fa-calendar-image::before {
  content: "\e0d4"; }

.fa-calendar-lines::before {
  content: "\e0d5"; }

.fa-calendar-note::before {
  content: "\e0d5"; }

.fa-calendar-minus::before {
  content: "\f272"; }

.fa-calendar-pen::before {
  content: "\f333"; }

.fa-calendar-edit::before {
  content: "\f333"; }

.fa-calendar-plus::before {
  content: "\f271"; }

.fa-calendar-range::before {
  content: "\e0d6"; }

.fa-calendar-star::before {
  content: "\f736"; }

.fa-calendar-week::before {
  content: "\f784"; }

.fa-calendar-xmark::before {
  content: "\f273"; }

.fa-calendar-times::before {
  content: "\f273"; }

.fa-calendars::before {
  content: "\e0d7"; }

.fa-camcorder::before {
  content: "\1f4f9"; }

.fa-video-handheld::before {
  content: "\1f4f9"; }

.fa-camera::before {
  content: "\f030"; }

.fa-camera-alt::before {
  content: "\f030"; }

.fa-camera-cctv::before {
  content: "\f8ac"; }

.fa-cctv::before {
  content: "\f8ac"; }

.fa-camera-movie::before {
  content: "\1f3a5"; }

.fa-camera-polaroid::before {
  content: "\f8aa"; }

.fa-camera-retro::before {
  content: "\1f4f7"; }

.fa-camera-rotate::before {
  content: "\e0d8"; }

.fa-camera-security::before {
  content: "\f8fe"; }

.fa-camera-home::before {
  content: "\f8fe"; }

.fa-camera-slash::before {
  content: "\e0d9"; }

.fa-camera-viewfinder::before {
  content: "\e0da"; }

.fa-camera-web::before {
  content: "\f832"; }

.fa-webcam::before {
  content: "\f832"; }

.fa-camera-web-slash::before {
  content: "\f833"; }

.fa-webcam-slash::before {
  content: "\f833"; }

.fa-campfire::before {
  content: "\f6ba"; }

.fa-campground::before {
  content: "\26fa"; }

.fa-candle-holder::before {
  content: "\1f56f"; }

.fa-candy-cane::before {
  content: "\f786"; }

.fa-candy-corn::before {
  content: "\f6bd"; }

.fa-cannabis::before {
  content: "\f55f"; }

.fa-capsules::before {
  content: "\f46b"; }

.fa-car::before {
  content: "\1f698"; }

.fa-automobile::before {
  content: "\1f698"; }

.fa-car-battery::before {
  content: "\f5df"; }

.fa-battery-car::before {
  content: "\f5df"; }

.fa-car-bolt::before {
  content: "\e341"; }

.fa-car-building::before {
  content: "\f859"; }

.fa-car-bump::before {
  content: "\f5e0"; }

.fa-car-bus::before {
  content: "\f85a"; }

.fa-car-circle-bolt::before {
  content: "\e342"; }

.fa-car-crash::before {
  content: "\f5e1"; }

.fa-car-garage::before {
  content: "\f5e2"; }

.fa-car-mirrors::before {
  content: "\e343"; }

.fa-car-rear::before {
  content: "\f5de"; }

.fa-car-alt::before {
  content: "\f5de"; }

.fa-car-side::before {
  content: "\1f697"; }

.fa-car-side-bolt::before {
  content: "\e344"; }

.fa-car-tilt::before {
  content: "\f5e5"; }

.fa-car-wash::before {
  content: "\f5e6"; }

.fa-car-wrench::before {
  content: "\f5e3"; }

.fa-car-mechanic::before {
  content: "\f5e3"; }

.fa-caravan::before {
  content: "\f8ff"; }

.fa-caravan-simple::before {
  content: "\e000"; }

.fa-caravan-alt::before {
  content: "\e000"; }

.fa-caret-down::before {
  content: "\f0d7"; }

.fa-caret-left::before {
  content: "\f0d9"; }

.fa-caret-right::before {
  content: "\f0da"; }

.fa-caret-up::before {
  content: "\f0d8"; }

.fa-carrot::before {
  content: "\1f955"; }

.fa-cars::before {
  content: "\f85b"; }

.fa-cart-arrow-down::before {
  content: "\f218"; }

.fa-cart-flatbed::before {
  content: "\f474"; }

.fa-dolly-flatbed::before {
  content: "\f474"; }

.fa-cart-flatbed-boxes::before {
  content: "\f475"; }

.fa-dolly-flatbed-alt::before {
  content: "\f475"; }

.fa-cart-flatbed-empty::before {
  content: "\f476"; }

.fa-dolly-flatbed-empty::before {
  content: "\f476"; }

.fa-cart-flatbed-suitcase::before {
  content: "\f59d"; }

.fa-luggage-cart::before {
  content: "\f59d"; }

.fa-cart-minus::before {
  content: "\e0db"; }

.fa-cart-plus::before {
  content: "\f217"; }

.fa-cart-shopping::before {
  content: "\1f6d2"; }

.fa-shopping-cart::before {
  content: "\1f6d2"; }

.fa-cart-shopping-fast::before {
  content: "\e0dc"; }

.fa-cart-xmark::before {
  content: "\e0dd"; }

.fa-cash-register::before {
  content: "\f788"; }

.fa-cassette-betamax::before {
  content: "\f8a4"; }

.fa-betamax::before {
  content: "\f8a4"; }

.fa-cassette-tape::before {
  content: "\1f5ad"; }

.fa-cassette-vhs::before {
  content: "\1f4fc"; }

.fa-vhs::before {
  content: "\1f4fc"; }

.fa-castle::before {
  content: "\e0de"; }

.fa-cat::before {
  content: "\1f408"; }

.fa-cat-space::before {
  content: "\e001"; }

.fa-cauldron::before {
  content: "\f6bf"; }

.fa-cedi-sign::before {
  content: "\e0df"; }

.fa-cent-sign::before {
  content: "\a2"; }

.fa-certificate::before {
  content: "\f0a3"; }

.fa-chair::before {
  content: "\1fa91"; }

.fa-chair-office::before {
  content: "\f6c1"; }

.fa-chalkboard::before {
  content: "\f51b"; }

.fa-blackboard::before {
  content: "\f51b"; }

.fa-chalkboard-user::before {
  content: "\f51c"; }

.fa-chalkboard-teacher::before {
  content: "\f51c"; }

.fa-champagne-glass::before {
  content: "\f79e"; }

.fa-glass-champagne::before {
  content: "\f79e"; }

.fa-champagne-glasses::before {
  content: "\1f942"; }

.fa-glass-cheers::before {
  content: "\1f942"; }

.fa-charging-station::before {
  content: "\f5e7"; }

.fa-chart-area::before {
  content: "\f1fe"; }

.fa-area-chart::before {
  content: "\f1fe"; }

.fa-chart-bar::before {
  content: "\f080"; }

.fa-bar-chart::before {
  content: "\f080"; }

.fa-chart-bullet::before {
  content: "\e0e1"; }

.fa-chart-candlestick::before {
  content: "\e0e2"; }

.fa-chart-column::before {
  content: "\e0e3"; }

.fa-chart-gantt::before {
  content: "\e0e4"; }

.fa-chart-line::before {
  content: "\f201"; }

.fa-line-chart::before {
  content: "\f201"; }

.fa-chart-line-down::before {
  content: "\1f4c9"; }

.fa-chart-line-up::before {
  content: "\e0e5"; }

.fa-chart-mixed::before {
  content: "\f643"; }

.fa-analytics::before {
  content: "\f643"; }

.fa-chart-network::before {
  content: "\f78a"; }

.fa-chart-pie::before {
  content: "\f200"; }

.fa-pie-chart::before {
  content: "\f200"; }

.fa-chart-pie-simple::before {
  content: "\f64e"; }

.fa-chart-pie-alt::before {
  content: "\f64e"; }

.fa-chart-pyramid::before {
  content: "\e0e6"; }

.fa-chart-radar::before {
  content: "\e0e7"; }

.fa-chart-scatter::before {
  content: "\f7ee"; }

.fa-chart-scatter-3d::before {
  content: "\e0e8"; }

.fa-chart-scatter-bubble::before {
  content: "\e0e9"; }

.fa-chart-tree-map::before {
  content: "\e0ea"; }

.fa-chart-user::before {
  content: "\f6a3"; }

.fa-user-chart::before {
  content: "\f6a3"; }

.fa-chart-waterfall::before {
  content: "\e0eb"; }

.fa-check::before {
  content: "\2713"; }

.fa-check-double::before {
  content: "\f560"; }

.fa-check-to-slot::before {
  content: "\f772"; }

.fa-vote-yea::before {
  content: "\f772"; }

.fa-cheese::before {
  content: "\f7ef"; }

.fa-cheese-swiss::before {
  content: "\1f9c0"; }

.fa-cherries::before {
  content: "\e0ec"; }

.fa-chess::before {
  content: "\f439"; }

.fa-chess-bishop::before {
  content: "\265d"; }

.fa-chess-bishop-piece::before {
  content: "\f43b"; }

.fa-chess-bishop-alt::before {
  content: "\f43b"; }

.fa-chess-board::before {
  content: "\f43c"; }

.fa-chess-clock::before {
  content: "\f43d"; }

.fa-chess-clock-flip::before {
  content: "\f43e"; }

.fa-chess-clock-alt::before {
  content: "\f43e"; }

.fa-chess-king::before {
  content: "\265a"; }

.fa-chess-king-piece::before {
  content: "\f440"; }

.fa-chess-king-alt::before {
  content: "\f440"; }

.fa-chess-knight::before {
  content: "\265e"; }

.fa-chess-knight-piece::before {
  content: "\f442"; }

.fa-chess-knight-alt::before {
  content: "\f442"; }

.fa-chess-pawn::before {
  content: "\265f"; }

.fa-chess-pawn-piece::before {
  content: "\f444"; }

.fa-chess-pawn-alt::before {
  content: "\f444"; }

.fa-chess-queen::before {
  content: "\265b"; }

.fa-chess-queen-piece::before {
  content: "\f446"; }

.fa-chess-queen-alt::before {
  content: "\f446"; }

.fa-chess-rook::before {
  content: "\265c"; }

.fa-chess-rook-piece::before {
  content: "\f448"; }

.fa-chess-rook-alt::before {
  content: "\f448"; }

.fa-chevron-down::before {
  content: "\f078"; }

.fa-chevron-left::before {
  content: "\2329"; }

.fa-chevron-right::before {
  content: "\232a"; }

.fa-chevron-up::before {
  content: "\f077"; }

.fa-chevrons-down::before {
  content: "\f322"; }

.fa-chevron-double-down::before {
  content: "\f322"; }

.fa-chevrons-left::before {
  content: "\f323"; }

.fa-chevron-double-left::before {
  content: "\f323"; }

.fa-chevrons-right::before {
  content: "\f324"; }

.fa-chevron-double-right::before {
  content: "\f324"; }

.fa-chevrons-up::before {
  content: "\f325"; }

.fa-chevron-double-up::before {
  content: "\f325"; }

.fa-child::before {
  content: "\f1ae"; }

.fa-chimney::before {
  content: "\f78b"; }

.fa-church::before {
  content: "\26ea"; }

.fa-circle::before {
  content: "\25cf"; }

.fa-circle-0::before {
  content: "\e0ed"; }

.fa-circle-1::before {
  content: "\e0ee"; }

.fa-circle-2::before {
  content: "\e0ef"; }

.fa-circle-3::before {
  content: "\e0f0"; }

.fa-circle-4::before {
  content: "\e0f1"; }

.fa-circle-5::before {
  content: "\e0f2"; }

.fa-circle-6::before {
  content: "\e0f3"; }

.fa-circle-7::before {
  content: "\e0f4"; }

.fa-circle-8::before {
  content: "\e0f5"; }

.fa-circle-9::before {
  content: "\e0f6"; }

.fa-circle-a::before {
  content: "\e0f7"; }

.fa-circle-ampersand::before {
  content: "\e0f8"; }

.fa-circle-arrow-down::before {
  content: "\f0ab"; }

.fa-arrow-circle-down::before {
  content: "\f0ab"; }

.fa-circle-arrow-down-left::before {
  content: "\e0f9"; }

.fa-circle-arrow-down-right::before {
  content: "\e0fa"; }

.fa-circle-arrow-left::before {
  content: "\f0a8"; }

.fa-arrow-circle-left::before {
  content: "\f0a8"; }

.fa-circle-arrow-right::before {
  content: "\f0a9"; }

.fa-arrow-circle-right::before {
  content: "\f0a9"; }

.fa-circle-arrow-up::before {
  content: "\f0aa"; }

.fa-arrow-circle-up::before {
  content: "\f0aa"; }

.fa-circle-arrow-up-left::before {
  content: "\e0fb"; }

.fa-circle-arrow-up-right::before {
  content: "\e0fc"; }

.fa-circle-b::before {
  content: "\e0fd"; }

.fa-circle-bolt::before {
  content: "\e0fe"; }

.fa-circle-book-open::before {
  content: "\e0ff"; }

.fa-book-circle::before {
  content: "\e0ff"; }

.fa-circle-bookmark::before {
  content: "\e100"; }

.fa-bookmark-circle::before {
  content: "\e100"; }

.fa-circle-c::before {
  content: "\e101"; }

.fa-circle-calendar::before {
  content: "\e102"; }

.fa-calendar-circle::before {
  content: "\e102"; }

.fa-circle-camera::before {
  content: "\e103"; }

.fa-camera-circle::before {
  content: "\e103"; }

.fa-circle-caret-down::before {
  content: "\f32d"; }

.fa-caret-circle-down::before {
  content: "\f32d"; }

.fa-circle-caret-left::before {
  content: "\f32e"; }

.fa-caret-circle-left::before {
  content: "\f32e"; }

.fa-circle-caret-right::before {
  content: "\f330"; }

.fa-caret-circle-right::before {
  content: "\f330"; }

.fa-circle-caret-up::before {
  content: "\f331"; }

.fa-caret-circle-up::before {
  content: "\f331"; }

.fa-circle-check::before {
  content: "\f058"; }

.fa-check-circle::before {
  content: "\f058"; }

.fa-circle-chevron-down::before {
  content: "\f13a"; }

.fa-chevron-circle-down::before {
  content: "\f13a"; }

.fa-circle-chevron-left::before {
  content: "\f137"; }

.fa-chevron-circle-left::before {
  content: "\f137"; }

.fa-circle-chevron-right::before {
  content: "\f138"; }

.fa-chevron-circle-right::before {
  content: "\f138"; }

.fa-circle-chevron-up::before {
  content: "\f139"; }

.fa-chevron-circle-up::before {
  content: "\f139"; }

.fa-circle-d::before {
  content: "\e104"; }

.fa-circle-dashed::before {
  content: "\e105"; }

.fa-circle-divide::before {
  content: "\e106"; }

.fa-circle-dollar::before {
  content: "\f2e8"; }

.fa-dollar-circle::before {
  content: "\f2e8"; }

.fa-usd-circle::before {
  content: "\f2e8"; }

.fa-circle-dollar-to-slot::before {
  content: "\f4b9"; }

.fa-donate::before {
  content: "\f4b9"; }

.fa-circle-dot::before {
  content: "\1f518"; }

.fa-dot-circle::before {
  content: "\1f518"; }

.fa-circle-down::before {
  content: "\f358"; }

.fa-arrow-alt-circle-down::before {
  content: "\f358"; }

.fa-circle-down-left::before {
  content: "\e107"; }

.fa-circle-down-right::before {
  content: "\e108"; }

.fa-circle-e::before {
  content: "\e109"; }

.fa-circle-ellipsis::before {
  content: "\e10a"; }

.fa-circle-ellipsis-vertical::before {
  content: "\e10b"; }

.fa-circle-envelope::before {
  content: "\e10c"; }

.fa-envelope-circle::before {
  content: "\e10c"; }

.fa-circle-exclamation::before {
  content: "\f06a"; }

.fa-exclamation-circle::before {
  content: "\f06a"; }

.fa-circle-exclamation-check::before {
  content: "\e10d"; }

.fa-circle-f::before {
  content: "\e10e"; }

.fa-circle-g::before {
  content: "\e10f"; }

.fa-circle-h::before {
  content: "\24bd"; }

.fa-hospital-symbol::before {
  content: "\24bd"; }

.fa-circle-half::before {
  content: "\e110"; }

.fa-circle-half-stroke::before {
  content: "\25d0"; }

.fa-adjust::before {
  content: "\25d0"; }

.fa-circle-heart::before {
  content: "\f4c7"; }

.fa-heart-circle::before {
  content: "\f4c7"; }

.fa-circle-i::before {
  content: "\e111"; }

.fa-circle-info::before {
  content: "\f05a"; }

.fa-info-circle::before {
  content: "\f05a"; }

.fa-circle-j::before {
  content: "\e112"; }

.fa-circle-k::before {
  content: "\e113"; }

.fa-circle-l::before {
  content: "\e114"; }

.fa-circle-left::before {
  content: "\f359"; }

.fa-arrow-alt-circle-left::before {
  content: "\f359"; }

.fa-circle-location-arrow::before {
  content: "\f602"; }

.fa-location-circle::before {
  content: "\f602"; }

.fa-circle-m::before {
  content: "\e115"; }

.fa-circle-microphone::before {
  content: "\e116"; }

.fa-microphone-circle::before {
  content: "\e116"; }

.fa-circle-microphone-lines::before {
  content: "\e117"; }

.fa-microphone-circle-alt::before {
  content: "\e117"; }

.fa-circle-minus::before {
  content: "\f056"; }

.fa-minus-circle::before {
  content: "\f056"; }

.fa-circle-n::before {
  content: "\e118"; }

.fa-circle-notch::before {
  content: "\f1ce"; }

.fa-circle-o::before {
  content: "\e119"; }

.fa-circle-p::before {
  content: "\e11a"; }

.fa-circle-parking::before {
  content: "\f615"; }

.fa-parking-circle::before {
  content: "\f615"; }

.fa-circle-pause::before {
  content: "\f28b"; }

.fa-pause-circle::before {
  content: "\f28b"; }

.fa-circle-phone::before {
  content: "\e11b"; }

.fa-phone-circle::before {
  content: "\e11b"; }

.fa-circle-phone-flip::before {
  content: "\e11c"; }

.fa-phone-circle-alt::before {
  content: "\e11c"; }

.fa-circle-phone-hangup::before {
  content: "\e11d"; }

.fa-phone-circle-down::before {
  content: "\e11d"; }

.fa-circle-play::before {
  content: "\f144"; }

.fa-play-circle::before {
  content: "\f144"; }

.fa-circle-plus::before {
  content: "\f055"; }

.fa-plus-circle::before {
  content: "\f055"; }

.fa-circle-q::before {
  content: "\e11e"; }

.fa-circle-quarter::before {
  content: "\e11f"; }

.fa-circle-question::before {
  content: "\f059"; }

.fa-question-circle::before {
  content: "\f059"; }

.fa-circle-r::before {
  content: "\e120"; }

.fa-circle-radiation::before {
  content: "\2622"; }

.fa-radiation-alt::before {
  content: "\2622"; }

.fa-circle-right::before {
  content: "\f35a"; }

.fa-arrow-alt-circle-right::before {
  content: "\f35a"; }

.fa-circle-s::before {
  content: "\e121"; }

.fa-circle-small::before {
  content: "\e122"; }

.fa-circle-sort::before {
  content: "\e030"; }

.fa-sort-circle::before {
  content: "\e030"; }

.fa-circle-sort-down::before {
  content: "\e031"; }

.fa-sort-circle-down::before {
  content: "\e031"; }

.fa-circle-sort-up::before {
  content: "\e032"; }

.fa-sort-circle-up::before {
  content: "\e032"; }

.fa-circle-star::before {
  content: "\e123"; }

.fa-star-circle::before {
  content: "\e123"; }

.fa-circle-stop::before {
  content: "\f28d"; }

.fa-stop-circle::before {
  content: "\f28d"; }

.fa-circle-t::before {
  content: "\e124"; }

.fa-circle-three-quarters::before {
  content: "\e125"; }

.fa-circle-trash::before {
  content: "\e126"; }

.fa-trash-circle::before {
  content: "\e126"; }

.fa-circle-u::before {
  content: "\e127"; }

.fa-circle-up::before {
  content: "\f35b"; }

.fa-arrow-alt-circle-up::before {
  content: "\f35b"; }

.fa-circle-up-left::before {
  content: "\e128"; }

.fa-circle-up-right::before {
  content: "\e129"; }

.fa-circle-user::before {
  content: "\f2bd"; }

.fa-user-circle::before {
  content: "\f2bd"; }

.fa-circle-v::before {
  content: "\e12a"; }

.fa-circle-video::before {
  content: "\e12b"; }

.fa-video-circle::before {
  content: "\e12b"; }

.fa-circle-w::before {
  content: "\e12c"; }

.fa-circle-waveform-lines::before {
  content: "\e12d"; }

.fa-waveform-circle::before {
  content: "\e12d"; }

.fa-circle-x::before {
  content: "\e12e"; }

.fa-circle-xmark::before {
  content: "\f057"; }

.fa-times-circle::before {
  content: "\f057"; }

.fa-xmark-circle::before {
  content: "\f057"; }

.fa-circle-y::before {
  content: "\e12f"; }

.fa-circle-z::before {
  content: "\e130"; }

.fa-citrus::before {
  content: "\e2f4"; }

.fa-citrus-slice::before {
  content: "\e2f5"; }

.fa-city::before {
  content: "\1f3d9"; }

.fa-clapperboard::before {
  content: "\e131"; }

.fa-clapperboard-play::before {
  content: "\e132"; }

.fa-clarinet::before {
  content: "\f8ad"; }

.fa-claw-marks::before {
  content: "\f6c2"; }

.fa-clipboard::before {
  content: "\1f4cb"; }

.fa-clipboard-check::before {
  content: "\f46c"; }

.fa-clipboard-list::before {
  content: "\f46d"; }

.fa-clipboard-list-check::before {
  content: "\f737"; }

.fa-clipboard-medical::before {
  content: "\e133"; }

.fa-clipboard-prescription::before {
  content: "\f5e8"; }

.fa-clipboard-user::before {
  content: "\f7f3"; }

.fa-clock::before {
  content: "\1f553"; }

.fa-clock-four::before {
  content: "\1f553"; }

.fa-clock-desk::before {
  content: "\e134"; }

.fa-clock-eight::before {
  content: "\e345"; }

.fa-clock-eight-thirty::before {
  content: "\e346"; }

.fa-clock-eleven::before {
  content: "\e347"; }

.fa-clock-eleven-thirty::before {
  content: "\e348"; }

.fa-clock-five::before {
  content: "\e349"; }

.fa-clock-five-thirty::before {
  content: "\e34a"; }

.fa-clock-four-thirty::before {
  content: "\e34b"; }

.fa-clock-nine::before {
  content: "\e34c"; }

.fa-clock-nine-thirty::before {
  content: "\e34d"; }

.fa-clock-one::before {
  content: "\e34e"; }

.fa-clock-one-thirty::before {
  content: "\e34f"; }

.fa-clock-rotate-left::before {
  content: "\f1da"; }

.fa-history::before {
  content: "\f1da"; }

.fa-clock-seven::before {
  content: "\e350"; }

.fa-clock-seven-thirty::before {
  content: "\e351"; }

.fa-clock-six::before {
  content: "\e352"; }

.fa-clock-six-thirty::before {
  content: "\e353"; }

.fa-clock-ten::before {
  content: "\e354"; }

.fa-clock-ten-thirty::before {
  content: "\e355"; }

.fa-clock-three::before {
  content: "\e356"; }

.fa-clock-three-thirty::before {
  content: "\e357"; }

.fa-clock-twelve::before {
  content: "\e358"; }

.fa-clock-twelve-thirty::before {
  content: "\e359"; }

.fa-clock-two::before {
  content: "\e35a"; }

.fa-clock-two-thirty::before {
  content: "\e35b"; }

.fa-clone::before {
  content: "\f24d"; }

.fa-closed-captioning::before {
  content: "\f20a"; }

.fa-closed-captioning-slash::before {
  content: "\e135"; }

.fa-clothes-hanger::before {
  content: "\e136"; }

.fa-cloud::before {
  content: "\2601"; }

.fa-cloud-arrow-down::before {
  content: "\f0ed"; }

.fa-cloud-download::before {
  content: "\f0ed"; }

.fa-cloud-download-alt::before {
  content: "\f0ed"; }

.fa-cloud-arrow-up::before {
  content: "\f0ee"; }

.fa-cloud-upload::before {
  content: "\f0ee"; }

.fa-cloud-upload-alt::before {
  content: "\f0ee"; }

.fa-cloud-bolt::before {
  content: "\1f329"; }

.fa-thunderstorm::before {
  content: "\1f329"; }

.fa-cloud-bolt-moon::before {
  content: "\f76d"; }

.fa-thunderstorm-moon::before {
  content: "\f76d"; }

.fa-cloud-bolt-sun::before {
  content: "\f76e"; }

.fa-thunderstorm-sun::before {
  content: "\f76e"; }

.fa-cloud-check::before {
  content: "\e35c"; }

.fa-cloud-drizzle::before {
  content: "\f738"; }

.fa-cloud-fog::before {
  content: "\1f32b"; }

.fa-fog::before {
  content: "\1f32b"; }

.fa-cloud-hail::before {
  content: "\f739"; }

.fa-cloud-hail-mixed::before {
  content: "\f73a"; }

.fa-cloud-meatball::before {
  content: "\f73b"; }

.fa-cloud-minus::before {
  content: "\e35d"; }

.fa-cloud-moon::before {
  content: "\f6c3"; }

.fa-cloud-moon-rain::before {
  content: "\f73c"; }

.fa-cloud-music::before {
  content: "\f8ae"; }

.fa-cloud-plus::before {
  content: "\e35e"; }

.fa-cloud-rain::before {
  content: "\26c6"; }

.fa-cloud-rainbow::before {
  content: "\f73e"; }

.fa-cloud-showers::before {
  content: "\f73f"; }

.fa-cloud-showers-heavy::before {
  content: "\f740"; }

.fa-cloud-slash::before {
  content: "\e137"; }

.fa-cloud-sleet::before {
  content: "\f741"; }

.fa-cloud-snow::before {
  content: "\1f328"; }

.fa-cloud-sun::before {
  content: "\26c5"; }

.fa-cloud-sun-rain::before {
  content: "\1f326"; }

.fa-cloud-word::before {
  content: "\e138"; }

.fa-cloud-xmark::before {
  content: "\e35f"; }

.fa-clouds::before {
  content: "\f744"; }

.fa-clouds-moon::before {
  content: "\f745"; }

.fa-clouds-sun::before {
  content: "\f746"; }

.fa-clover::before {
  content: "\e139"; }

.fa-club::before {
  content: "\2663"; }

.fa-coconut::before {
  content: "\e2f6"; }

.fa-code::before {
  content: "\f121"; }

.fa-code-branch::before {
  content: "\f126"; }

.fa-code-commit::before {
  content: "\f386"; }

.fa-code-compare::before {
  content: "\e13a"; }

.fa-code-fork::before {
  content: "\e13b"; }

.fa-code-merge::before {
  content: "\f387"; }

.fa-code-pull-request::before {
  content: "\e13c"; }

.fa-code-simple::before {
  content: "\e13d"; }

.fa-coffee-bean::before {
  content: "\e13e"; }

.fa-coffee-beans::before {
  content: "\e13f"; }

.fa-coffee-pot::before {
  content: "\e002"; }

.fa-coffin::before {
  content: "\26b0"; }

.fa-coffin-cross::before {
  content: "\e051"; }

.fa-coin::before {
  content: "\1fa99"; }

.fa-coins::before {
  content: "\f51e"; }

.fa-colon::before {
  content: "\3a"; }

.fa-colon-sign::before {
  content: "\e140"; }

.fa-columns-3::before {
  content: "\e361"; }

.fa-comet::before {
  content: "\e003"; }

.fa-comma::before {
  content: "\2c"; }

.fa-command::before {
  content: "\e142"; }

.fa-comment::before {
  content: "\1f5e9"; }

.fa-comment-arrow-down::before {
  content: "\e143"; }

.fa-comment-arrow-up::before {
  content: "\e144"; }

.fa-comment-arrow-up-right::before {
  content: "\e145"; }

.fa-comment-captions::before {
  content: "\e146"; }

.fa-comment-check::before {
  content: "\f4ac"; }

.fa-comment-code::before {
  content: "\e147"; }

.fa-comment-dollar::before {
  content: "\f651"; }

.fa-comment-dots::before {
  content: "\1f4ac"; }

.fa-commenting::before {
  content: "\1f4ac"; }

.fa-comment-exclamation::before {
  content: "\f4af"; }

.fa-comment-image::before {
  content: "\e148"; }

.fa-comment-lines::before {
  content: "\f4b0"; }

.fa-comment-medical::before {
  content: "\f7f5"; }

.fa-comment-middle::before {
  content: "\e149"; }

.fa-comment-middle-top::before {
  content: "\e14a"; }

.fa-comment-minus::before {
  content: "\f4b1"; }

.fa-comment-music::before {
  content: "\f8b0"; }

.fa-comment-pen::before {
  content: "\f4ae"; }

.fa-comment-edit::before {
  content: "\f4ae"; }

.fa-comment-plus::before {
  content: "\f4b2"; }

.fa-comment-question::before {
  content: "\e14b"; }

.fa-comment-quote::before {
  content: "\e14c"; }

.fa-comment-slash::before {
  content: "\f4b3"; }

.fa-comment-smile::before {
  content: "\f4b4"; }

.fa-comment-sms::before {
  content: "\f7cd"; }

.fa-sms::before {
  content: "\f7cd"; }

.fa-comment-text::before {
  content: "\e14d"; }

.fa-comment-xmark::before {
  content: "\f4b5"; }

.fa-comment-times::before {
  content: "\f4b5"; }

.fa-comments::before {
  content: "\1f5ea"; }

.fa-comments-dollar::before {
  content: "\f653"; }

.fa-comments-question::before {
  content: "\e14e"; }

.fa-comments-question-check::before {
  content: "\e14f"; }

.fa-compact-disc::before {
  content: "\1f4bf"; }

.fa-compass::before {
  content: "\1f9ed"; }

.fa-compass-drafting::before {
  content: "\f568"; }

.fa-drafting-compass::before {
  content: "\f568"; }

.fa-compass-slash::before {
  content: "\f5e9"; }

.fa-compress::before {
  content: "\f066"; }

.fa-compress-wide::before {
  content: "\f326"; }

.fa-computer-classic::before {
  content: "\f8b1"; }

.fa-computer-mouse::before {
  content: "\1f5b1"; }

.fa-mouse::before {
  content: "\1f5b1"; }

.fa-computer-mouse-scrollwheel::before {
  content: "\f8cd"; }

.fa-mouse-alt::before {
  content: "\f8cd"; }

.fa-computer-speaker::before {
  content: "\f8b2"; }

.fa-container-storage::before {
  content: "\f4b7"; }

.fa-conveyor-belt::before {
  content: "\f46e"; }

.fa-conveyor-belt-boxes::before {
  content: "\f46f"; }

.fa-conveyor-belt-alt::before {
  content: "\f46f"; }

.fa-conveyor-belt-empty::before {
  content: "\e150"; }

.fa-cookie::before {
  content: "\1f36a"; }

.fa-cookie-bite::before {
  content: "\f564"; }

.fa-copy::before {
  content: "\f0c5"; }

.fa-copyright::before {
  content: "\a9"; }

.fa-corn::before {
  content: "\1f33d"; }

.fa-corner::before {
  content: "\ac"; }

.fa-couch::before {
  content: "\f4b8"; }

.fa-cow::before {
  content: "\1f404"; }

.fa-cowbell::before {
  content: "\f8b3"; }

.fa-cowbell-circle-plus::before {
  content: "\f8b4"; }

.fa-cowbell-more::before {
  content: "\f8b4"; }

.fa-crate-apple::before {
  content: "\f6b1"; }

.fa-apple-crate::before {
  content: "\f6b1"; }

.fa-crate-empty::before {
  content: "\e151"; }

.fa-credit-card::before {
  content: "\1f4b3"; }

.fa-credit-card-alt::before {
  content: "\1f4b3"; }

.fa-credit-card-blank::before {
  content: "\f389"; }

.fa-credit-card-front::before {
  content: "\f38a"; }

.fa-cricket-bat-ball::before {
  content: "\1f3cf"; }

.fa-cricket::before {
  content: "\1f3cf"; }

.fa-croissant::before {
  content: "\1f950"; }

.fa-crop::before {
  content: "\f125"; }

.fa-crop-simple::before {
  content: "\f565"; }

.fa-crop-alt::before {
  content: "\f565"; }

.fa-cross::before {
  content: "\271d"; }

.fa-crosshairs::before {
  content: "\f05b"; }

.fa-crow::before {
  content: "\f520"; }

.fa-crown::before {
  content: "\1f451"; }

.fa-crutch::before {
  content: "\f7f7"; }

.fa-crutches::before {
  content: "\f7f8"; }

.fa-cruzeiro-sign::before {
  content: "\e152"; }

.fa-crystal-ball::before {
  content: "\e362"; }

.fa-cube::before {
  content: "\f1b2"; }

.fa-cubes::before {
  content: "\f1b3"; }

.fa-cup-straw::before {
  content: "\e363"; }

.fa-cup-straw-swoosh::before {
  content: "\e364"; }

.fa-cup-togo::before {
  content: "\f6c5"; }

.fa-coffee-togo::before {
  content: "\f6c5"; }

.fa-curling-stone::before {
  content: "\1f94c"; }

.fa-curling::before {
  content: "\1f94c"; }

.fa-d::before {
  content: "\44"; }

.fa-dagger::before {
  content: "\f6cb"; }

.fa-dash::before {
  content: "\2014"; }

.fa-database::before {
  content: "\f1c0"; }

.fa-deer::before {
  content: "\1f98c"; }

.fa-deer-rudolph::before {
  content: "\f78f"; }

.fa-delete-left::before {
  content: "\232b"; }

.fa-backspace::before {
  content: "\232b"; }

.fa-delete-right::before {
  content: "\e154"; }

.fa-democrat::before {
  content: "\f747"; }

.fa-desktop::before {
  content: "\1f5a5"; }

.fa-desktop-alt::before {
  content: "\1f5a5"; }

.fa-desktop-arrow-down::before {
  content: "\e155"; }

.fa-dharmachakra::before {
  content: "\2638"; }

.fa-diagram-lean-canvas::before {
  content: "\e156"; }

.fa-diagram-nested::before {
  content: "\e157"; }

.fa-diagram-project::before {
  content: "\f542"; }

.fa-project-diagram::before {
  content: "\f542"; }

.fa-diagram-sankey::before {
  content: "\e158"; }

.fa-diagram-venn::before {
  content: "\e15a"; }

.fa-dial::before {
  content: "\e15b"; }

.fa-dial-med-high::before {
  content: "\e15b"; }

.fa-dial-high::before {
  content: "\e15c"; }

.fa-dial-low::before {
  content: "\e15d"; }

.fa-dial-max::before {
  content: "\e15e"; }

.fa-dial-med::before {
  content: "\e15f"; }

.fa-dial-med-low::before {
  content: "\e160"; }

.fa-dial-min::before {
  content: "\e161"; }

.fa-dial-off::before {
  content: "\e162"; }

.fa-diamond::before {
  content: "\2666"; }

.fa-diamond-turn-right::before {
  content: "\f5eb"; }

.fa-directions::before {
  content: "\f5eb"; }

.fa-dice::before {
  content: "\1f3b2"; }

.fa-dice-d10::before {
  content: "\f6cd"; }

.fa-dice-d12::before {
  content: "\f6ce"; }

.fa-dice-d20::before {
  content: "\f6cf"; }

.fa-dice-d4::before {
  content: "\f6d0"; }

.fa-dice-d6::before {
  content: "\f6d1"; }

.fa-dice-d8::before {
  content: "\f6d2"; }

.fa-dice-five::before {
  content: "\2684"; }

.fa-dice-four::before {
  content: "\2683"; }

.fa-dice-one::before {
  content: "\2680"; }

.fa-dice-six::before {
  content: "\2685"; }

.fa-dice-three::before {
  content: "\2682"; }

.fa-dice-two::before {
  content: "\2681"; }

.fa-diploma::before {
  content: "\f5ea"; }

.fa-scroll-ribbon::before {
  content: "\f5ea"; }

.fa-disc-drive::before {
  content: "\f8b5"; }

.fa-disease::before {
  content: "\f7fa"; }

.fa-display::before {
  content: "\e163"; }

.fa-display-arrow-down::before {
  content: "\e164"; }

.fa-display-code::before {
  content: "\e165"; }

.fa-desktop-code::before {
  content: "\e165"; }

.fa-display-medical::before {
  content: "\e166"; }

.fa-desktop-medical::before {
  content: "\e166"; }

.fa-display-slash::before {
  content: "\e2fa"; }

.fa-desktop-slash::before {
  content: "\e2fa"; }

.fa-distribute-spacing-horizontal::before {
  content: "\e365"; }

.fa-distribute-spacing-vertical::before {
  content: "\e366"; }

.fa-ditto::before {
  content: "\22"; }

.fa-divide::before {
  content: "\f7"; }

.fa-dna::before {
  content: "\1f9ec"; }

.fa-do-not-enter::before {
  content: "\f5ec"; }

.fa-dog::before {
  content: "\1f415"; }

.fa-dog-leashed::before {
  content: "\1f9ae"; }

.fa-dollar-sign::before {
  content: "\24"; }

.fa-dollar::before {
  content: "\24"; }

.fa-usd::before {
  content: "\24"; }

.fa-dolly::before {
  content: "\f472"; }

.fa-dolly-box::before {
  content: "\f472"; }

.fa-dolly-empty::before {
  content: "\f473"; }

.fa-dolphin::before {
  content: "\e168"; }

.fa-dong-sign::before {
  content: "\e169"; }

.fa-door-closed::before {
  content: "\1f6aa"; }

.fa-door-open::before {
  content: "\f52b"; }

.fa-dove::before {
  content: "\1f54a"; }

.fa-down::before {
  content: "\f354"; }

.fa-arrow-alt-down::before {
  content: "\f354"; }

.fa-down-from-line::before {
  content: "\f349"; }

.fa-arrow-alt-from-top::before {
  content: "\f349"; }

.fa-down-left::before {
  content: "\e16a"; }

.fa-down-left-and-up-right-to-center::before {
  content: "\f422"; }

.fa-compress-alt::before {
  content: "\f422"; }

.fa-down-long::before {
  content: "\f309"; }

.fa-long-arrow-alt-down::before {
  content: "\f309"; }

.fa-down-right::before {
  content: "\e16b"; }

.fa-down-to-line::before {
  content: "\f34a"; }

.fa-arrow-alt-to-bottom::before {
  content: "\f34a"; }

.fa-download::before {
  content: "\f019"; }

.fa-dragon::before {
  content: "\1f409"; }

.fa-draw-circle::before {
  content: "\f5ed"; }

.fa-draw-polygon::before {
  content: "\f5ee"; }

.fa-draw-square::before {
  content: "\f5ef"; }

.fa-dreidel::before {
  content: "\f792"; }

.fa-drone::before {
  content: "\f85f"; }

.fa-drone-front::before {
  content: "\f860"; }

.fa-drone-alt::before {
  content: "\f860"; }

.fa-droplet::before {
  content: "\1f4a7"; }

.fa-tint::before {
  content: "\1f4a7"; }

.fa-droplet-degree::before {
  content: "\f748"; }

.fa-dewpoint::before {
  content: "\f748"; }

.fa-droplet-percent::before {
  content: "\f750"; }

.fa-humidity::before {
  content: "\f750"; }

.fa-droplet-slash::before {
  content: "\f5c7"; }

.fa-tint-slash::before {
  content: "\f5c7"; }

.fa-drum::before {
  content: "\1f941"; }

.fa-drum-steelpan::before {
  content: "\f56a"; }

.fa-drumstick::before {
  content: "\1f357"; }

.fa-drumstick-bite::before {
  content: "\f6d7"; }

.fa-dryer::before {
  content: "\f861"; }

.fa-dryer-heat::before {
  content: "\f862"; }

.fa-dryer-alt::before {
  content: "\f862"; }

.fa-duck::before {
  content: "\1f986"; }

.fa-dumbbell::before {
  content: "\f44b"; }

.fa-dumpster::before {
  content: "\f793"; }

.fa-dumpster-fire::before {
  content: "\f794"; }

.fa-dungeon::before {
  content: "\f6d9"; }

.fa-e::before {
  content: "\45"; }

.fa-ear::before {
  content: "\1f442"; }

.fa-ear-deaf::before {
  content: "\f2a4"; }

.fa-deaf::before {
  content: "\f2a4"; }

.fa-deafness::before {
  content: "\f2a4"; }

.fa-hard-of-hearing::before {
  content: "\f2a4"; }

.fa-ear-listen::before {
  content: "\f2a2"; }

.fa-assistive-listening-systems::before {
  content: "\f2a2"; }

.fa-ear-muffs::before {
  content: "\f795"; }

.fa-earth-africa::before {
  content: "\1f30d"; }

.fa-globe-africa::before {
  content: "\1f30d"; }

.fa-earth-americas::before {
  content: "\1f30e"; }

.fa-earth::before {
  content: "\1f30e"; }

.fa-globe-americas::before {
  content: "\1f30e"; }

.fa-earth-asia::before {
  content: "\1f30f"; }

.fa-globe-asia::before {
  content: "\1f30f"; }

.fa-earth-europa::before {
  content: "\f7a2"; }

.fa-globe-europe::before {
  content: "\f7a2"; }

.fa-eclipse::before {
  content: "\f749"; }

.fa-egg::before {
  content: "\1f95a"; }

.fa-egg-fried::before {
  content: "\f7fc"; }

.fa-eggplant::before {
  content: "\e16c"; }

.fa-eject::before {
  content: "\23cf"; }

.fa-elephant::before {
  content: "\1f418"; }

.fa-elevator::before {
  content: "\e16d"; }

.fa-ellipsis::before {
  content: "\f141"; }

.fa-ellipsis-h::before {
  content: "\f141"; }

.fa-ellipsis-stroke::before {
  content: "\f39b"; }

.fa-ellipsis-h-alt::before {
  content: "\f39b"; }

.fa-ellipsis-stroke-vertical::before {
  content: "\f39c"; }

.fa-ellipsis-v-alt::before {
  content: "\f39c"; }

.fa-ellipsis-vertical::before {
  content: "\f142"; }

.fa-ellipsis-v::before {
  content: "\f142"; }

.fa-empty-set::before {
  content: "\d8"; }

.fa-engine::before {
  content: "\e16e"; }

.fa-engine-warning::before {
  content: "\f5f2"; }

.fa-engine-exclamation::before {
  content: "\f5f2"; }

.fa-envelope::before {
  content: "\2709"; }

.fa-envelope-dot::before {
  content: "\e16f"; }

.fa-envelope-badge::before {
  content: "\e16f"; }

.fa-envelope-open::before {
  content: "\f2b6"; }

.fa-envelope-open-dollar::before {
  content: "\f657"; }

.fa-envelope-open-text::before {
  content: "\f658"; }

.fa-envelopes::before {
  content: "\e170"; }

.fa-envelopes-bulk::before {
  content: "\f674"; }

.fa-mail-bulk::before {
  content: "\f674"; }

.fa-equals::before {
  content: "\3d"; }

.fa-eraser::before {
  content: "\f12d"; }

.fa-escalator::before {
  content: "\e171"; }

.fa-ethernet::before {
  content: "\f796"; }

.fa-euro-sign::before {
  content: "\20ac"; }

.fa-eur::before {
  content: "\20ac"; }

.fa-euro::before {
  content: "\20ac"; }

.fa-exclamation::before {
  content: "\21"; }

.fa-expand::before {
  content: "\f065"; }

.fa-expand-wide::before {
  content: "\f320"; }

.fa-eye::before {
  content: "\1f441"; }

.fa-eye-dropper::before {
  content: "\f1fb"; }

.fa-eye-dropper-empty::before {
  content: "\f1fb"; }

.fa-eyedropper::before {
  content: "\f1fb"; }

.fa-eye-dropper-full::before {
  content: "\e172"; }

.fa-eye-dropper-half::before {
  content: "\e173"; }

.fa-eye-evil::before {
  content: "\f6db"; }

.fa-eye-low-vision::before {
  content: "\f2a8"; }

.fa-low-vision::before {
  content: "\f2a8"; }

.fa-eye-slash::before {
  content: "\f070"; }

.fa-eyes::before {
  content: "\e367"; }

.fa-f::before {
  content: "\46"; }

.fa-face-angry::before {
  content: "\1f620"; }

.fa-angry::before {
  content: "\1f620"; }

.fa-face-angry-horns::before {
  content: "\e368"; }

.fa-face-anguished::before {
  content: "\e369"; }

.fa-face-anxious-sweat::before {
  content: "\e36a"; }

.fa-face-astonished::before {
  content: "\e36b"; }

.fa-face-confounded::before {
  content: "\e36c"; }

.fa-face-confused::before {
  content: "\e36d"; }

.fa-face-cowboy-hat::before {
  content: "\e36e"; }

.fa-face-disappointed::before {
  content: "\e36f"; }

.fa-face-disguise::before {
  content: "\e370"; }

.fa-face-dizzy::before {
  content: "\f567"; }

.fa-dizzy::before {
  content: "\f567"; }

.fa-face-downcast-sweat::before {
  content: "\e371"; }

.fa-face-drooling::before {
  content: "\e372"; }

.fa-face-explode::before {
  content: "\e2fe"; }

.fa-exploding-head::before {
  content: "\e2fe"; }

.fa-face-expressionless::before {
  content: "\e373"; }

.fa-face-eyes-xmarks::before {
  content: "\e374"; }

.fa-face-fearful::before {
  content: "\e375"; }

.fa-face-flushed::before {
  content: "\1f633"; }

.fa-flushed::before {
  content: "\1f633"; }

.fa-face-frown::before {
  content: "\2639"; }

.fa-frown::before {
  content: "\2639"; }

.fa-face-frown-open::before {
  content: "\1f626"; }

.fa-frown-open::before {
  content: "\1f626"; }

.fa-face-frown-slight::before {
  content: "\e376"; }

.fa-face-glasses::before {
  content: "\e377"; }

.fa-face-grimace::before {
  content: "\1f62c"; }

.fa-grimace::before {
  content: "\1f62c"; }

.fa-face-grin::before {
  content: "\1f600"; }

.fa-grin::before {
  content: "\1f600"; }

.fa-face-grin-beam::before {
  content: "\1f604"; }

.fa-grin-beam::before {
  content: "\1f604"; }

.fa-face-grin-beam-sweat::before {
  content: "\1f605"; }

.fa-grin-beam-sweat::before {
  content: "\1f605"; }

.fa-face-grin-hearts::before {
  content: "\1f60d"; }

.fa-grin-hearts::before {
  content: "\1f60d"; }

.fa-face-grin-squint::before {
  content: "\1f606"; }

.fa-grin-squint::before {
  content: "\1f606"; }

.fa-face-grin-squint-tears::before {
  content: "\1f923"; }

.fa-grin-squint-tears::before {
  content: "\1f923"; }

.fa-face-grin-stars::before {
  content: "\1f929"; }

.fa-grin-stars::before {
  content: "\1f929"; }

.fa-face-grin-tears::before {
  content: "\1f602"; }

.fa-grin-tears::before {
  content: "\1f602"; }

.fa-face-grin-tongue::before {
  content: "\1f61b"; }

.fa-grin-tongue::before {
  content: "\1f61b"; }

.fa-face-grin-tongue-squint::before {
  content: "\1f61d"; }

.fa-grin-tongue-squint::before {
  content: "\1f61d"; }

.fa-face-grin-tongue-wink::before {
  content: "\1f61c"; }

.fa-grin-tongue-wink::before {
  content: "\1f61c"; }

.fa-face-grin-wide::before {
  content: "\1f603"; }

.fa-grin-alt::before {
  content: "\1f603"; }

.fa-face-grin-wink::before {
  content: "\f58c"; }

.fa-grin-wink::before {
  content: "\f58c"; }

.fa-face-hand-over-mouth::before {
  content: "\e378"; }

.fa-face-hand-yawn::before {
  content: "\e379"; }

.fa-face-head-bandage::before {
  content: "\e37a"; }

.fa-face-hushed::before {
  content: "\e37b"; }

.fa-face-icicles::before {
  content: "\e37c"; }

.fa-face-kiss::before {
  content: "\1f617"; }

.fa-kiss::before {
  content: "\1f617"; }

.fa-face-kiss-beam::before {
  content: "\1f619"; }

.fa-kiss-beam::before {
  content: "\1f619"; }

.fa-face-kiss-closed-eyes::before {
  content: "\e37d"; }

.fa-face-kiss-wink-heart::before {
  content: "\1f618"; }

.fa-kiss-wink-heart::before {
  content: "\1f618"; }

.fa-face-laugh::before {
  content: "\f599"; }

.fa-laugh::before {
  content: "\f599"; }

.fa-face-laugh-beam::before {
  content: "\1f601"; }

.fa-laugh-beam::before {
  content: "\1f601"; }

.fa-face-laugh-squint::before {
  content: "\f59b"; }

.fa-laugh-squint::before {
  content: "\f59b"; }

.fa-face-laugh-wink::before {
  content: "\f59c"; }

.fa-laugh-wink::before {
  content: "\f59c"; }

.fa-face-lying::before {
  content: "\e37e"; }

.fa-face-mask::before {
  content: "\e37f"; }

.fa-face-meh::before {
  content: "\1f610"; }

.fa-meh::before {
  content: "\1f610"; }

.fa-face-meh-blank::before {
  content: "\1f636"; }

.fa-meh-blank::before {
  content: "\1f636"; }

.fa-face-monocle::before {
  content: "\e380"; }

.fa-face-nauseated::before {
  content: "\e381"; }

.fa-face-nose-steam::before {
  content: "\e382"; }

.fa-face-party::before {
  content: "\e383"; }

.fa-face-pensive::before {
  content: "\e384"; }

.fa-face-persevering::before {
  content: "\e385"; }

.fa-face-pleading::before {
  content: "\e386"; }

.fa-face-pouting::before {
  content: "\e387"; }

.fa-face-raised-eyebrow::before {
  content: "\e388"; }

.fa-face-relieved::before {
  content: "\e389"; }

.fa-face-rolling-eyes::before {
  content: "\1f644"; }

.fa-meh-rolling-eyes::before {
  content: "\1f644"; }

.fa-face-sad-cry::before {
  content: "\1f62d"; }

.fa-sad-cry::before {
  content: "\1f62d"; }

.fa-face-sad-sweat::before {
  content: "\e38a"; }

.fa-face-sad-tear::before {
  content: "\1f622"; }

.fa-sad-tear::before {
  content: "\1f622"; }

.fa-face-scream::before {
  content: "\e38b"; }

.fa-face-shush::before {
  content: "\e38c"; }

.fa-face-sleeping::before {
  content: "\e38d"; }

.fa-face-sleepy::before {
  content: "\e38e"; }

.fa-face-smile::before {
  content: "\1f642"; }

.fa-smile::before {
  content: "\1f642"; }

.fa-face-smile-beam::before {
  content: "\1f60a"; }

.fa-smile-beam::before {
  content: "\1f60a"; }

.fa-face-smile-halo::before {
  content: "\e38f"; }

.fa-face-smile-hearts::before {
  content: "\e390"; }

.fa-face-smile-horns::before {
  content: "\e391"; }

.fa-face-smile-plus::before {
  content: "\f5b9"; }

.fa-smile-plus::before {
  content: "\f5b9"; }

.fa-face-smile-relaxed::before {
  content: "\e392"; }

.fa-face-smile-tear::before {
  content: "\e393"; }

.fa-face-smile-tongue::before {
  content: "\e394"; }

.fa-face-smile-upside-down::before {
  content: "\e395"; }

.fa-face-smile-wink::before {
  content: "\1f609"; }

.fa-smile-wink::before {
  content: "\1f609"; }

.fa-face-smiling-hands::before {
  content: "\e396"; }

.fa-face-smirking::before {
  content: "\e397"; }

.fa-face-sunglasses::before {
  content: "\e398"; }

.fa-face-surprise::before {
  content: "\1f62e"; }

.fa-surprise::before {
  content: "\1f62e"; }

.fa-face-swear::before {
  content: "\e399"; }

.fa-face-thermometer::before {
  content: "\e39a"; }

.fa-face-thinking::before {
  content: "\e39b"; }

.fa-face-tired::before {
  content: "\1f62b"; }

.fa-tired::before {
  content: "\1f62b"; }

.fa-face-tissue::before {
  content: "\e39c"; }

.fa-face-tongue-money::before {
  content: "\e39d"; }

.fa-face-tongue-sweat::before {
  content: "\e39e"; }

.fa-face-unamused::before {
  content: "\e39f"; }

.fa-face-viewfinder::before {
  content: "\e2ff"; }

.fa-face-vomit::before {
  content: "\e3a0"; }

.fa-face-weary::before {
  content: "\e3a1"; }

.fa-face-woozy::before {
  content: "\e3a2"; }

.fa-face-worried::before {
  content: "\e3a3"; }

.fa-face-zany::before {
  content: "\e3a4"; }

.fa-face-zipper::before {
  content: "\e3a5"; }

.fa-family::before {
  content: "\e300"; }

.fa-family-dress::before {
  content: "\e301"; }

.fa-family-pants::before {
  content: "\e302"; }

.fa-fan::before {
  content: "\f863"; }

.fa-fan-table::before {
  content: "\e004"; }

.fa-farm::before {
  content: "\f864"; }

.fa-barn-silo::before {
  content: "\f864"; }

.fa-faucet::before {
  content: "\e005"; }

.fa-faucet-drip::before {
  content: "\1f6b0"; }

.fa-fax::before {
  content: "\1f4e0"; }

.fa-feather::before {
  content: "\1fab6"; }

.fa-feather-pointed::before {
  content: "\f56b"; }

.fa-feather-alt::before {
  content: "\f56b"; }

.fa-fence::before {
  content: "\e303"; }

.fa-ferris-wheel::before {
  content: "\e174"; }

.fa-field-hockey-stick-ball::before {
  content: "\1f3d1"; }

.fa-field-hockey::before {
  content: "\1f3d1"; }

.fa-file::before {
  content: "\1f4c4"; }

.fa-file-arrow-down::before {
  content: "\f56d"; }

.fa-file-download::before {
  content: "\f56d"; }

.fa-file-arrow-up::before {
  content: "\f574"; }

.fa-file-upload::before {
  content: "\f574"; }

.fa-file-audio::before {
  content: "\f1c7"; }

.fa-file-binary::before {
  content: "\e175"; }

.fa-file-certificate::before {
  content: "\f5f3"; }

.fa-file-award::before {
  content: "\f5f3"; }

.fa-file-chart-column::before {
  content: "\f659"; }

.fa-file-chart-line::before {
  content: "\f659"; }

.fa-file-chart-pie::before {
  content: "\f65a"; }

.fa-file-check::before {
  content: "\f316"; }

.fa-file-code::before {
  content: "\f1c9"; }

.fa-file-contract::before {
  content: "\f56c"; }

.fa-file-csv::before {
  content: "\f6dd"; }

.fa-file-dashed-line::before {
  content: "\f877"; }

.fa-page-break::before {
  content: "\f877"; }

.fa-file-excel::before {
  content: "\f1c3"; }

.fa-file-exclamation::before {
  content: "\f31a"; }

.fa-file-export::before {
  content: "\f56e"; }

.fa-arrow-right-from-file::before {
  content: "\f56e"; }

.fa-file-heart::before {
  content: "\e176"; }

.fa-file-image::before {
  content: "\1f5bb"; }

.fa-file-import::before {
  content: "\f56f"; }

.fa-arrow-right-to-file::before {
  content: "\f56f"; }

.fa-file-invoice::before {
  content: "\f570"; }

.fa-file-invoice-dollar::before {
  content: "\f571"; }

.fa-file-lines::before {
  content: "\1f5b9"; }

.fa-file-alt::before {
  content: "\1f5b9"; }

.fa-file-text::before {
  content: "\1f5b9"; }

.fa-file-lock::before {
  content: "\e3a6"; }

.fa-file-magnifying-glass::before {
  content: "\f865"; }

.fa-file-search::before {
  content: "\f865"; }

.fa-file-medical::before {
  content: "\f477"; }

.fa-file-minus::before {
  content: "\f318"; }

.fa-file-music::before {
  content: "\f8b6"; }

.fa-file-pdf::before {
  content: "\f1c1"; }

.fa-file-pen::before {
  content: "\1f4dd"; }

.fa-file-edit::before {
  content: "\1f4dd"; }

.fa-file-plus::before {
  content: "\f319"; }

.fa-file-plus-minus::before {
  content: "\e177"; }

.fa-file-powerpoint::before {
  content: "\f1c4"; }

.fa-file-prescription::before {
  content: "\f572"; }

.fa-file-signature::before {
  content: "\f573"; }

.fa-file-slash::before {
  content: "\e3a7"; }

.fa-file-spreadsheet::before {
  content: "\f65b"; }

.fa-file-user::before {
  content: "\f65c"; }

.fa-file-video::before {
  content: "\f1c8"; }

.fa-file-waveform::before {
  content: "\f478"; }

.fa-file-medical-alt::before {
  content: "\f478"; }

.fa-file-word::before {
  content: "\f1c2"; }

.fa-file-xmark::before {
  content: "\f317"; }

.fa-file-times::before {
  content: "\f317"; }

.fa-file-zipper::before {
  content: "\f1c6"; }

.fa-file-archive::before {
  content: "\f1c6"; }

.fa-files::before {
  content: "\e178"; }

.fa-files-medical::before {
  content: "\f7fd"; }

.fa-fill::before {
  content: "\f575"; }

.fa-fill-drip::before {
  content: "\f576"; }

.fa-film::before {
  content: "\1f39e"; }

.fa-film-canister::before {
  content: "\f8b7"; }

.fa-film-simple::before {
  content: "\f3a0"; }

.fa-film-alt::before {
  content: "\f3a0"; }

.fa-film-slash::before {
  content: "\e179"; }

.fa-films::before {
  content: "\e17a"; }

.fa-filter::before {
  content: "\f0b0"; }

.fa-filter-circle-dollar::before {
  content: "\f662"; }

.fa-funnel-dollar::before {
  content: "\f662"; }

.fa-filter-circle-xmark::before {
  content: "\e17b"; }

.fa-filter-list::before {
  content: "\e17c"; }

.fa-filter-slash::before {
  content: "\e17d"; }

.fa-filters::before {
  content: "\e17e"; }

.fa-fingerprint::before {
  content: "\f577"; }

.fa-fire::before {
  content: "\1f525"; }

.fa-fire-extinguisher::before {
  content: "\1f9ef"; }

.fa-fire-flame::before {
  content: "\f6df"; }

.fa-flame::before {
  content: "\f6df"; }

.fa-fire-flame-curved::before {
  content: "\f7e4"; }

.fa-fire-alt::before {
  content: "\f7e4"; }

.fa-fire-flame-simple::before {
  content: "\f46a"; }

.fa-burn::before {
  content: "\f46a"; }

.fa-fire-hydrant::before {
  content: "\e17f"; }

.fa-fire-smoke::before {
  content: "\f74b"; }

.fa-fireplace::before {
  content: "\f79a"; }

.fa-fish::before {
  content: "\1f41f"; }

.fa-fish-bones::before {
  content: "\e304"; }

.fa-fish-cooked::before {
  content: "\f7fe"; }

.fa-fishing-rod::before {
  content: "\e3a8"; }

.fa-flag::before {
  content: "\1f3f4"; }

.fa-flag-checkered::before {
  content: "\1f3c1"; }

.fa-flag-pennant::before {
  content: "\1f6a9"; }

.fa-pennant::before {
  content: "\1f6a9"; }

.fa-flag-swallowtail::before {
  content: "\f74c"; }

.fa-flag-alt::before {
  content: "\f74c"; }

.fa-flag-usa::before {
  content: "\f74d"; }

.fa-flashlight::before {
  content: "\1f526"; }

.fa-flask::before {
  content: "\f0c3"; }

.fa-flask-round-poison::before {
  content: "\f6e0"; }

.fa-flask-poison::before {
  content: "\f6e0"; }

.fa-flask-round-potion::before {
  content: "\f6e1"; }

.fa-flask-potion::before {
  content: "\f6e1"; }

.fa-floppy-disk::before {
  content: "\1f4be"; }

.fa-save::before {
  content: "\1f4be"; }

.fa-floppy-disk-circle-arrow-right::before {
  content: "\e180"; }

.fa-save-circle-arrow-right::before {
  content: "\e180"; }

.fa-floppy-disk-circle-xmark::before {
  content: "\e181"; }

.fa-floppy-disk-times::before {
  content: "\e181"; }

.fa-save-circle-xmark::before {
  content: "\e181"; }

.fa-save-times::before {
  content: "\e181"; }

.fa-floppy-disk-pen::before {
  content: "\e182"; }

.fa-floppy-disks::before {
  content: "\e183"; }

.fa-florin-sign::before {
  content: "\e184"; }

.fa-flower::before {
  content: "\273f"; }

.fa-flower-daffodil::before {
  content: "\2698"; }

.fa-flower-tulip::before {
  content: "\1f337"; }

.fa-flute::before {
  content: "\f8b9"; }

.fa-flux-capacitor::before {
  content: "\f8ba"; }

.fa-flying-disc::before {
  content: "\e3a9"; }

.fa-folder::before {
  content: "\1f4c1"; }

.fa-folder-arrow-down::before {
  content: "\e053"; }

.fa-folder-download::before {
  content: "\e053"; }

.fa-folder-arrow-up::before {
  content: "\e054"; }

.fa-folder-upload::before {
  content: "\e054"; }

.fa-folder-blank::before {
  content: "\e185"; }

.fa-folder-bookmark::before {
  content: "\e186"; }

.fa-folder-gear::before {
  content: "\e187"; }

.fa-folder-cog::before {
  content: "\e187"; }

.fa-folder-grid::before {
  content: "\e188"; }

.fa-folder-heart::before {
  content: "\e189"; }

.fa-folder-image::before {
  content: "\e18a"; }

.fa-folder-magnifying-glass::before {
  content: "\e18b"; }

.fa-folder-search::before {
  content: "\e18b"; }

.fa-folder-medical::before {
  content: "\e18c"; }

.fa-folder-minus::before {
  content: "\f65d"; }

.fa-folder-music::before {
  content: "\e18d"; }

.fa-folder-open::before {
  content: "\1f4c2"; }

.fa-folder-plus::before {
  content: "\f65e"; }

.fa-folder-tree::before {
  content: "\f802"; }

.fa-folder-user::before {
  content: "\e18e"; }

.fa-folder-xmark::before {
  content: "\f65f"; }

.fa-folder-times::before {
  content: "\f65f"; }

.fa-folders::before {
  content: "\f660"; }

.fa-font::before {
  content: "\f031"; }

.fa-font-case::before {
  content: "\f866"; }

.fa-football::before {
  content: "\1f3c8"; }

.fa-football-ball::before {
  content: "\1f3c8"; }

.fa-football-helmet::before {
  content: "\f44f"; }

.fa-fork::before {
  content: "\f2e3"; }

.fa-utensil-fork::before {
  content: "\f2e3"; }

.fa-fork-knife::before {
  content: "\f2e6"; }

.fa-utensils-alt::before {
  content: "\f2e6"; }

.fa-forklift::before {
  content: "\f47a"; }

.fa-forward::before {
  content: "\23e9"; }

.fa-forward-fast::before {
  content: "\23ed"; }

.fa-fast-forward::before {
  content: "\23ed"; }

.fa-forward-step::before {
  content: "\f051"; }

.fa-step-forward::before {
  content: "\f051"; }

.fa-franc-sign::before {
  content: "\e18f"; }

.fa-french-fries::before {
  content: "\1f35f"; }

.fa-frog::before {
  content: "\f52e"; }

.fa-function::before {
  content: "\f661"; }

.fa-futbol::before {
  content: "\26bd"; }

.fa-futbol-ball::before {
  content: "\26bd"; }

.fa-soccer-ball::before {
  content: "\26bd"; }

.fa-g::before {
  content: "\47"; }

.fa-galaxy::before {
  content: "\e008"; }

.fa-gallery-thumbnails::before {
  content: "\e3aa"; }

.fa-game-board::before {
  content: "\f867"; }

.fa-game-board-simple::before {
  content: "\f868"; }

.fa-game-board-alt::before {
  content: "\f868"; }

.fa-game-console-handheld::before {
  content: "\f8bb"; }

.fa-gamepad::before {
  content: "\f11b"; }

.fa-gamepad-modern::before {
  content: "\1f3ae"; }

.fa-gamepad-alt::before {
  content: "\1f3ae"; }

.fa-garage::before {
  content: "\e009"; }

.fa-garage-car::before {
  content: "\e00a"; }

.fa-garage-open::before {
  content: "\e00b"; }

.fa-gas-pump::before {
  content: "\26fd"; }

.fa-gas-pump-slash::before {
  content: "\f5f4"; }

.fa-gauge::before {
  content: "\f625"; }

.fa-dashboard::before {
  content: "\f625"; }

.fa-gauge-high::before {
  content: "\f625"; }

.fa-tachometer-alt::before {
  content: "\f625"; }

.fa-tachometer-alt-fast::before {
  content: "\f625"; }

.fa-gauge-low::before {
  content: "\f627"; }

.fa-tachometer-alt-slow::before {
  content: "\f627"; }

.fa-gauge-max::before {
  content: "\f626"; }

.fa-tachometer-alt-fastest::before {
  content: "\f626"; }

.fa-gauge-med::before {
  content: "\f624"; }

.fa-tachometer-alt-average::before {
  content: "\f624"; }

.fa-gauge-min::before {
  content: "\f628"; }

.fa-tachometer-alt-slowest::before {
  content: "\f628"; }

.fa-gauge-simple::before {
  content: "\f62a"; }

.fa-gauge-simple-high::before {
  content: "\f62a"; }

.fa-tachometer::before {
  content: "\f62a"; }

.fa-gauge-simple-low::before {
  content: "\f62c"; }

.fa-tachometer-slow::before {
  content: "\f62c"; }

.fa-gauge-simple-max::before {
  content: "\f62b"; }

.fa-tachometer-fastest::before {
  content: "\f62b"; }

.fa-gauge-simple-med::before {
  content: "\f629"; }

.fa-tachometer-average::before {
  content: "\f629"; }

.fa-gauge-simple-min::before {
  content: "\f62d"; }

.fa-tachometer-slowest::before {
  content: "\f62d"; }

.fa-gavel::before {
  content: "\f0e3"; }

.fa-legal::before {
  content: "\f0e3"; }

.fa-gear::before {
  content: "\2699"; }

.fa-cog::before {
  content: "\2699"; }

.fa-gears::before {
  content: "\f085"; }

.fa-cogs::before {
  content: "\f085"; }

.fa-gem::before {
  content: "\1f48e"; }

.fa-genderless::before {
  content: "\f22d"; }

.fa-ghost::before {
  content: "\1f47b"; }

.fa-gif::before {
  content: "\e190"; }

.fa-gift::before {
  content: "\1f381"; }

.fa-gift-card::before {
  content: "\f663"; }

.fa-gifts::before {
  content: "\f79c"; }

.fa-gingerbread-man::before {
  content: "\f79d"; }

.fa-glass::before {
  content: "\1f95b"; }

.fa-glass-citrus::before {
  content: "\f869"; }

.fa-glass-empty::before {
  content: "\e191"; }

.fa-glass-half::before {
  content: "\e192"; }

.fa-glass-half-empty::before {
  content: "\e192"; }

.fa-glass-half-full::before {
  content: "\e192"; }

.fa-glasses::before {
  content: "\f530"; }

.fa-glasses-round::before {
  content: "\1f453"; }

.fa-glasses-alt::before {
  content: "\1f453"; }

.fa-globe::before {
  content: "\1f310"; }

.fa-globe-snow::before {
  content: "\f7a3"; }

.fa-globe-stand::before {
  content: "\f5f6"; }

.fa-goal-net::before {
  content: "\e3ab"; }

.fa-golf-ball-tee::before {
  content: "\f450"; }

.fa-golf-ball::before {
  content: "\f450"; }

.fa-golf-club::before {
  content: "\f451"; }

.fa-golf-flag-hole::before {
  content: "\e3ac"; }

.fa-gopuram::before {
  content: "\f664"; }

.fa-graduation-cap::before {
  content: "\1f393"; }

.fa-mortar-board::before {
  content: "\1f393"; }

.fa-gramophone::before {
  content: "\f8bd"; }

.fa-grapes::before {
  content: "\e306"; }

.fa-grate::before {
  content: "\e193"; }

.fa-grate-droplet::before {
  content: "\e194"; }

.fa-greater-than::before {
  content: "\3e"; }

.fa-greater-than-equal::before {
  content: "\f532"; }

.fa-grid::before {
  content: "\e195"; }

.fa-grid-3::before {
  content: "\e195"; }

.fa-grid-2::before {
  content: "\e196"; }

.fa-grid-2-plus::before {
  content: "\e197"; }

.fa-grid-4::before {
  content: "\e198"; }

.fa-grid-5::before {
  content: "\e199"; }

.fa-grid-dividers::before {
  content: "\e3ad"; }

.fa-grid-horizontal::before {
  content: "\e307"; }

.fa-grip::before {
  content: "\f58d"; }

.fa-grip-horizontal::before {
  content: "\f58d"; }

.fa-grip-lines::before {
  content: "\f7a4"; }

.fa-grip-lines-vertical::before {
  content: "\f7a5"; }

.fa-grip-vertical::before {
  content: "\f58e"; }

.fa-guarani-sign::before {
  content: "\e19a"; }

.fa-guitar::before {
  content: "\f7a6"; }

.fa-guitar-electric::before {
  content: "\1f3b8"; }

.fa-guitars::before {
  content: "\f8bf"; }

.fa-gun::before {
  content: "\e19b"; }

.fa-gun-slash::before {
  content: "\e19c"; }

.fa-gun-squirt::before {
  content: "\e19d"; }

.fa-h::before {
  content: "\48"; }

.fa-h1::before {
  content: "\f313"; }

.fa-h2::before {
  content: "\f314"; }

.fa-h3::before {
  content: "\f315"; }

.fa-h4::before {
  content: "\f86a"; }

.fa-hammer::before {
  content: "\1f528"; }

.fa-hammer-war::before {
  content: "\f6e4"; }

.fa-hamsa::before {
  content: "\f665"; }

.fa-hand::before {
  content: "\270b"; }

.fa-hand-paper::before {
  content: "\270b"; }

.fa-hand-back-fist::before {
  content: "\f255"; }

.fa-hand-rock::before {
  content: "\f255"; }

.fa-hand-back-point-down::before {
  content: "\e19e"; }

.fa-hand-back-point-left::before {
  content: "\e19f"; }

.fa-hand-back-point-ribbon::before {
  content: "\e1a0"; }

.fa-hand-back-point-right::before {
  content: "\e1a1"; }

.fa-hand-back-point-up::before {
  content: "\e1a2"; }

.fa-hand-dots::before {
  content: "\f461"; }

.fa-allergies::before {
  content: "\f461"; }

.fa-hand-fingers-crossed::before {
  content: "\e1a3"; }

.fa-hand-fist::before {
  content: "\270a"; }

.fa-fist-raised::before {
  content: "\270a"; }

.fa-hand-heart::before {
  content: "\f4bc"; }

.fa-hand-holding::before {
  content: "\f4bd"; }

.fa-hand-holding-box::before {
  content: "\f47b"; }

.fa-hand-holding-dollar::before {
  content: "\f4c0"; }

.fa-hand-holding-usd::before {
  content: "\f4c0"; }

.fa-hand-holding-droplet::before {
  content: "\f4c1"; }

.fa-hand-holding-water::before {
  content: "\f4c1"; }

.fa-hand-holding-heart::before {
  content: "\f4be"; }

.fa-hand-holding-magic::before {
  content: "\f6e5"; }

.fa-hand-holding-medical::before {
  content: "\e05c"; }

.fa-hand-holding-seedling::before {
  content: "\f4bf"; }

.fa-hand-holding-skull::before {
  content: "\e1a4"; }

.fa-hand-horns::before {
  content: "\e1a9"; }

.fa-hand-lizard::before {
  content: "\f258"; }

.fa-hand-love::before {
  content: "\e1a5"; }

.fa-hand-middle-finger::before {
  content: "\1f595"; }

.fa-hand-peace::before {
  content: "\270c"; }

.fa-hand-point-down::before {
  content: "\f0a7"; }

.fa-hand-point-left::before {
  content: "\f0a5"; }

.fa-hand-point-ribbon::before {
  content: "\e1a6"; }

.fa-hand-point-right::before {
  content: "\f0a4"; }

.fa-hand-point-up::before {
  content: "\261d"; }

.fa-hand-pointer::before {
  content: "\f25a"; }

.fa-hand-scissors::before {
  content: "\f257"; }

.fa-hand-sparkles::before {
  content: "\e05d"; }

.fa-hand-spock::before {
  content: "\1f596"; }

.fa-hand-wave::before {
  content: "\e1a7"; }

.fa-hands::before {
  content: "\f2a7"; }

.fa-sign-language::before {
  content: "\f2a7"; }

.fa-signing::before {
  content: "\f2a7"; }

.fa-hands-asl-interpreting::before {
  content: "\f2a3"; }

.fa-american-sign-language-interpreting::before {
  content: "\f2a3"; }

.fa-asl-interpreting::before {
  content: "\f2a3"; }

.fa-hands-american-sign-language-interpreting::before {
  content: "\f2a3"; }

.fa-hands-bubbles::before {
  content: "\e05e"; }

.fa-hands-wash::before {
  content: "\e05e"; }

.fa-hands-clapping::before {
  content: "\e1a8"; }

.fa-hands-holding::before {
  content: "\f4c2"; }

.fa-hands-holding-diamond::before {
  content: "\f47c"; }

.fa-hand-receiving::before {
  content: "\f47c"; }

.fa-hands-holding-dollar::before {
  content: "\f4c5"; }

.fa-hands-usd::before {
  content: "\f4c5"; }

.fa-hands-holding-heart::before {
  content: "\f4c3"; }

.fa-hands-heart::before {
  content: "\f4c3"; }

.fa-hands-praying::before {
  content: "\f684"; }

.fa-praying-hands::before {
  content: "\f684"; }

.fa-handshake::before {
  content: "\f2b5"; }

.fa-handshake-angle::before {
  content: "\f4c4"; }

.fa-hands-helping::before {
  content: "\f4c4"; }

.fa-handshake-simple::before {
  content: "\1f91d"; }

.fa-handshake-alt::before {
  content: "\1f91d"; }

.fa-handshake-simple-slash::before {
  content: "\e05f"; }

.fa-handshake-alt-slash::before {
  content: "\e05f"; }

.fa-handshake-slash::before {
  content: "\e060"; }

.fa-hanukiah::before {
  content: "\1f54e"; }

.fa-hard-drive::before {
  content: "\1f5b4"; }

.fa-hdd::before {
  content: "\1f5b4"; }

.fa-hashtag::before {
  content: "\23"; }

.fa-hat-chef::before {
  content: "\f86b"; }

.fa-hat-cowboy::before {
  content: "\f8c0"; }

.fa-hat-cowboy-side::before {
  content: "\f8c1"; }

.fa-hat-santa::before {
  content: "\f7a7"; }

.fa-hat-winter::before {
  content: "\f7a8"; }

.fa-hat-witch::before {
  content: "\f6e7"; }

.fa-hat-wizard::before {
  content: "\f6e8"; }

.fa-head-side::before {
  content: "\f6e9"; }

.fa-head-side-brain::before {
  content: "\f808"; }

.fa-head-side-cough::before {
  content: "\e061"; }

.fa-head-side-cough-slash::before {
  content: "\e062"; }

.fa-head-side-goggles::before {
  content: "\f6ea"; }

.fa-head-vr::before {
  content: "\f6ea"; }

.fa-head-side-headphones::before {
  content: "\f8c2"; }

.fa-head-side-heart::before {
  content: "\e1aa"; }

.fa-head-side-mask::before {
  content: "\e063"; }

.fa-head-side-medical::before {
  content: "\f809"; }

.fa-head-side-virus::before {
  content: "\e064"; }

.fa-heading::before {
  content: "\f1dc"; }

.fa-header::before {
  content: "\f1dc"; }

.fa-headphones::before {
  content: "\1f3a7"; }

.fa-headphones-simple::before {
  content: "\f58f"; }

.fa-headphones-alt::before {
  content: "\f58f"; }

.fa-headset::before {
  content: "\f590"; }

.fa-heart::before {
  content: "\2665"; }

.fa-heart-crack::before {
  content: "\1f494"; }

.fa-heart-broken::before {
  content: "\1f494"; }

.fa-heart-half::before {
  content: "\e1ab"; }

.fa-heart-half-stroke::before {
  content: "\e1ac"; }

.fa-heart-half-alt::before {
  content: "\e1ac"; }

.fa-heart-pulse::before {
  content: "\f21e"; }

.fa-heartbeat::before {
  content: "\f21e"; }

.fa-heat::before {
  content: "\e00c"; }

.fa-helicopter::before {
  content: "\1f681"; }

.fa-helmet-battle::before {
  content: "\f6eb"; }

.fa-helmet-safety::before {
  content: "\f807"; }

.fa-hard-hat::before {
  content: "\f807"; }

.fa-hat-hard::before {
  content: "\f807"; }

.fa-hexagon::before {
  content: "\2b23"; }

.fa-hexagon-divide::before {
  content: "\e1ad"; }

.fa-hexagon-minus::before {
  content: "\f307"; }

.fa-minus-hexagon::before {
  content: "\f307"; }

.fa-hexagon-plus::before {
  content: "\f300"; }

.fa-plus-hexagon::before {
  content: "\f300"; }

.fa-hexagon-xmark::before {
  content: "\f2ee"; }

.fa-times-hexagon::before {
  content: "\f2ee"; }

.fa-xmark-hexagon::before {
  content: "\f2ee"; }

.fa-high-definition::before {
  content: "\e1ae"; }

.fa-rectangle-hd::before {
  content: "\e1ae"; }

.fa-highlighter::before {
  content: "\f591"; }

.fa-highlighter-line::before {
  content: "\e1af"; }

.fa-hippo::before {
  content: "\1f99b"; }

.fa-hockey-mask::before {
  content: "\f6ee"; }

.fa-hockey-puck::before {
  content: "\f453"; }

.fa-hockey-stick-puck::before {
  content: "\e3ae"; }

.fa-hockey-sticks::before {
  content: "\f454"; }

.fa-holly-berry::before {
  content: "\f7aa"; }

.fa-hood-cloak::before {
  content: "\f6ef"; }

.fa-horizontal-rule::before {
  content: "\2015"; }

.fa-horse::before {
  content: "\1f40e"; }

.fa-horse-head::before {
  content: "\f7ab"; }

.fa-horse-saddle::before {
  content: "\f8c3"; }

.fa-hospital::before {
  content: "\1f3e5"; }

.fa-hospital-user::before {
  content: "\f80d"; }

.fa-hospital-wide::before {
  content: "\f47d"; }

.fa-hospital-alt::before {
  content: "\f47d"; }

.fa-hospitals::before {
  content: "\f80e"; }

.fa-hot-tub-person::before {
  content: "\f593"; }

.fa-hot-tub::before {
  content: "\f593"; }

.fa-hotdog::before {
  content: "\1f32d"; }

.fa-hotel::before {
  content: "\1f3e8"; }

.fa-hourglass::before {
  content: "\23f3"; }

.fa-hourglass-2::before {
  content: "\23f3"; }

.fa-hourglass-half::before {
  content: "\23f3"; }

.fa-hourglass-empty::before {
  content: "\f252"; }

.fa-hourglass-end::before {
  content: "\231b"; }

.fa-hourglass-3::before {
  content: "\231b"; }

.fa-hourglass-start::before {
  content: "\f251"; }

.fa-hourglass-1::before {
  content: "\f251"; }

.fa-house::before {
  content: "\1f3e0"; }

.fa-home::before {
  content: "\1f3e0"; }

.fa-house-blank::before {
  content: "\f80a"; }

.fa-home-blank::before {
  content: "\f80a"; }

.fa-home-lg-alt::before {
  content: "\f80a"; }

.fa-house-building::before {
  content: "\e1b1"; }

.fa-house-chimney::before {
  content: "\e3af"; }

.fa-home-lg::before {
  content: "\e3af"; }

.fa-house-chimney-blank::before {
  content: "\e3b0"; }

.fa-house-chimney-crack::before {
  content: "\f6f1"; }

.fa-house-damage::before {
  content: "\f6f1"; }

.fa-house-chimney-heart::before {
  content: "\e1b2"; }

.fa-house-chimney-medical::before {
  content: "\f7f2"; }

.fa-clinic-medical::before {
  content: "\f7f2"; }

.fa-house-chimney-user::before {
  content: "\e065"; }

.fa-house-chimney-window::before {
  content: "\e00d"; }

.fa-house-crack::before {
  content: "\e3b1"; }

.fa-house-day::before {
  content: "\e00e"; }

.fa-house-flood::before {
  content: "\f74f"; }

.fa-house-heart::before {
  content: "\f4c9"; }

.fa-home-heart::before {
  content: "\f4c9"; }

.fa-house-laptop::before {
  content: "\e066"; }

.fa-laptop-house::before {
  content: "\e066"; }

.fa-house-medical::before {
  content: "\e3b2"; }

.fa-house-night::before {
  content: "\e010"; }

.fa-house-person-leave::before {
  content: "\e00f"; }

.fa-house-person-depart::before {
  content: "\e00f"; }

.fa-house-person-return::before {
  content: "\e011"; }

.fa-house-person-arrive::before {
  content: "\e011"; }

.fa-house-signal::before {
  content: "\e012"; }

.fa-house-tree::before {
  content: "\e1b3"; }

.fa-house-turret::before {
  content: "\e1b4"; }

.fa-house-user::before {
  content: "\e1b0"; }

.fa-home-user::before {
  content: "\e1b0"; }

.fa-house-window::before {
  content: "\e3b3"; }

.fa-hryvnia-sign::before {
  content: "\20b4"; }

.fa-hryvnia::before {
  content: "\20b4"; }

.fa-hurricane::before {
  content: "\f751"; }

.fa-hyphen::before {
  content: "\2d"; }

.fa-i::before {
  content: "\49"; }

.fa-i-cursor::before {
  content: "\f246"; }

.fa-ice-cream::before {
  content: "\1f368"; }

.fa-ice-skate::before {
  content: "\26f8"; }

.fa-icicles::before {
  content: "\f7ad"; }

.fa-icons::before {
  content: "\f86d"; }

.fa-heart-music-camera-bolt::before {
  content: "\f86d"; }

.fa-id-badge::before {
  content: "\f2c1"; }

.fa-id-card::before {
  content: "\f2c2"; }

.fa-drivers-license::before {
  content: "\f2c2"; }

.fa-id-card-clip::before {
  content: "\f47f"; }

.fa-id-card-alt::before {
  content: "\f47f"; }

.fa-igloo::before {
  content: "\f7ae"; }

.fa-image::before {
  content: "\f03e"; }

.fa-image-landscape::before {
  content: "\e1b5"; }

.fa-landscape::before {
  content: "\e1b5"; }

.fa-image-polaroid::before {
  content: "\f8c4"; }

.fa-image-polaroid-user::before {
  content: "\e1b6"; }

.fa-image-portrait::before {
  content: "\f3e0"; }

.fa-portrait::before {
  content: "\f3e0"; }

.fa-image-slash::before {
  content: "\e1b7"; }

.fa-image-user::before {
  content: "\e1b8"; }

.fa-images::before {
  content: "\f302"; }

.fa-images-user::before {
  content: "\e1b9"; }

.fa-inbox::before {
  content: "\f01c"; }

.fa-inbox-full::before {
  content: "\e1ba"; }

.fa-inbox-in::before {
  content: "\1f4e5"; }

.fa-inbox-arrow-down::before {
  content: "\1f4e5"; }

.fa-inbox-out::before {
  content: "\1f4e4"; }

.fa-inbox-arrow-up::before {
  content: "\1f4e4"; }

.fa-inboxes::before {
  content: "\e1bb"; }

.fa-indent::before {
  content: "\f03c"; }

.fa-indian-rupee-sign::before {
  content: "\e1bc"; }

.fa-indian-rupee::before {
  content: "\e1bc"; }

.fa-inr::before {
  content: "\e1bc"; }

.fa-industry::before {
  content: "\f275"; }

.fa-industry-windows::before {
  content: "\1f3ed"; }

.fa-industry-alt::before {
  content: "\1f3ed"; }

.fa-infinity::before {
  content: "\221e"; }

.fa-info::before {
  content: "\f129"; }

.fa-inhaler::before {
  content: "\f5f9"; }

.fa-input-numeric::before {
  content: "\e1bd"; }

.fa-input-pipe::before {
  content: "\e1be"; }

.fa-input-text::before {
  content: "\e1bf"; }

.fa-integral::before {
  content: "\f667"; }

.fa-intersection::before {
  content: "\22c2"; }

.fa-island-tropical::before {
  content: "\1f3dd"; }

.fa-island-tree-palm::before {
  content: "\1f3dd"; }

.fa-italic::before {
  content: "\f033"; }

.fa-j::before {
  content: "\4a"; }

.fa-jack-o-lantern::before {
  content: "\1f383"; }

.fa-jedi::before {
  content: "\f669"; }

.fa-jet-fighter::before {
  content: "\f0fb"; }

.fa-fighter-jet::before {
  content: "\f0fb"; }

.fa-joint::before {
  content: "\f595"; }

.fa-joystick::before {
  content: "\1f579"; }

.fa-jug::before {
  content: "\f8c6"; }

.fa-k::before {
  content: "\4b"; }

.fa-kaaba::before {
  content: "\1f54b"; }

.fa-kazoo::before {
  content: "\f8c7"; }

.fa-kerning::before {
  content: "\f86f"; }

.fa-key::before {
  content: "\1f511"; }

.fa-key-skeleton::before {
  content: "\1f5dd"; }

.fa-key-skeleton-left-right::before {
  content: "\e3b4"; }

.fa-keyboard::before {
  content: "\2328"; }

.fa-keyboard-brightness::before {
  content: "\e1c0"; }

.fa-keyboard-brightness-low::before {
  content: "\e1c1"; }

.fa-keyboard-down::before {
  content: "\e1c2"; }

.fa-keyboard-left::before {
  content: "\e1c3"; }

.fa-keynote::before {
  content: "\f66c"; }

.fa-khanda::before {
  content: "\262c"; }

.fa-kidneys::before {
  content: "\f5fb"; }

.fa-kip-sign::before {
  content: "\e1c4"; }

.fa-kit-medical::before {
  content: "\f479"; }

.fa-first-aid::before {
  content: "\f479"; }

.fa-kite::before {
  content: "\1fa81"; }

.fa-kiwi-bird::before {
  content: "\f535"; }

.fa-kiwi-fruit::before {
  content: "\e30c"; }

.fa-knife::before {
  content: "\f2e4"; }

.fa-utensil-knife::before {
  content: "\f2e4"; }

.fa-knife-kitchen::before {
  content: "\1f52a"; }

.fa-l::before {
  content: "\4c"; }

.fa-lacrosse-stick::before {
  content: "\e3b5"; }

.fa-lacrosse-stick-ball::before {
  content: "\e3b6"; }

.fa-lambda::before {
  content: "\f66e"; }

.fa-lamp::before {
  content: "\f4ca"; }

.fa-lamp-desk::before {
  content: "\e014"; }

.fa-lamp-floor::before {
  content: "\e015"; }

.fa-lamp-street::before {
  content: "\e1c5"; }

.fa-landmark::before {
  content: "\1f3db"; }

.fa-landmark-dome::before {
  content: "\f752"; }

.fa-landmark-alt::before {
  content: "\f752"; }

.fa-language::before {
  content: "\f1ab"; }

.fa-laptop::before {
  content: "\1f4bb"; }

.fa-laptop-arrow-down::before {
  content: "\e1c6"; }

.fa-laptop-code::before {
  content: "\f5fc"; }

.fa-laptop-medical::before {
  content: "\f812"; }

.fa-laptop-mobile::before {
  content: "\f87a"; }

.fa-phone-laptop::before {
  content: "\f87a"; }

.fa-laptop-slash::before {
  content: "\e1c7"; }

.fa-lari-sign::before {
  content: "\e1c8"; }

.fa-lasso::before {
  content: "\f8c8"; }

.fa-lasso-sparkles::before {
  content: "\e1c9"; }

.fa-layer-group::before {
  content: "\f5fd"; }

.fa-layer-minus::before {
  content: "\f5fe"; }

.fa-layer-group-minus::before {
  content: "\f5fe"; }

.fa-layer-plus::before {
  content: "\f5ff"; }

.fa-layer-group-plus::before {
  content: "\f5ff"; }

.fa-leaf::before {
  content: "\f06c"; }

.fa-leaf-heart::before {
  content: "\f4cb"; }

.fa-leaf-maple::before {
  content: "\1f341"; }

.fa-leaf-oak::before {
  content: "\f6f7"; }

.fa-left::before {
  content: "\f355"; }

.fa-arrow-alt-left::before {
  content: "\f355"; }

.fa-left-from-line::before {
  content: "\f348"; }

.fa-arrow-alt-from-right::before {
  content: "\f348"; }

.fa-left-long::before {
  content: "\f30a"; }

.fa-long-arrow-alt-left::before {
  content: "\f30a"; }

.fa-left-right::before {
  content: "\2194"; }

.fa-arrows-alt-h::before {
  content: "\2194"; }

.fa-left-to-line::before {
  content: "\f34b"; }

.fa-arrow-alt-to-left::before {
  content: "\f34b"; }

.fa-lemon::before {
  content: "\1f34b"; }

.fa-less-than::before {
  content: "\3c"; }

.fa-less-than-equal::before {
  content: "\f537"; }

.fa-life-ring::before {
  content: "\f1cd"; }

.fa-light-ceiling::before {
  content: "\e016"; }

.fa-light-switch::before {
  content: "\e017"; }

.fa-light-switch-off::before {
  content: "\e018"; }

.fa-light-switch-on::before {
  content: "\e019"; }

.fa-lightbulb::before {
  content: "\1f4a1"; }

.fa-lightbulb-dollar::before {
  content: "\f670"; }

.fa-lightbulb-exclamation::before {
  content: "\f671"; }

.fa-lightbulb-exclamation-on::before {
  content: "\e1ca"; }

.fa-lightbulb-on::before {
  content: "\f672"; }

.fa-lightbulb-slash::before {
  content: "\f673"; }

.fa-lights-holiday::before {
  content: "\f7b2"; }

.fa-line-columns::before {
  content: "\f870"; }

.fa-line-height::before {
  content: "\f871"; }

.fa-link::before {
  content: "\1f517"; }

.fa-chain::before {
  content: "\1f517"; }

.fa-link-horizontal::before {
  content: "\e1cb"; }

.fa-chain-horizontal::before {
  content: "\e1cb"; }

.fa-link-horizontal-slash::before {
  content: "\e1cc"; }

.fa-chain-horizontal-slash::before {
  content: "\e1cc"; }

.fa-link-simple::before {
  content: "\e1cd"; }

.fa-link-simple-slash::before {
  content: "\e1ce"; }

.fa-link-slash::before {
  content: "\f127"; }

.fa-chain-broken::before {
  content: "\f127"; }

.fa-chain-slash::before {
  content: "\f127"; }

.fa-unlink::before {
  content: "\f127"; }

.fa-lips::before {
  content: "\1f444"; }

.fa-lira-sign::before {
  content: "\20a4"; }

.fa-list::before {
  content: "\f03a"; }

.fa-list-squares::before {
  content: "\f03a"; }

.fa-list-check::before {
  content: "\f0ae"; }

.fa-tasks::before {
  content: "\f0ae"; }

.fa-list-dropdown::before {
  content: "\e1cf"; }

.fa-list-music::before {
  content: "\f8c9"; }

.fa-list-ol::before {
  content: "\f0cb"; }

.fa-list-1-2::before {
  content: "\f0cb"; }

.fa-list-numeric::before {
  content: "\f0cb"; }

.fa-list-radio::before {
  content: "\e1d0"; }

.fa-list-timeline::before {
  content: "\e1d1"; }

.fa-list-tree::before {
  content: "\e1d2"; }

.fa-list-ul::before {
  content: "\f0ca"; }

.fa-list-dots::before {
  content: "\f0ca"; }

.fa-litecoin-sign::before {
  content: "\e1d3"; }

.fa-loader::before {
  content: "\e1d4"; }

.fa-location::before {
  content: "\f041"; }

.fa-map-marker::before {
  content: "\f041"; }

.fa-location-arrow::before {
  content: "\f124"; }

.fa-location-check::before {
  content: "\f606"; }

.fa-map-marker-check::before {
  content: "\f606"; }

.fa-location-crosshairs::before {
  content: "\f601"; }

.fa-location-crosshairs-slash::before {
  content: "\f603"; }

.fa-location-dot::before {
  content: "\f3c5"; }

.fa-map-marker-alt::before {
  content: "\f3c5"; }

.fa-location-dot-slash::before {
  content: "\f605"; }

.fa-map-marker-alt-slash::before {
  content: "\f605"; }

.fa-location-exclamation::before {
  content: "\f608"; }

.fa-map-marker-exclamation::before {
  content: "\f608"; }

.fa-location-minus::before {
  content: "\f609"; }

.fa-map-marker-minus::before {
  content: "\f609"; }

.fa-location-pen::before {
  content: "\f607"; }

.fa-map-marker-edit::before {
  content: "\f607"; }

.fa-location-plus::before {
  content: "\f60a"; }

.fa-map-marker-plus::before {
  content: "\f60a"; }

.fa-location-question::before {
  content: "\f60b"; }

.fa-map-marker-question::before {
  content: "\f60b"; }

.fa-location-slash::before {
  content: "\f60c"; }

.fa-map-marker-slash::before {
  content: "\f60c"; }

.fa-location-smile::before {
  content: "\f60d"; }

.fa-map-marker-smile::before {
  content: "\f60d"; }

.fa-location-xmark::before {
  content: "\f60e"; }

.fa-map-marker-times::before {
  content: "\f60e"; }

.fa-map-marker-xmark::before {
  content: "\f60e"; }

.fa-lock::before {
  content: "\1f512"; }

.fa-lock-keyhole::before {
  content: "\f30d"; }

.fa-lock-alt::before {
  content: "\f30d"; }

.fa-lock-keyhole-open::before {
  content: "\f3c2"; }

.fa-lock-open-alt::before {
  content: "\f3c2"; }

.fa-lock-open::before {
  content: "\f3c1"; }

.fa-loveseat::before {
  content: "\f4cc"; }

.fa-couch-small::before {
  content: "\f4cc"; }

.fa-luchador-mask::before {
  content: "\f455"; }

.fa-luchador::before {
  content: "\f455"; }

.fa-mask-luchador::before {
  content: "\f455"; }

.fa-lungs::before {
  content: "\1fac1"; }

.fa-lungs-virus::before {
  content: "\e067"; }

.fa-m::before {
  content: "\4d"; }

.fa-mace::before {
  content: "\f6f8"; }

.fa-magnet::before {
  content: "\1f9f2"; }

.fa-magnifying-glass::before {
  content: "\1f50d"; }

.fa-search::before {
  content: "\1f50d"; }

.fa-magnifying-glass-dollar::before {
  content: "\f688"; }

.fa-search-dollar::before {
  content: "\f688"; }

.fa-magnifying-glass-location::before {
  content: "\f689"; }

.fa-search-location::before {
  content: "\f689"; }

.fa-magnifying-glass-minus::before {
  content: "\f010"; }

.fa-search-minus::before {
  content: "\f010"; }

.fa-magnifying-glass-plus::before {
  content: "\f00e"; }

.fa-search-plus::before {
  content: "\f00e"; }

.fa-mailbox::before {
  content: "\1f4ea"; }

.fa-manat-sign::before {
  content: "\e1d5"; }

.fa-mandolin::before {
  content: "\f6f9"; }

.fa-mango::before {
  content: "\e30f"; }

.fa-manhole::before {
  content: "\e1d6"; }

.fa-map::before {
  content: "\1f5fa"; }

.fa-map-location::before {
  content: "\f59f"; }

.fa-map-marked::before {
  content: "\f59f"; }

.fa-map-location-dot::before {
  content: "\f5a0"; }

.fa-map-marked-alt::before {
  content: "\f5a0"; }

.fa-map-pin::before {
  content: "\1f4cd"; }

.fa-marker::before {
  content: "\f5a1"; }

.fa-mars::before {
  content: "\2642"; }

.fa-mars-and-venus::before {
  content: "\26a5"; }

.fa-mars-double::before {
  content: "\26a3"; }

.fa-mars-stroke::before {
  content: "\26a6"; }

.fa-mars-stroke-right::before {
  content: "\26a9"; }

.fa-mars-stroke-h::before {
  content: "\26a9"; }

.fa-mars-stroke-up::before {
  content: "\26a8"; }

.fa-mars-stroke-v::before {
  content: "\26a8"; }

.fa-martini-glass::before {
  content: "\1f378"; }

.fa-glass-martini-alt::before {
  content: "\1f378"; }

.fa-martini-glass-citrus::before {
  content: "\f561"; }

.fa-cocktail::before {
  content: "\f561"; }

.fa-martini-glass-empty::before {
  content: "\f000"; }

.fa-glass-martini::before {
  content: "\f000"; }

.fa-mask::before {
  content: "\f6fa"; }

.fa-mask-face::before {
  content: "\e1d7"; }

.fa-mask-snorkel::before {
  content: "\e3b7"; }

.fa-masks-theater::before {
  content: "\1f3ad"; }

.fa-theater-masks::before {
  content: "\1f3ad"; }

.fa-maximize::before {
  content: "\f31e"; }

.fa-expand-arrows-alt::before {
  content: "\f31e"; }

.fa-meat::before {
  content: "\1f356"; }

.fa-medal::before {
  content: "\1f3c5"; }

.fa-megaphone::before {
  content: "\1f4e3"; }

.fa-melon::before {
  content: "\e310"; }

.fa-melon-slice::before {
  content: "\e311"; }

.fa-memo::before {
  content: "\e1d8"; }

.fa-memo-circle-check::before {
  content: "\e1d9"; }

.fa-memo-pad::before {
  content: "\e1da"; }

.fa-memory::before {
  content: "\f538"; }

.fa-menorah::before {
  content: "\f676"; }

.fa-mercury::before {
  content: "\263f"; }

.fa-message::before {
  content: "\f27a"; }

.fa-comment-alt::before {
  content: "\f27a"; }

.fa-message-arrow-down::before {
  content: "\e1db"; }

.fa-comment-alt-arrow-down::before {
  content: "\e1db"; }

.fa-message-arrow-up::before {
  content: "\e1dc"; }

.fa-comment-alt-arrow-up::before {
  content: "\e1dc"; }

.fa-message-arrow-up-right::before {
  content: "\e1dd"; }

.fa-message-bot::before {
  content: "\e3b8"; }

.fa-message-captions::before {
  content: "\e1de"; }

.fa-comment-alt-captions::before {
  content: "\e1de"; }

.fa-message-check::before {
  content: "\f4a2"; }

.fa-comment-alt-check::before {
  content: "\f4a2"; }

.fa-message-code::before {
  content: "\e1df"; }

.fa-message-dollar::before {
  content: "\f650"; }

.fa-comment-alt-dollar::before {
  content: "\f650"; }

.fa-message-dots::before {
  content: "\f4a3"; }

.fa-comment-alt-dots::before {
  content: "\f4a3"; }

.fa-messaging::before {
  content: "\f4a3"; }

.fa-message-exclamation::before {
  content: "\f4a5"; }

.fa-comment-alt-exclamation::before {
  content: "\f4a5"; }

.fa-message-image::before {
  content: "\e1e0"; }

.fa-comment-alt-image::before {
  content: "\e1e0"; }

.fa-message-lines::before {
  content: "\f4a6"; }

.fa-comment-alt-lines::before {
  content: "\f4a6"; }

.fa-message-medical::before {
  content: "\f7f4"; }

.fa-comment-alt-medical::before {
  content: "\f7f4"; }

.fa-message-middle::before {
  content: "\e1e1"; }

.fa-comment-middle-alt::before {
  content: "\e1e1"; }

.fa-message-middle-top::before {
  content: "\e1e2"; }

.fa-comment-middle-top-alt::before {
  content: "\e1e2"; }

.fa-message-minus::before {
  content: "\f4a7"; }

.fa-comment-alt-minus::before {
  content: "\f4a7"; }

.fa-message-music::before {
  content: "\f8af"; }

.fa-comment-alt-music::before {
  content: "\f8af"; }

.fa-message-pen::before {
  content: "\f4a4"; }

.fa-comment-alt-edit::before {
  content: "\f4a4"; }

.fa-message-edit::before {
  content: "\f4a4"; }

.fa-message-plus::before {
  content: "\f4a8"; }

.fa-comment-alt-plus::before {
  content: "\f4a8"; }

.fa-message-question::before {
  content: "\e1e3"; }

.fa-message-quote::before {
  content: "\e1e4"; }

.fa-comment-alt-quote::before {
  content: "\e1e4"; }

.fa-message-slash::before {
  content: "\f4a9"; }

.fa-comment-alt-slash::before {
  content: "\f4a9"; }

.fa-message-smile::before {
  content: "\f4aa"; }

.fa-comment-alt-smile::before {
  content: "\f4aa"; }

.fa-message-sms::before {
  content: "\e1e5"; }

.fa-message-text::before {
  content: "\e1e6"; }

.fa-comment-alt-text::before {
  content: "\e1e6"; }

.fa-message-xmark::before {
  content: "\f4ab"; }

.fa-comment-alt-times::before {
  content: "\f4ab"; }

.fa-message-times::before {
  content: "\f4ab"; }

.fa-messages::before {
  content: "\f4b6"; }

.fa-comments-alt::before {
  content: "\f4b6"; }

.fa-messages-dollar::before {
  content: "\f652"; }

.fa-comments-alt-dollar::before {
  content: "\f652"; }

.fa-messages-question::before {
  content: "\e1e7"; }

.fa-meteor::before {
  content: "\2604"; }

.fa-meter::before {
  content: "\e1e8"; }

.fa-meter-bolt::before {
  content: "\e1e9"; }

.fa-meter-droplet::before {
  content: "\e1ea"; }

.fa-meter-fire::before {
  content: "\e1eb"; }

.fa-microchip::before {
  content: "\f2db"; }

.fa-microchip-ai::before {
  content: "\e1ec"; }

.fa-microphone::before {
  content: "\f130"; }

.fa-microphone-lines::before {
  content: "\1f399"; }

.fa-microphone-alt::before {
  content: "\1f399"; }

.fa-microphone-lines-slash::before {
  content: "\f539"; }

.fa-microphone-alt-slash::before {
  content: "\f539"; }

.fa-microphone-slash::before {
  content: "\f131"; }

.fa-microphone-stand::before {
  content: "\1f3a4"; }

.fa-microscope::before {
  content: "\1f52c"; }

.fa-microwave::before {
  content: "\e01b"; }

.fa-mill-sign::before {
  content: "\e1ed"; }

.fa-minimize::before {
  content: "\f78c"; }

.fa-compress-arrows-alt::before {
  content: "\f78c"; }

.fa-minus::before {
  content: "\2013"; }

.fa-subtract::before {
  content: "\2013"; }

.fa-mistletoe::before {
  content: "\f7b4"; }

.fa-mitten::before {
  content: "\f7b5"; }

.fa-mobile::before {
  content: "\1f4f1"; }

.fa-mobile-android::before {
  content: "\1f4f1"; }

.fa-mobile-phone::before {
  content: "\1f4f1"; }

.fa-mobile-button::before {
  content: "\f10b"; }

.fa-mobile-notch::before {
  content: "\e1ee"; }

.fa-mobile-iphone::before {
  content: "\e1ee"; }

.fa-mobile-screen::before {
  content: "\f3cf"; }

.fa-mobile-android-alt::before {
  content: "\f3cf"; }

.fa-mobile-screen-button::before {
  content: "\f3cd"; }

.fa-mobile-alt::before {
  content: "\f3cd"; }

.fa-mobile-signal::before {
  content: "\e1ef"; }

.fa-mobile-signal-out::before {
  content: "\e1f0"; }

.fa-money-bill::before {
  content: "\f0d6"; }

.fa-money-bill-1::before {
  content: "\f3d1"; }

.fa-money-bill-alt::before {
  content: "\f3d1"; }

.fa-money-bill-1-wave::before {
  content: "\f53b"; }

.fa-money-bill-wave-alt::before {
  content: "\f53b"; }

.fa-money-bill-simple::before {
  content: "\e1f1"; }

.fa-money-bill-simple-wave::before {
  content: "\e1f2"; }

.fa-money-bill-wave::before {
  content: "\f53a"; }

.fa-money-bills::before {
  content: "\e1f3"; }

.fa-money-bills-simple::before {
  content: "\e1f4"; }

.fa-money-bills-alt::before {
  content: "\e1f4"; }

.fa-money-check::before {
  content: "\f53c"; }

.fa-money-check-dollar::before {
  content: "\f53d"; }

.fa-money-check-alt::before {
  content: "\f53d"; }

.fa-money-check-dollar-pen::before {
  content: "\f873"; }

.fa-money-check-edit-alt::before {
  content: "\f873"; }

.fa-money-check-pen::before {
  content: "\f872"; }

.fa-money-check-edit::before {
  content: "\f872"; }

.fa-money-from-bracket::before {
  content: "\e312"; }

.fa-money-simple-from-bracket::before {
  content: "\e313"; }

.fa-monitor-waveform::before {
  content: "\f611"; }

.fa-monitor-heart-rate::before {
  content: "\f611"; }

.fa-monkey::before {
  content: "\1f412"; }

.fa-monument::before {
  content: "\f5a6"; }

.fa-moon::before {
  content: "\23fe"; }

.fa-moon-cloud::before {
  content: "\f754"; }

.fa-moon-over-sun::before {
  content: "\f74a"; }

.fa-eclipse-alt::before {
  content: "\f74a"; }

.fa-moon-stars::before {
  content: "\f755"; }

.fa-moped::before {
  content: "\e3b9"; }

.fa-mortar-pestle::before {
  content: "\f5a7"; }

.fa-mosque::before {
  content: "\1f54c"; }

.fa-motorcycle::before {
  content: "\1f3cd"; }

.fa-mountain::before {
  content: "\1f3d4"; }

.fa-mountains::before {
  content: "\26f0"; }

.fa-mp3-player::before {
  content: "\f8ce"; }

.fa-mug::before {
  content: "\f874"; }

.fa-mug-hot::before {
  content: "\2615"; }

.fa-mug-marshmallows::before {
  content: "\f7b7"; }

.fa-mug-saucer::before {
  content: "\f0f4"; }

.fa-coffee::before {
  content: "\f0f4"; }

.fa-mug-tea::before {
  content: "\f875"; }

.fa-mug-tea-saucer::before {
  content: "\e1f5"; }

.fa-music::before {
  content: "\1f3b5"; }

.fa-music-note::before {
  content: "\f8cf"; }

.fa-music-alt::before {
  content: "\f8cf"; }

.fa-music-note-slash::before {
  content: "\f8d0"; }

.fa-music-alt-slash::before {
  content: "\f8d0"; }

.fa-music-slash::before {
  content: "\f8d1"; }

.fa-n::before {
  content: "\4e"; }

.fa-naira-sign::before {
  content: "\e1f6"; }

.fa-narwhal::before {
  content: "\f6fe"; }

.fa-nesting-dolls::before {
  content: "\e3ba"; }

.fa-network-wired::before {
  content: "\f6ff"; }

.fa-neuter::before {
  content: "\26b2"; }

.fa-newspaper::before {
  content: "\1f4f0"; }

.fa-nfc::before {
  content: "\e1f7"; }

.fa-nfc-lock::before {
  content: "\e1f8"; }

.fa-nfc-magnifying-glass::before {
  content: "\e1f9"; }

.fa-nfc-pen::before {
  content: "\e1fa"; }

.fa-nfc-signal::before {
  content: "\e1fb"; }

.fa-nfc-slash::before {
  content: "\e1fc"; }

.fa-nfc-trash::before {
  content: "\e1fd"; }

.fa-not-equal::before {
  content: "\f53e"; }

.fa-notdef::before {
  content: "\e1fe"; }

.fa-note::before {
  content: "\e1ff"; }

.fa-note-medical::before {
  content: "\e200"; }

.fa-note-sticky::before {
  content: "\f249"; }

.fa-sticky-note::before {
  content: "\f249"; }

.fa-notebook::before {
  content: "\e201"; }

.fa-notes::before {
  content: "\e202"; }

.fa-notes-medical::before {
  content: "\f481"; }

.fa-o::before {
  content: "\4f"; }

.fa-object-group::before {
  content: "\f247"; }

.fa-object-ungroup::before {
  content: "\f248"; }

.fa-objects-align-bottom::before {
  content: "\e3bb"; }

.fa-objects-align-center-horizontal::before {
  content: "\e3bc"; }

.fa-objects-align-center-vertical::before {
  content: "\e3bd"; }

.fa-objects-align-left::before {
  content: "\e3be"; }

.fa-objects-align-right::before {
  content: "\e3bf"; }

.fa-objects-align-top::before {
  content: "\e3c0"; }

.fa-objects-column::before {
  content: "\e3c1"; }

.fa-octagon::before {
  content: "\1f6d1"; }

.fa-octagon-divide::before {
  content: "\e203"; }

.fa-octagon-exclamation::before {
  content: "\e204"; }

.fa-octagon-minus::before {
  content: "\f308"; }

.fa-minus-octagon::before {
  content: "\f308"; }

.fa-octagon-plus::before {
  content: "\f301"; }

.fa-plus-octagon::before {
  content: "\f301"; }

.fa-octagon-xmark::before {
  content: "\f2f0"; }

.fa-times-octagon::before {
  content: "\f2f0"; }

.fa-xmark-octagon::before {
  content: "\f2f0"; }

.fa-oil-can::before {
  content: "\f613"; }

.fa-oil-can-drip::before {
  content: "\e205"; }

.fa-oil-temperature::before {
  content: "\f614"; }

.fa-oil-temp::before {
  content: "\f614"; }

.fa-olive::before {
  content: "\e316"; }

.fa-olive-branch::before {
  content: "\e317"; }

.fa-om::before {
  content: "\1f549"; }

.fa-omega::before {
  content: "\f67a"; }

.fa-option::before {
  content: "\e318"; }

.fa-ornament::before {
  content: "\f7b8"; }

.fa-otter::before {
  content: "\1f9a6"; }

.fa-outdent::before {
  content: "\f03b"; }

.fa-dedent::before {
  content: "\f03b"; }

.fa-outlet::before {
  content: "\e01c"; }

.fa-oven::before {
  content: "\e01d"; }

.fa-overline::before {
  content: "\f876"; }

.fa-p::before {
  content: "\50"; }

.fa-pager::before {
  content: "\1f4df"; }

.fa-paint-brush::before {
  content: "\1f58c"; }

.fa-paint-brush-fine::before {
  content: "\f5a9"; }

.fa-paint-brush-alt::before {
  content: "\f5a9"; }

.fa-paint-roller::before {
  content: "\f5aa"; }

.fa-paintbrush-pencil::before {
  content: "\e206"; }

.fa-palette::before {
  content: "\1f3a8"; }

.fa-pallet::before {
  content: "\f482"; }

.fa-pallet-box::before {
  content: "\e208"; }

.fa-pallet-boxes::before {
  content: "\f483"; }

.fa-palette-boxes::before {
  content: "\f483"; }

.fa-pallet-alt::before {
  content: "\f483"; }

.fa-panorama::before {
  content: "\e209"; }

.fa-paper-plane::before {
  content: "\f1d8"; }

.fa-paper-plane-top::before {
  content: "\e20a"; }

.fa-paper-plane-alt::before {
  content: "\e20a"; }

.fa-send::before {
  content: "\e20a"; }

.fa-paperclip::before {
  content: "\1f4ce"; }

.fa-paperclip-vertical::before {
  content: "\e3c2"; }

.fa-parachute-box::before {
  content: "\f4cd"; }

.fa-paragraph::before {
  content: "\b6"; }

.fa-paragraph-left::before {
  content: "\f878"; }

.fa-paragraph-rtl::before {
  content: "\f878"; }

.fa-party-bell::before {
  content: "\e31a"; }

.fa-party-horn::before {
  content: "\e31b"; }

.fa-passport::before {
  content: "\f5ab"; }

.fa-paste::before {
  content: "\f0ea"; }

.fa-file-clipboard::before {
  content: "\f0ea"; }

.fa-pause::before {
  content: "\23f8"; }

.fa-paw::before {
  content: "\f1b0"; }

.fa-paw-claws::before {
  content: "\f702"; }

.fa-paw-simple::before {
  content: "\f701"; }

.fa-paw-alt::before {
  content: "\f701"; }

.fa-peace::before {
  content: "\262e"; }

.fa-peach::before {
  content: "\e20b"; }

.fa-peapod::before {
  content: "\e31c"; }

.fa-pear::before {
  content: "\e20c"; }

.fa-pedestal::before {
  content: "\e20d"; }

.fa-pegasus::before {
  content: "\f703"; }

.fa-pen::before {
  content: "\1f58a"; }

.fa-pen-circle::before {
  content: "\e20e"; }

.fa-pen-clip::before {
  content: "\f305"; }

.fa-pen-alt::before {
  content: "\f305"; }

.fa-pen-clip-slash::before {
  content: "\e20f"; }

.fa-pen-alt-slash::before {
  content: "\e20f"; }

.fa-pen-fancy::before {
  content: "\2712"; }

.fa-pen-fancy-slash::before {
  content: "\e210"; }

.fa-pen-field::before {
  content: "\e211"; }

.fa-pen-line::before {
  content: "\e212"; }

.fa-pen-nib::before {
  content: "\2711"; }

.fa-pen-paintbrush::before {
  content: "\f618"; }

.fa-pencil-paintbrush::before {
  content: "\f618"; }

.fa-pen-ruler::before {
  content: "\f5ae"; }

.fa-pencil-ruler::before {
  content: "\f5ae"; }

.fa-pen-slash::before {
  content: "\e213"; }

.fa-pen-swirl::before {
  content: "\e214"; }

.fa-pen-to-square::before {
  content: "\f044"; }

.fa-edit::before {
  content: "\f044"; }

.fa-pencil::before {
  content: "\270f"; }

.fa-pencil-alt::before {
  content: "\270f"; }

.fa-pencil-slash::before {
  content: "\e215"; }

.fa-people::before {
  content: "\e216"; }

.fa-people-arrows-left-right::before {
  content: "\e068"; }

.fa-people-arrows::before {
  content: "\e068"; }

.fa-people-carry-box::before {
  content: "\f4ce"; }

.fa-people-carry::before {
  content: "\f4ce"; }

.fa-people-dress::before {
  content: "\e217"; }

.fa-people-dress-simple::before {
  content: "\e218"; }

.fa-people-pants::before {
  content: "\e219"; }

.fa-people-pants-simple::before {
  content: "\e21a"; }

.fa-people-simple::before {
  content: "\e21b"; }

.fa-pepper-hot::before {
  content: "\1f336"; }

.fa-percent::before {
  content: "\25"; }

.fa-percentage::before {
  content: "\25"; }

.fa-period::before {
  content: "\2e"; }

.fa-person::before {
  content: "\1f9cd"; }

.fa-male::before {
  content: "\1f9cd"; }

.fa-person-biking::before {
  content: "\1f6b4"; }

.fa-biking::before {
  content: "\1f6b4"; }

.fa-person-biking-mountain::before {
  content: "\1f6b5"; }

.fa-biking-mountain::before {
  content: "\1f6b5"; }

.fa-person-booth::before {
  content: "\f756"; }

.fa-person-carry-box::before {
  content: "\f4cf"; }

.fa-person-carry::before {
  content: "\f4cf"; }

.fa-person-digging::before {
  content: "\f85e"; }

.fa-digging::before {
  content: "\f85e"; }

.fa-person-dolly::before {
  content: "\f4d0"; }

.fa-person-dolly-empty::before {
  content: "\f4d1"; }

.fa-person-dots-from-line::before {
  content: "\f470"; }

.fa-diagnoses::before {
  content: "\f470"; }

.fa-person-dress::before {
  content: "\f182"; }

.fa-female::before {
  content: "\f182"; }

.fa-person-dress-simple::before {
  content: "\e21c"; }

.fa-person-from-portal::before {
  content: "\e023"; }

.fa-portal-exit::before {
  content: "\e023"; }

.fa-person-hiking::before {
  content: "\f6ec"; }

.fa-hiking::before {
  content: "\f6ec"; }

.fa-person-pinball::before {
  content: "\e21d"; }

.fa-person-praying::before {
  content: "\1f6d0"; }

.fa-pray::before {
  content: "\1f6d0"; }

.fa-person-pregnant::before {
  content: "\e31e"; }

.fa-person-running::before {
  content: "\1f3c3"; }

.fa-running::before {
  content: "\1f3c3"; }

.fa-person-seat::before {
  content: "\e21e"; }

.fa-person-seat-reclined::before {
  content: "\e21f"; }

.fa-person-sign::before {
  content: "\f757"; }

.fa-person-simple::before {
  content: "\e220"; }

.fa-person-skating::before {
  content: "\f7c5"; }

.fa-skating::before {
  content: "\f7c5"; }

.fa-person-ski-jumping::before {
  content: "\f7c7"; }

.fa-ski-jump::before {
  content: "\f7c7"; }

.fa-person-ski-lift::before {
  content: "\f7c8"; }

.fa-ski-lift::before {
  content: "\f7c8"; }

.fa-person-skiing::before {
  content: "\26f7"; }

.fa-skiing::before {
  content: "\26f7"; }

.fa-person-skiing-nordic::before {
  content: "\f7ca"; }

.fa-skiing-nordic::before {
  content: "\f7ca"; }

.fa-person-sledding::before {
  content: "\f7cb"; }

.fa-sledding::before {
  content: "\f7cb"; }

.fa-person-snowboarding::before {
  content: "\1f3c2"; }

.fa-snowboarding::before {
  content: "\1f3c2"; }

.fa-person-snowmobiling::before {
  content: "\f7d1"; }

.fa-snowmobile::before {
  content: "\f7d1"; }

.fa-person-swimming::before {
  content: "\1f3ca"; }

.fa-swimmer::before {
  content: "\1f3ca"; }

.fa-person-to-portal::before {
  content: "\e022"; }

.fa-portal-enter::before {
  content: "\e022"; }

.fa-person-walking::before {
  content: "\1f6b6"; }

.fa-walking::before {
  content: "\1f6b6"; }

.fa-person-walking-with-cane::before {
  content: "\f29d"; }

.fa-blind::before {
  content: "\f29d"; }

.fa-peseta-sign::before {
  content: "\e221"; }

.fa-peso-sign::before {
  content: "\e222"; }

.fa-phone::before {
  content: "\1f4de"; }

.fa-phone-arrow-down-left::before {
  content: "\e223"; }

.fa-phone-arrow-down::before {
  content: "\e223"; }

.fa-phone-incoming::before {
  content: "\e223"; }

.fa-phone-arrow-up-right::before {
  content: "\e224"; }

.fa-phone-arrow-up::before {
  content: "\e224"; }

.fa-phone-outgoing::before {
  content: "\e224"; }

.fa-phone-flip::before {
  content: "\1f57d"; }

.fa-phone-alt::before {
  content: "\1f57d"; }

.fa-phone-hangup::before {
  content: "\e225"; }

.fa-phone-missed::before {
  content: "\e226"; }

.fa-phone-office::before {
  content: "\f67d"; }

.fa-phone-plus::before {
  content: "\f4d2"; }

.fa-phone-rotary::before {
  content: "\260e"; }

.fa-phone-slash::before {
  content: "\f3dd"; }

.fa-phone-volume::before {
  content: "\f2a0"; }

.fa-volume-control-phone::before {
  content: "\f2a0"; }

.fa-phone-xmark::before {
  content: "\e227"; }

.fa-photo-film::before {
  content: "\f87c"; }

.fa-photo-video::before {
  content: "\f87c"; }

.fa-photo-film-music::before {
  content: "\e228"; }

.fa-pi::before {
  content: "\f67e"; }

.fa-piano::before {
  content: "\f8d4"; }

.fa-piano-keyboard::before {
  content: "\1f3b9"; }

.fa-pie::before {
  content: "\1f967"; }

.fa-pig::before {
  content: "\1f416"; }

.fa-piggy-bank::before {
  content: "\f4d3"; }

.fa-pills::before {
  content: "\f484"; }

.fa-pinata::before {
  content: "\e3c3"; }

.fa-pinball::before {
  content: "\e229"; }

.fa-pineapple::before {
  content: "\e31f"; }

.fa-pipe::before {
  content: "\7c"; }

.fa-pipe-smoking::before {
  content: "\e3c4"; }

.fa-pizza::before {
  content: "\1f355"; }

.fa-pizza-slice::before {
  content: "\f818"; }

.fa-place-of-worship::before {
  content: "\f67f"; }

.fa-plane::before {
  content: "\f072"; }

.fa-plane-arrival::before {
  content: "\1f6ec"; }

.fa-plane-departure::before {
  content: "\1f6eb"; }

.fa-plane-engines::before {
  content: "\2708"; }

.fa-plane-alt::before {
  content: "\2708"; }

.fa-plane-prop::before {
  content: "\e22b"; }

.fa-plane-slash::before {
  content: "\e069"; }

.fa-plane-tail::before {
  content: "\e22c"; }

.fa-plane-up::before {
  content: "\e22d"; }

.fa-plane-up-slash::before {
  content: "\e22e"; }

.fa-planet-moon::before {
  content: "\e01f"; }

.fa-planet-ringed::before {
  content: "\1fa90"; }

.fa-play::before {
  content: "\25b6"; }

.fa-play-pause::before {
  content: "\e22f"; }

.fa-plug::before {
  content: "\1f50c"; }

.fa-plus::before {
  content: "\2b"; }

.fa-add::before {
  content: "\2b"; }

.fa-plus-minus::before {
  content: "\b1"; }

.fa-podcast::before {
  content: "\f2ce"; }

.fa-podium::before {
  content: "\f680"; }

.fa-podium-star::before {
  content: "\f758"; }

.fa-police-box::before {
  content: "\e021"; }

.fa-poll-people::before {
  content: "\f759"; }

.fa-poo::before {
  content: "\1f4a9"; }

.fa-poo-bolt::before {
  content: "\f75a"; }

.fa-poo-storm::before {
  content: "\f75a"; }

.fa-pool-8-ball::before {
  content: "\e3c5"; }

.fa-poop::before {
  content: "\f619"; }

.fa-popcorn::before {
  content: "\1f37f"; }

.fa-power-off::before {
  content: "\23fb"; }

.fa-prescription::before {
  content: "\f5b1"; }

.fa-prescription-bottle::before {
  content: "\f485"; }

.fa-prescription-bottle-medical::before {
  content: "\f486"; }

.fa-prescription-bottle-alt::before {
  content: "\f486"; }

.fa-presentation-screen::before {
  content: "\f685"; }

.fa-presentation::before {
  content: "\f685"; }

.fa-print::before {
  content: "\2399"; }

.fa-print-magnifying-glass::before {
  content: "\f81a"; }

.fa-print-search::before {
  content: "\f81a"; }

.fa-print-slash::before {
  content: "\f686"; }

.fa-projector::before {
  content: "\f8d6"; }

.fa-pump-medical::before {
  content: "\e06a"; }

.fa-pump-soap::before {
  content: "\e06b"; }

.fa-pumpkin::before {
  content: "\f707"; }

.fa-puzzle-piece::before {
  content: "\1f9e9"; }

.fa-puzzle-piece-simple::before {
  content: "\e231"; }

.fa-puzzle-piece-alt::before {
  content: "\e231"; }

.fa-q::before {
  content: "\51"; }

.fa-qrcode::before {
  content: "\f029"; }

.fa-question::before {
  content: "\3f"; }

.fa-quidditch::before {
  content: "\f458"; }

.fa-broom-ball::before {
  content: "\f458"; }

.fa-quidditch-broom-ball::before {
  content: "\f458"; }

.fa-quote-left::before {
  content: "\201c"; }

.fa-quote-left-alt::before {
  content: "\201c"; }

.fa-quote-right::before {
  content: "\201d"; }

.fa-quote-right-alt::before {
  content: "\201d"; }

.fa-quotes::before {
  content: "\e234"; }

.fa-r::before {
  content: "\52"; }

.fa-rabbit::before {
  content: "\1f407"; }

.fa-rabbit-running::before {
  content: "\f709"; }

.fa-rabbit-fast::before {
  content: "\f709"; }

.fa-racquet::before {
  content: "\f45a"; }

.fa-radar::before {
  content: "\e024"; }

.fa-radiation::before {
  content: "\f7b9"; }

.fa-radio::before {
  content: "\1f4fb"; }

.fa-radio-tuner::before {
  content: "\f8d8"; }

.fa-radio-alt::before {
  content: "\f8d8"; }

.fa-rainbow::before {
  content: "\1f308"; }

.fa-raindrops::before {
  content: "\f75c"; }

.fa-ram::before {
  content: "\1f40f"; }

.fa-ramp-loading::before {
  content: "\f4d4"; }

.fa-raygun::before {
  content: "\e025"; }

.fa-receipt::before {
  content: "\1f9fe"; }

.fa-record-vinyl::before {
  content: "\f8d9"; }

.fa-rectangle::before {
  content: "\25ac"; }

.fa-rectangle-landscape::before {
  content: "\25ac"; }

.fa-rectangle-ad::before {
  content: "\f641"; }

.fa-ad::before {
  content: "\f641"; }

.fa-rectangle-barcode::before {
  content: "\f463"; }

.fa-barcode-alt::before {
  content: "\f463"; }

.fa-rectangle-code::before {
  content: "\e322"; }

.fa-rectangle-list::before {
  content: "\f022"; }

.fa-list-alt::before {
  content: "\f022"; }

.fa-rectangle-pro::before {
  content: "\e235"; }

.fa-pro::before {
  content: "\e235"; }

.fa-rectangle-terminal::before {
  content: "\e236"; }

.fa-rectangle-vertical::before {
  content: "\f2fb"; }

.fa-rectangle-portrait::before {
  content: "\f2fb"; }

.fa-rectangle-vertical-history::before {
  content: "\e237"; }

.fa-rectangle-wide::before {
  content: "\f2fc"; }

.fa-rectangle-xmark::before {
  content: "\f410"; }

.fa-rectangle-times::before {
  content: "\f410"; }

.fa-times-rectangle::before {
  content: "\f410"; }

.fa-window-close::before {
  content: "\f410"; }

.fa-rectangles-mixed::before {
  content: "\e323"; }

.fa-recycle::before {
  content: "\2672"; }

.fa-reel::before {
  content: "\e238"; }

.fa-refrigerator::before {
  content: "\e026"; }

.fa-registered::before {
  content: "\ae"; }

.fa-repeat::before {
  content: "\1f501"; }

.fa-repeat-1::before {
  content: "\1f502"; }

.fa-reply::before {
  content: "\f3e5"; }

.fa-mail-reply::before {
  content: "\f3e5"; }

.fa-reply-all::before {
  content: "\f122"; }

.fa-mail-reply-all::before {
  content: "\f122"; }

.fa-reply-clock::before {
  content: "\e239"; }

.fa-reply-time::before {
  content: "\e239"; }

.fa-republican::before {
  content: "\f75e"; }

.fa-restroom::before {
  content: "\f7bd"; }

.fa-restroom-simple::before {
  content: "\e23a"; }

.fa-retweet::before {
  content: "\f079"; }

.fa-rhombus::before {
  content: "\e23b"; }

.fa-ribbon::before {
  content: "\1f397"; }

.fa-right::before {
  content: "\2b95"; }

.fa-arrow-alt-right::before {
  content: "\2b95"; }

.fa-right-from-bracket::before {
  content: "\f2f5"; }

.fa-sign-out-alt::before {
  content: "\f2f5"; }

.fa-right-from-line::before {
  content: "\f347"; }

.fa-arrow-alt-from-left::before {
  content: "\f347"; }

.fa-right-left::before {
  content: "\f362"; }

.fa-exchange-alt::before {
  content: "\f362"; }

.fa-right-long::before {
  content: "\f30b"; }

.fa-long-arrow-alt-right::before {
  content: "\f30b"; }

.fa-right-to-bracket::before {
  content: "\f2f6"; }

.fa-sign-in-alt::before {
  content: "\f2f6"; }

.fa-right-to-line::before {
  content: "\f34c"; }

.fa-arrow-alt-to-right::before {
  content: "\f34c"; }

.fa-ring::before {
  content: "\f70b"; }

.fa-rings-wedding::before {
  content: "\f81b"; }

.fa-road::before {
  content: "\1f6e3"; }

.fa-robot::before {
  content: "\1f916"; }

.fa-robot-astromech::before {
  content: "\e2d2"; }

.fa-rocket::before {
  content: "\f135"; }

.fa-rocket-launch::before {
  content: "\1f680"; }

.fa-roller-coaster::before {
  content: "\e324"; }

.fa-rotate::before {
  content: "\1f504"; }

.fa-sync-alt::before {
  content: "\1f504"; }

.fa-rotate-exclamation::before {
  content: "\e23c"; }

.fa-rotate-left::before {
  content: "\f2ea"; }

.fa-rotate-back::before {
  content: "\f2ea"; }

.fa-rotate-backward::before {
  content: "\f2ea"; }

.fa-undo-alt::before {
  content: "\f2ea"; }

.fa-rotate-right::before {
  content: "\f2f9"; }

.fa-redo-alt::before {
  content: "\f2f9"; }

.fa-rotate-forward::before {
  content: "\f2f9"; }

.fa-route::before {
  content: "\f4d7"; }

.fa-route-highway::before {
  content: "\f61a"; }

.fa-route-interstate::before {
  content: "\f61b"; }

.fa-router::before {
  content: "\f8da"; }

.fa-rss::before {
  content: "\f09e"; }

.fa-feed::before {
  content: "\f09e"; }

.fa-ruble-sign::before {
  content: "\20bd"; }

.fa-rouble::before {
  content: "\20bd"; }

.fa-rub::before {
  content: "\20bd"; }

.fa-ruble::before {
  content: "\20bd"; }

.fa-rugby-ball::before {
  content: "\e3c6"; }

.fa-ruler::before {
  content: "\1f4cf"; }

.fa-ruler-combined::before {
  content: "\f546"; }

.fa-ruler-horizontal::before {
  content: "\f547"; }

.fa-ruler-triangle::before {
  content: "\1f4d0"; }

.fa-ruler-vertical::before {
  content: "\f548"; }

.fa-rupee-sign::before {
  content: "\20a8"; }

.fa-rupee::before {
  content: "\20a8"; }

.fa-rupiah-sign::before {
  content: "\e23d"; }

.fa-rv::before {
  content: "\f7be"; }

.fa-s::before {
  content: "\53"; }

.fa-sack::before {
  content: "\f81c"; }

.fa-sack-dollar::before {
  content: "\1f4b0"; }

.fa-salad::before {
  content: "\1f957"; }

.fa-bowl-salad::before {
  content: "\1f957"; }

.fa-sandwich::before {
  content: "\1f96a"; }

.fa-satellite::before {
  content: "\1f6f0"; }

.fa-satellite-dish::before {
  content: "\1f4e1"; }

.fa-sausage::before {
  content: "\f820"; }

.fa-saxophone::before {
  content: "\1f3b7"; }

.fa-saxophone-fire::before {
  content: "\f8db"; }

.fa-sax-hot::before {
  content: "\f8db"; }

.fa-scale-balanced::before {
  content: "\2696"; }

.fa-balance-scale::before {
  content: "\2696"; }

.fa-scale-unbalanced::before {
  content: "\f515"; }

.fa-balance-scale-left::before {
  content: "\f515"; }

.fa-scale-unbalanced-flip::before {
  content: "\f516"; }

.fa-balance-scale-right::before {
  content: "\f516"; }

.fa-scalpel::before {
  content: "\f61d"; }

.fa-scalpel-line-dashed::before {
  content: "\f61e"; }

.fa-scalpel-path::before {
  content: "\f61e"; }

.fa-scanner::before {
  content: "\f8f3"; }

.fa-scanner-image::before {
  content: "\f8f3"; }

.fa-scanner-gun::before {
  content: "\f488"; }

.fa-scanner-keyboard::before {
  content: "\f489"; }

.fa-scanner-touchscreen::before {
  content: "\f48a"; }

.fa-scarecrow::before {
  content: "\f70d"; }

.fa-scarf::before {
  content: "\1f9e3"; }

.fa-school::before {
  content: "\1f3eb"; }

.fa-scissors::before {
  content: "\2700"; }

.fa-cut::before {
  content: "\2700"; }

.fa-screen-users::before {
  content: "\f63d"; }

.fa-users-class::before {
  content: "\f63d"; }

.fa-screencast::before {
  content: "\e23e"; }

.fa-screwdriver::before {
  content: "\1fa9b"; }

.fa-screwdriver-wrench::before {
  content: "\f7d9"; }

.fa-tools::before {
  content: "\f7d9"; }

.fa-scribble::before {
  content: "\e23f"; }

.fa-scroll::before {
  content: "\1f4dc"; }

.fa-scroll-old::before {
  content: "\f70f"; }

.fa-scroll-torah::before {
  content: "\f6a0"; }

.fa-torah::before {
  content: "\f6a0"; }

.fa-scrubber::before {
  content: "\f2f8"; }

.fa-scythe::before {
  content: "\f710"; }

.fa-sd-card::before {
  content: "\f7c2"; }

.fa-sd-cards::before {
  content: "\e240"; }

.fa-seal::before {
  content: "\e241"; }

.fa-seal-exclamation::before {
  content: "\e242"; }

.fa-seal-question::before {
  content: "\e243"; }

.fa-seat-airline::before {
  content: "\e244"; }

.fa-section::before {
  content: "\a7"; }

.fa-seedling::before {
  content: "\1f331"; }

.fa-sprout::before {
  content: "\1f331"; }

.fa-semicolon::before {
  content: "\3b"; }

.fa-send-back::before {
  content: "\f87e"; }

.fa-send-backward::before {
  content: "\f87f"; }

.fa-sensor::before {
  content: "\e028"; }

.fa-sensor-cloud::before {
  content: "\e02c"; }

.fa-sensor-smoke::before {
  content: "\e02c"; }

.fa-sensor-fire::before {
  content: "\e02a"; }

.fa-sensor-on::before {
  content: "\e02b"; }

.fa-sensor-triangle-exclamation::before {
  content: "\e029"; }

.fa-sensor-alert::before {
  content: "\e029"; }

.fa-server::before {
  content: "\f233"; }

.fa-shapes::before {
  content: "\f61f"; }

.fa-triangle-circle-square::before {
  content: "\f61f"; }

.fa-share::before {
  content: "\f064"; }

.fa-arrow-turn-right::before {
  content: "\f064"; }

.fa-mail-forward::before {
  content: "\f064"; }

.fa-share-all::before {
  content: "\f367"; }

.fa-arrows-turn-right::before {
  content: "\f367"; }

.fa-share-from-square::before {
  content: "\f14d"; }

.fa-share-square::before {
  content: "\f14d"; }

.fa-share-nodes::before {
  content: "\f1e0"; }

.fa-share-alt::before {
  content: "\f1e0"; }

.fa-sheep::before {
  content: "\1f411"; }

.fa-shekel-sign::before {
  content: "\20aa"; }

.fa-ils::before {
  content: "\20aa"; }

.fa-shekel::before {
  content: "\20aa"; }

.fa-sheqel::before {
  content: "\20aa"; }

.fa-sheqel-sign::before {
  content: "\20aa"; }

.fa-shelves::before {
  content: "\f480"; }

.fa-inventory::before {
  content: "\f480"; }

.fa-shelves-empty::before {
  content: "\e246"; }

.fa-shield::before {
  content: "\1f6e1"; }

.fa-shield-blank::before {
  content: "\f3ed"; }

.fa-shield-alt::before {
  content: "\f3ed"; }

.fa-shield-check::before {
  content: "\f2f7"; }

.fa-shield-cross::before {
  content: "\26e8"; }

.fa-shield-exclamation::before {
  content: "\e247"; }

.fa-shield-keyhole::before {
  content: "\e248"; }

.fa-shield-minus::before {
  content: "\e249"; }

.fa-shield-plus::before {
  content: "\e24a"; }

.fa-shield-slash::before {
  content: "\e24b"; }

.fa-shield-virus::before {
  content: "\e06c"; }

.fa-shield-xmark::before {
  content: "\e24c"; }

.fa-shield-times::before {
  content: "\e24c"; }

.fa-ship::before {
  content: "\1f6a2"; }

.fa-shirt::before {
  content: "\1f455"; }

.fa-t-shirt::before {
  content: "\1f455"; }

.fa-tshirt::before {
  content: "\1f455"; }

.fa-shirt-long-sleeve::before {
  content: "\e3c7"; }

.fa-shirt-running::before {
  content: "\e3c8"; }

.fa-shirt-tank-top::before {
  content: "\e3c9"; }

.fa-shish-kebab::before {
  content: "\f821"; }

.fa-shoe-prints::before {
  content: "\f54b"; }

.fa-shop::before {
  content: "\f54f"; }

.fa-store-alt::before {
  content: "\f54f"; }

.fa-shop-slash::before {
  content: "\e070"; }

.fa-store-alt-slash::before {
  content: "\e070"; }

.fa-shovel::before {
  content: "\f713"; }

.fa-shovel-snow::before {
  content: "\f7c3"; }

.fa-shower::before {
  content: "\1f6bf"; }

.fa-shower-down::before {
  content: "\e24d"; }

.fa-shower-alt::before {
  content: "\e24d"; }

.fa-shredder::before {
  content: "\f68a"; }

.fa-shuffle::before {
  content: "\1f500"; }

.fa-random::before {
  content: "\1f500"; }

.fa-shuttle-space::before {
  content: "\f197"; }

.fa-space-shuttle::before {
  content: "\f197"; }

.fa-shuttlecock::before {
  content: "\f45b"; }

.fa-sickle::before {
  content: "\f822"; }

.fa-sidebar::before {
  content: "\e24e"; }

.fa-sidebar-flip::before {
  content: "\e24f"; }

.fa-sigma::before {
  content: "\2211"; }

.fa-sign-hanging::before {
  content: "\f4d9"; }

.fa-sign::before {
  content: "\f4d9"; }

.fa-signal::before {
  content: "\1f4f6"; }

.fa-signal-5::before {
  content: "\1f4f6"; }

.fa-signal-perfect::before {
  content: "\1f4f6"; }

.fa-signal-bars::before {
  content: "\f690"; }

.fa-signal-alt::before {
  content: "\f690"; }

.fa-signal-alt-4::before {
  content: "\f690"; }

.fa-signal-bars-strong::before {
  content: "\f690"; }

.fa-signal-bars-fair::before {
  content: "\f692"; }

.fa-signal-alt-2::before {
  content: "\f692"; }

.fa-signal-bars-good::before {
  content: "\f693"; }

.fa-signal-alt-3::before {
  content: "\f693"; }

.fa-signal-bars-slash::before {
  content: "\f694"; }

.fa-signal-alt-slash::before {
  content: "\f694"; }

.fa-signal-bars-weak::before {
  content: "\f691"; }

.fa-signal-alt-1::before {
  content: "\f691"; }

.fa-signal-fair::before {
  content: "\f68d"; }

.fa-signal-2::before {
  content: "\f68d"; }

.fa-signal-good::before {
  content: "\f68e"; }

.fa-signal-3::before {
  content: "\f68e"; }

.fa-signal-slash::before {
  content: "\f695"; }

.fa-signal-stream::before {
  content: "\f8dd"; }

.fa-signal-stream-slash::before {
  content: "\e250"; }

.fa-signal-strong::before {
  content: "\f68f"; }

.fa-signal-4::before {
  content: "\f68f"; }

.fa-signal-weak::before {
  content: "\f68c"; }

.fa-signal-1::before {
  content: "\f68c"; }

.fa-signature::before {
  content: "\f5b7"; }

.fa-signature-lock::before {
  content: "\e3ca"; }

.fa-signature-slash::before {
  content: "\e3cb"; }

.fa-signs-post::before {
  content: "\f277"; }

.fa-map-signs::before {
  content: "\f277"; }

.fa-sim-card::before {
  content: "\f7c4"; }

.fa-sim-cards::before {
  content: "\e251"; }

.fa-sink::before {
  content: "\e06d"; }

.fa-siren::before {
  content: "\e02d"; }

.fa-siren-on::before {
  content: "\e02e"; }

.fa-sitemap::before {
  content: "\f0e8"; }

.fa-skeleton::before {
  content: "\f620"; }

.fa-ski-boot::before {
  content: "\e3cc"; }

.fa-ski-boot-ski::before {
  content: "\e3cd"; }

.fa-skull::before {
  content: "\1f480"; }

.fa-skull-cow::before {
  content: "\f8de"; }

.fa-skull-crossbones::before {
  content: "\2620"; }

.fa-slash::before {
  content: "\f715"; }

.fa-slash-back::before {
  content: "\5c"; }

.fa-slash-forward::before {
  content: "\2f"; }

.fa-sleigh::before {
  content: "\f7cc"; }

.fa-slider::before {
  content: "\e252"; }

.fa-sliders::before {
  content: "\f1de"; }

.fa-sliders-h::before {
  content: "\f1de"; }

.fa-sliders-simple::before {
  content: "\e253"; }

.fa-sliders-up::before {
  content: "\f3f1"; }

.fa-sliders-v::before {
  content: "\f3f1"; }

.fa-slot-machine::before {
  content: "\e3ce"; }

.fa-smog::before {
  content: "\f75f"; }

.fa-smoke::before {
  content: "\f760"; }

.fa-smoking::before {
  content: "\1f6ac"; }

.fa-snake::before {
  content: "\1f40d"; }

.fa-snooze::before {
  content: "\1f4a4"; }

.fa-zzz::before {
  content: "\1f4a4"; }

.fa-snow-blowing::before {
  content: "\f761"; }

.fa-snowflake::before {
  content: "\2744"; }

.fa-snowflakes::before {
  content: "\f7cf"; }

.fa-snowman::before {
  content: "\2603"; }

.fa-snowman-head::before {
  content: "\f79b"; }

.fa-frosty-head::before {
  content: "\f79b"; }

.fa-snowplow::before {
  content: "\f7d2"; }

.fa-soap::before {
  content: "\1f9fc"; }

.fa-socks::before {
  content: "\1f9e6"; }

.fa-solar-panel::before {
  content: "\f5ba"; }

.fa-solar-system::before {
  content: "\e02f"; }

.fa-sort::before {
  content: "\f0dc"; }

.fa-unsorted::before {
  content: "\f0dc"; }

.fa-sort-down::before {
  content: "\f0dd"; }

.fa-sort-desc::before {
  content: "\f0dd"; }

.fa-sort-up::before {
  content: "\f0de"; }

.fa-sort-asc::before {
  content: "\f0de"; }

.fa-spa::before {
  content: "\f5bb"; }

.fa-space-station-moon::before {
  content: "\e033"; }

.fa-space-station-moon-construction::before {
  content: "\e034"; }

.fa-space-station-moon-alt::before {
  content: "\e034"; }

.fa-spade::before {
  content: "\2660"; }

.fa-spaghetti-monster-flying::before {
  content: "\f67b"; }

.fa-pastafarianism::before {
  content: "\f67b"; }

.fa-sparkles::before {
  content: "\2728"; }

.fa-speaker::before {
  content: "\f8df"; }

.fa-speakers::before {
  content: "\f8e0"; }

.fa-spell-check::before {
  content: "\f891"; }

.fa-spider::before {
  content: "\1f577"; }

.fa-spider-black-widow::before {
  content: "\f718"; }

.fa-spider-web::before {
  content: "\1f578"; }

.fa-spinner::before {
  content: "\f110"; }

.fa-spinner-third::before {
  content: "\f3f4"; }

.fa-split::before {
  content: "\e254"; }

.fa-splotch::before {
  content: "\f5bc"; }

.fa-spoon::before {
  content: "\1f944"; }

.fa-utensil-spoon::before {
  content: "\1f944"; }

.fa-spray-can::before {
  content: "\f5bd"; }

.fa-spray-can-sparkles::before {
  content: "\f5d0"; }

.fa-air-freshener::before {
  content: "\f5d0"; }

.fa-sprinkler::before {
  content: "\e035"; }

.fa-square::before {
  content: "\25a0"; }

.fa-square-0::before {
  content: "\e255"; }

.fa-square-1::before {
  content: "\e256"; }

.fa-square-2::before {
  content: "\e257"; }

.fa-square-3::before {
  content: "\e258"; }

.fa-square-4::before {
  content: "\e259"; }

.fa-square-5::before {
  content: "\e25a"; }

.fa-square-6::before {
  content: "\e25b"; }

.fa-square-7::before {
  content: "\e25c"; }

.fa-square-8::before {
  content: "\e25d"; }

.fa-square-9::before {
  content: "\e25e"; }

.fa-square-a::before {
  content: "\e25f"; }

.fa-square-ampersand::before {
  content: "\e260"; }

.fa-square-arrow-down::before {
  content: "\f339"; }

.fa-arrow-square-down::before {
  content: "\f339"; }

.fa-square-arrow-down-left::before {
  content: "\e261"; }

.fa-square-arrow-down-right::before {
  content: "\e262"; }

.fa-square-arrow-left::before {
  content: "\f33a"; }

.fa-arrow-square-left::before {
  content: "\f33a"; }

.fa-square-arrow-right::before {
  content: "\f33b"; }

.fa-arrow-square-right::before {
  content: "\f33b"; }

.fa-square-arrow-up::before {
  content: "\f33c"; }

.fa-arrow-square-up::before {
  content: "\f33c"; }

.fa-square-arrow-up-left::before {
  content: "\e263"; }

.fa-square-arrow-up-right::before {
  content: "\f14c"; }

.fa-external-link-square::before {
  content: "\f14c"; }

.fa-square-b::before {
  content: "\e264"; }

.fa-square-bolt::before {
  content: "\e265"; }

.fa-square-c::before {
  content: "\e266"; }

.fa-square-caret-down::before {
  content: "\f150"; }

.fa-caret-square-down::before {
  content: "\f150"; }

.fa-square-caret-left::before {
  content: "\f191"; }

.fa-caret-square-left::before {
  content: "\f191"; }

.fa-square-caret-right::before {
  content: "\f152"; }

.fa-caret-square-right::before {
  content: "\f152"; }

.fa-square-caret-up::before {
  content: "\f151"; }

.fa-caret-square-up::before {
  content: "\f151"; }

.fa-square-check::before {
  content: "\2611"; }

.fa-check-square::before {
  content: "\2611"; }

.fa-square-chevron-down::before {
  content: "\f329"; }

.fa-chevron-square-down::before {
  content: "\f329"; }

.fa-square-chevron-left::before {
  content: "\f32a"; }

.fa-chevron-square-left::before {
  content: "\f32a"; }

.fa-square-chevron-right::before {
  content: "\f32b"; }

.fa-chevron-square-right::before {
  content: "\f32b"; }

.fa-square-chevron-up::before {
  content: "\f32c"; }

.fa-chevron-square-up::before {
  content: "\f32c"; }

.fa-square-code::before {
  content: "\e267"; }

.fa-square-d::before {
  content: "\e268"; }

.fa-square-dashed::before {
  content: "\e269"; }

.fa-square-divide::before {
  content: "\e26a"; }

.fa-square-dollar::before {
  content: "\f2e9"; }

.fa-dollar-square::before {
  content: "\f2e9"; }

.fa-usd-square::before {
  content: "\f2e9"; }

.fa-square-down::before {
  content: "\2b07"; }

.fa-arrow-alt-square-down::before {
  content: "\2b07"; }

.fa-square-down-left::before {
  content: "\e26b"; }

.fa-square-down-right::before {
  content: "\e26c"; }

.fa-square-e::before {
  content: "\e26d"; }

.fa-square-ellipsis::before {
  content: "\e26e"; }

.fa-square-ellipsis-vertical::before {
  content: "\e26f"; }

.fa-square-envelope::before {
  content: "\f199"; }

.fa-envelope-square::before {
  content: "\f199"; }

.fa-square-exclamation::before {
  content: "\f321"; }

.fa-exclamation-square::before {
  content: "\f321"; }

.fa-square-f::before {
  content: "\e270"; }

.fa-square-fragile::before {
  content: "\f49b"; }

.fa-box-fragile::before {
  content: "\f49b"; }

.fa-square-wine-glass-crack::before {
  content: "\f49b"; }

.fa-square-full::before {
  content: "\2b1b"; }

.fa-square-g::before {
  content: "\e271"; }

.fa-square-h::before {
  content: "\f0fd"; }

.fa-h-square::before {
  content: "\f0fd"; }

.fa-square-heart::before {
  content: "\1f49f"; }

.fa-heart-square::before {
  content: "\1f49f"; }

.fa-square-i::before {
  content: "\e272"; }

.fa-square-info::before {
  content: "\2139"; }

.fa-info-square::before {
  content: "\2139"; }

.fa-square-j::before {
  content: "\e273"; }

.fa-square-k::before {
  content: "\e274"; }

.fa-square-l::before {
  content: "\e275"; }

.fa-square-left::before {
  content: "\2b05"; }

.fa-arrow-alt-square-left::before {
  content: "\2b05"; }

.fa-square-m::before {
  content: "\e276"; }

.fa-square-minus::before {
  content: "\f146"; }

.fa-minus-square::before {
  content: "\f146"; }

.fa-square-n::before {
  content: "\e277"; }

.fa-square-o::before {
  content: "\e278"; }

.fa-square-p::before {
  content: "\e279"; }

.fa-square-parking::before {
  content: "\1f17f"; }

.fa-parking::before {
  content: "\1f17f"; }

.fa-square-parking-slash::before {
  content: "\f617"; }

.fa-parking-slash::before {
  content: "\f617"; }

.fa-square-pen::before {
  content: "\f14b"; }

.fa-pen-square::before {
  content: "\f14b"; }

.fa-pencil-square::before {
  content: "\f14b"; }

.fa-square-phone::before {
  content: "\f098"; }

.fa-phone-square::before {
  content: "\f098"; }

.fa-square-phone-flip::before {
  content: "\f87b"; }

.fa-phone-square-alt::before {
  content: "\f87b"; }

.fa-square-phone-hangup::before {
  content: "\e27a"; }

.fa-phone-square-down::before {
  content: "\e27a"; }

.fa-square-plus::before {
  content: "\f0fe"; }

.fa-plus-square::before {
  content: "\f0fe"; }

.fa-square-poll-horizontal::before {
  content: "\f682"; }

.fa-poll-h::before {
  content: "\f682"; }

.fa-square-poll-vertical::before {
  content: "\f681"; }

.fa-poll::before {
  content: "\f681"; }

.fa-square-q::before {
  content: "\e27b"; }

.fa-square-question::before {
  content: "\f2fd"; }

.fa-question-square::before {
  content: "\f2fd"; }

.fa-square-quote::before {
  content: "\e329"; }

.fa-square-r::before {
  content: "\e27c"; }

.fa-square-right::before {
  content: "\27a1"; }

.fa-arrow-alt-square-right::before {
  content: "\27a1"; }

.fa-square-root::before {
  content: "\221a"; }

.fa-square-root-variable::before {
  content: "\f698"; }

.fa-square-root-alt::before {
  content: "\f698"; }

.fa-square-rss::before {
  content: "\f143"; }

.fa-rss-square::before {
  content: "\f143"; }

.fa-square-s::before {
  content: "\e27d"; }

.fa-square-share-nodes::before {
  content: "\f1e1"; }

.fa-share-alt-square::before {
  content: "\f1e1"; }

.fa-square-sliders::before {
  content: "\f3f0"; }

.fa-sliders-h-square::before {
  content: "\f3f0"; }

.fa-square-sliders-vertical::before {
  content: "\f3f2"; }

.fa-sliders-v-square::before {
  content: "\f3f2"; }

.fa-square-small::before {
  content: "\e27e"; }

.fa-square-star::before {
  content: "\e27f"; }

.fa-square-t::before {
  content: "\e280"; }

.fa-square-terminal::before {
  content: "\e32a"; }

.fa-square-this-way-up::before {
  content: "\f49f"; }

.fa-box-up::before {
  content: "\f49f"; }

.fa-square-u::before {
  content: "\e281"; }

.fa-square-up::before {
  content: "\2b06"; }

.fa-arrow-alt-square-up::before {
  content: "\2b06"; }

.fa-square-up-left::before {
  content: "\e282"; }

.fa-square-up-right::before {
  content: "\2197"; }

.fa-external-link-square-alt::before {
  content: "\2197"; }

.fa-square-user::before {
  content: "\e283"; }

.fa-square-v::before {
  content: "\e284"; }

.fa-square-w::before {
  content: "\e285"; }

.fa-square-x::before {
  content: "\e286"; }

.fa-square-xmark::before {
  content: "\274e"; }

.fa-times-square::before {
  content: "\274e"; }

.fa-xmark-square::before {
  content: "\274e"; }

.fa-square-y::before {
  content: "\e287"; }

.fa-square-z::before {
  content: "\e288"; }

.fa-squirrel::before {
  content: "\f71a"; }

.fa-staff::before {
  content: "\f71b"; }

.fa-stairs::before {
  content: "\e289"; }

.fa-stamp::before {
  content: "\f5bf"; }

.fa-standard-definition::before {
  content: "\e28a"; }

.fa-rectangle-sd::before {
  content: "\e28a"; }

.fa-star::before {
  content: "\2b50"; }

.fa-star-and-crescent::before {
  content: "\262a"; }

.fa-star-christmas::before {
  content: "\f7d4"; }

.fa-star-exclamation::before {
  content: "\f2f3"; }

.fa-star-half::before {
  content: "\f089"; }

.fa-star-half-stroke::before {
  content: "\f5c0"; }

.fa-star-half-alt::before {
  content: "\f5c0"; }

.fa-star-of-david::before {
  content: "\2721"; }

.fa-star-of-life::before {
  content: "\f621"; }

.fa-star-sharp::before {
  content: "\e28b"; }

.fa-star-sharp-half::before {
  content: "\e28c"; }

.fa-star-sharp-half-stroke::before {
  content: "\e28d"; }

.fa-star-sharp-half-alt::before {
  content: "\e28d"; }

.fa-star-shooting::before {
  content: "\1f320"; }

.fa-starfighter::before {
  content: "\e037"; }

.fa-starfighter-twin-ion-engine::before {
  content: "\e038"; }

.fa-starfighter-alt::before {
  content: "\e038"; }

.fa-starfighter-twin-ion-engine-advanced::before {
  content: "\e28e"; }

.fa-starfighter-alt-advanced::before {
  content: "\e28e"; }

.fa-stars::before {
  content: "\f762"; }

.fa-starship::before {
  content: "\e039"; }

.fa-starship-freighter::before {
  content: "\e03a"; }

.fa-steak::before {
  content: "\1f969"; }

.fa-steering-wheel::before {
  content: "\f622"; }

.fa-sterling-sign::before {
  content: "\a3"; }

.fa-gbp::before {
  content: "\a3"; }

.fa-pound-sign::before {
  content: "\a3"; }

.fa-stethoscope::before {
  content: "\1fa7a"; }

.fa-stocking::before {
  content: "\f7d5"; }

.fa-stomach::before {
  content: "\f623"; }

.fa-stop::before {
  content: "\23f9"; }

.fa-stopwatch::before {
  content: "\23f1"; }

.fa-stopwatch-20::before {
  content: "\e06f"; }

.fa-store::before {
  content: "\f54e"; }

.fa-store-slash::before {
  content: "\e071"; }

.fa-strawberry::before {
  content: "\e32b"; }

.fa-street-view::before {
  content: "\f21d"; }

.fa-stretcher::before {
  content: "\f825"; }

.fa-strikethrough::before {
  content: "\f0cc"; }

.fa-stroopwafel::before {
  content: "\f551"; }

.fa-subscript::before {
  content: "\f12c"; }

.fa-suitcase::before {
  content: "\1f9f3"; }

.fa-suitcase-medical::before {
  content: "\f0fa"; }

.fa-medkit::before {
  content: "\f0fa"; }

.fa-suitcase-rolling::before {
  content: "\f5c1"; }

.fa-sun::before {
  content: "\2600"; }

.fa-sun-bright::before {
  content: "\e28f"; }

.fa-sun-alt::before {
  content: "\e28f"; }

.fa-sun-cloud::before {
  content: "\1f324"; }

.fa-sun-dust::before {
  content: "\f764"; }

.fa-sun-haze::before {
  content: "\f765"; }

.fa-sunglasses::before {
  content: "\1f576"; }

.fa-sunrise::before {
  content: "\1f305"; }

.fa-sunset::before {
  content: "\1f307"; }

.fa-superscript::before {
  content: "\f12b"; }

.fa-swatchbook::before {
  content: "\f5c3"; }

.fa-sword::before {
  content: "\1f5e1"; }

.fa-sword-laser::before {
  content: "\e03b"; }

.fa-sword-laser-alt::before {
  content: "\e03c"; }

.fa-swords::before {
  content: "\2694"; }

.fa-swords-laser::before {
  content: "\e03d"; }

.fa-symbols::before {
  content: "\1f523"; }

.fa-icons-alt::before {
  content: "\1f523"; }

.fa-synagogue::before {
  content: "\1f54d"; }

.fa-syringe::before {
  content: "\1f489"; }

.fa-t::before {
  content: "\54"; }

.fa-table::before {
  content: "\f0ce"; }

.fa-table-cells::before {
  content: "\f00a"; }

.fa-th::before {
  content: "\f00a"; }

.fa-table-cells-large::before {
  content: "\f009"; }

.fa-th-large::before {
  content: "\f009"; }

.fa-table-columns::before {
  content: "\f0db"; }

.fa-columns::before {
  content: "\f0db"; }

.fa-table-layout::before {
  content: "\e290"; }

.fa-table-list::before {
  content: "\f00b"; }

.fa-th-list::before {
  content: "\f00b"; }

.fa-table-picnic::before {
  content: "\e32d"; }

.fa-table-pivot::before {
  content: "\e291"; }

.fa-table-rows::before {
  content: "\e292"; }

.fa-rows::before {
  content: "\e292"; }

.fa-table-tennis-paddle-ball::before {
  content: "\1f3d3"; }

.fa-ping-pong-paddle-ball::before {
  content: "\1f3d3"; }

.fa-table-tennis::before {
  content: "\1f3d3"; }

.fa-table-tree::before {
  content: "\e293"; }

.fa-tablet::before {
  content: "\f3fb"; }

.fa-tablet-android::before {
  content: "\f3fb"; }

.fa-tablet-button::before {
  content: "\f10a"; }

.fa-tablet-rugged::before {
  content: "\f48f"; }

.fa-tablet-screen::before {
  content: "\f3fc"; }

.fa-tablet-android-alt::before {
  content: "\f3fc"; }

.fa-tablet-screen-button::before {
  content: "\f3fa"; }

.fa-tablet-alt::before {
  content: "\f3fa"; }

.fa-tablets::before {
  content: "\f490"; }

.fa-tachograph-digital::before {
  content: "\f566"; }

.fa-digital-tachograph::before {
  content: "\f566"; }

.fa-taco::before {
  content: "\1f32e"; }

.fa-tag::before {
  content: "\1f3f7"; }

.fa-tags::before {
  content: "\f02c"; }

.fa-tally::before {
  content: "\f69c"; }

.fa-tally-5::before {
  content: "\f69c"; }

.fa-tally-1::before {
  content: "\e294"; }

.fa-tally-2::before {
  content: "\e295"; }

.fa-tally-3::before {
  content: "\e296"; }

.fa-tally-4::before {
  content: "\e297"; }

.fa-tape::before {
  content: "\f4db"; }

.fa-taxi::before {
  content: "\1f696"; }

.fa-cab::before {
  content: "\1f696"; }

.fa-taxi-bus::before {
  content: "\e298"; }

.fa-teddy-bear::before {
  content: "\e3cf"; }

.fa-teeth::before {
  content: "\f62e"; }

.fa-teeth-open::before {
  content: "\f62f"; }

.fa-telescope::before {
  content: "\1f52d"; }

.fa-temperature-arrow-down::before {
  content: "\e03f"; }

.fa-temperature-down::before {
  content: "\e03f"; }

.fa-temperature-arrow-up::before {
  content: "\e040"; }

.fa-temperature-up::before {
  content: "\e040"; }

.fa-temperature-empty::before {
  content: "\f2cb"; }

.fa-temperature-0::before {
  content: "\f2cb"; }

.fa-thermometer-0::before {
  content: "\f2cb"; }

.fa-thermometer-empty::before {
  content: "\f2cb"; }

.fa-temperature-full::before {
  content: "\f2c7"; }

.fa-temperature-4::before {
  content: "\f2c7"; }

.fa-thermometer-4::before {
  content: "\f2c7"; }

.fa-thermometer-full::before {
  content: "\f2c7"; }

.fa-temperature-half::before {
  content: "\1f321"; }

.fa-temperature-2::before {
  content: "\1f321"; }

.fa-thermometer-2::before {
  content: "\1f321"; }

.fa-thermometer-half::before {
  content: "\1f321"; }

.fa-temperature-high::before {
  content: "\f769"; }

.fa-temperature-list::before {
  content: "\e299"; }

.fa-temperature-low::before {
  content: "\f76b"; }

.fa-temperature-quarter::before {
  content: "\f2ca"; }

.fa-temperature-1::before {
  content: "\f2ca"; }

.fa-thermometer-1::before {
  content: "\f2ca"; }

.fa-thermometer-quarter::before {
  content: "\f2ca"; }

.fa-temperature-snow::before {
  content: "\f768"; }

.fa-temperature-frigid::before {
  content: "\f768"; }

.fa-temperature-sun::before {
  content: "\f76a"; }

.fa-temperature-hot::before {
  content: "\f76a"; }

.fa-temperature-three-quarters::before {
  content: "\f2c8"; }

.fa-temperature-3::before {
  content: "\f2c8"; }

.fa-thermometer-3::before {
  content: "\f2c8"; }

.fa-thermometer-three-quarters::before {
  content: "\f2c8"; }

.fa-tenge-sign::before {
  content: "\20b8"; }

.fa-tenge::before {
  content: "\20b8"; }

.fa-tennis-ball::before {
  content: "\1f3be"; }

.fa-terminal::before {
  content: "\f120"; }

.fa-text::before {
  content: "\f893"; }

.fa-text-height::before {
  content: "\f034"; }

.fa-text-size::before {
  content: "\f894"; }

.fa-text-slash::before {
  content: "\f87d"; }

.fa-remove-format::before {
  content: "\f87d"; }

.fa-text-width::before {
  content: "\f035"; }

.fa-thermometer::before {
  content: "\f491"; }

.fa-theta::before {
  content: "\f69e"; }

.fa-thought-bubble::before {
  content: "\e32e"; }

.fa-thumbs-down::before {
  content: "\1f44e"; }

.fa-thumbs-up::before {
  content: "\1f44d"; }

.fa-thumbtack::before {
  content: "\1f4cc"; }

.fa-thumb-tack::before {
  content: "\1f4cc"; }

.fa-tick::before {
  content: "\e32f"; }

.fa-ticket::before {
  content: "\1f39f"; }

.fa-ticket-airline::before {
  content: "\e29a"; }

.fa-ticket-simple::before {
  content: "\f3ff"; }

.fa-ticket-alt::before {
  content: "\f3ff"; }

.fa-tickets-airline::before {
  content: "\e29b"; }

.fa-tilde::before {
  content: "\7e"; }

.fa-timeline::before {
  content: "\e29c"; }

.fa-timeline-arrow::before {
  content: "\e29d"; }

.fa-timer::before {
  content: "\e29e"; }

.fa-tire::before {
  content: "\f631"; }

.fa-tire-flat::before {
  content: "\f632"; }

.fa-tire-pressure-warning::before {
  content: "\f633"; }

.fa-tire-rugged::before {
  content: "\f634"; }

.fa-toggle-off::before {
  content: "\f204"; }

.fa-toggle-on::before {
  content: "\f205"; }

.fa-toilet::before {
  content: "\1f6bd"; }

.fa-toilet-paper::before {
  content: "\1f9fb"; }

.fa-toilet-paper-blank::before {
  content: "\f71f"; }

.fa-toilet-paper-alt::before {
  content: "\f71f"; }

.fa-toilet-paper-blank-under::before {
  content: "\e29f"; }

.fa-toilet-paper-reverse-alt::before {
  content: "\e29f"; }

.fa-toilet-paper-slash::before {
  content: "\e072"; }

.fa-toilet-paper-under::before {
  content: "\e2a0"; }

.fa-toilet-paper-reverse::before {
  content: "\e2a0"; }

.fa-toilet-paper-under-slash::before {
  content: "\e2a1"; }

.fa-toilet-paper-reverse-slash::before {
  content: "\e2a1"; }

.fa-tomato::before {
  content: "\e330"; }

.fa-tombstone::before {
  content: "\f720"; }

.fa-tombstone-blank::before {
  content: "\1faa6"; }

.fa-tombstone-alt::before {
  content: "\1faa6"; }

.fa-toolbox::before {
  content: "\1f9f0"; }

.fa-tooth::before {
  content: "\1f9b7"; }

.fa-toothbrush::before {
  content: "\1faa5"; }

.fa-torii-gate::before {
  content: "\26e9"; }

.fa-tornado::before {
  content: "\1f32a"; }

.fa-tower-broadcast::before {
  content: "\f519"; }

.fa-broadcast-tower::before {
  content: "\f519"; }

.fa-tower-control::before {
  content: "\e2a2"; }

.fa-tractor::before {
  content: "\1f69c"; }

.fa-trademark::before {
  content: "\2122"; }

.fa-traffic-cone::before {
  content: "\f636"; }

.fa-traffic-light::before {
  content: "\1f6a6"; }

.fa-traffic-light-go::before {
  content: "\f638"; }

.fa-traffic-light-slow::before {
  content: "\f639"; }

.fa-traffic-light-stop::before {
  content: "\f63a"; }

.fa-trailer::before {
  content: "\e041"; }

.fa-train::before {
  content: "\1f686"; }

.fa-train-subway::before {
  content: "\f239"; }

.fa-subway::before {
  content: "\f239"; }

.fa-train-subway-tunnel::before {
  content: "\e2a3"; }

.fa-subway-tunnel::before {
  content: "\e2a3"; }

.fa-train-tram::before {
  content: "\1f68a"; }

.fa-tram::before {
  content: "\1f68a"; }

.fa-transformer-bolt::before {
  content: "\e2a4"; }

.fa-transgender::before {
  content: "\26a7"; }

.fa-transgender-alt::before {
  content: "\26a7"; }

.fa-transporter::before {
  content: "\e042"; }

.fa-transporter-1::before {
  content: "\e043"; }

.fa-transporter-2::before {
  content: "\e044"; }

.fa-transporter-3::before {
  content: "\e045"; }

.fa-transporter-4::before {
  content: "\e2a5"; }

.fa-transporter-5::before {
  content: "\e2a6"; }

.fa-transporter-6::before {
  content: "\e2a7"; }

.fa-transporter-7::before {
  content: "\e2a8"; }

.fa-transporter-empty::before {
  content: "\e046"; }

.fa-trash::before {
  content: "\f1f8"; }

.fa-trash-arrow-up::before {
  content: "\f829"; }

.fa-trash-restore::before {
  content: "\f829"; }

.fa-trash-can::before {
  content: "\f2ed"; }

.fa-trash-alt::before {
  content: "\f2ed"; }

.fa-trash-can-arrow-up::before {
  content: "\f82a"; }

.fa-trash-restore-alt::before {
  content: "\f82a"; }

.fa-trash-can-check::before {
  content: "\e2a9"; }

.fa-trash-can-clock::before {
  content: "\e2aa"; }

.fa-trash-can-list::before {
  content: "\e2ab"; }

.fa-trash-can-plus::before {
  content: "\e2ac"; }

.fa-trash-can-slash::before {
  content: "\e2ad"; }

.fa-trash-alt-slash::before {
  content: "\e2ad"; }

.fa-trash-can-undo::before {
  content: "\f896"; }

.fa-trash-can-arrow-turn-left::before {
  content: "\f896"; }

.fa-trash-undo-alt::before {
  content: "\f896"; }

.fa-trash-can-xmark::before {
  content: "\e2ae"; }

.fa-trash-check::before {
  content: "\e2af"; }

.fa-trash-clock::before {
  content: "\e2b0"; }

.fa-trash-list::before {
  content: "\e2b1"; }

.fa-trash-plus::before {
  content: "\e2b2"; }

.fa-trash-slash::before {
  content: "\e2b3"; }

.fa-trash-undo::before {
  content: "\f895"; }

.fa-trash-arrow-turn-left::before {
  content: "\f895"; }

.fa-trash-xmark::before {
  content: "\e2b4"; }

.fa-treasure-chest::before {
  content: "\f723"; }

.fa-tree::before {
  content: "\1f332"; }

.fa-tree-christmas::before {
  content: "\1f384"; }

.fa-tree-deciduous::before {
  content: "\1f333"; }

.fa-tree-alt::before {
  content: "\1f333"; }

.fa-tree-decorated::before {
  content: "\f7dc"; }

.fa-tree-large::before {
  content: "\f7dd"; }

.fa-tree-palm::before {
  content: "\1f334"; }

.fa-trees::before {
  content: "\f724"; }

.fa-triangle::before {
  content: "\25b2"; }

.fa-triangle-exclamation::before {
  content: "\26a0"; }

.fa-exclamation-triangle::before {
  content: "\26a0"; }

.fa-warning::before {
  content: "\26a0"; }

.fa-triangle-instrument::before {
  content: "\f8e2"; }

.fa-triangle-music::before {
  content: "\f8e2"; }

.fa-triangle-person-digging::before {
  content: "\f85d"; }

.fa-construction::before {
  content: "\f85d"; }

.fa-trophy::before {
  content: "\1f3c6"; }

.fa-trophy-star::before {
  content: "\f2eb"; }

.fa-trophy-alt::before {
  content: "\f2eb"; }

.fa-truck::before {
  content: "\26df"; }

.fa-truck-bolt::before {
  content: "\e3d0"; }

.fa-truck-clock::before {
  content: "\f48c"; }

.fa-shipping-timed::before {
  content: "\f48c"; }

.fa-truck-container::before {
  content: "\f4dc"; }

.fa-truck-container-empty::before {
  content: "\e2b5"; }

.fa-truck-fast::before {
  content: "\f48b"; }

.fa-shipping-fast::before {
  content: "\f48b"; }

.fa-truck-flatbed::before {
  content: "\e2b6"; }

.fa-truck-front::before {
  content: "\e2b7"; }

.fa-truck-medical::before {
  content: "\1f691"; }

.fa-ambulance::before {
  content: "\1f691"; }

.fa-truck-monster::before {
  content: "\f63b"; }

.fa-truck-moving::before {
  content: "\f4df"; }

.fa-truck-pickup::before {
  content: "\1f6fb"; }

.fa-truck-plow::before {
  content: "\f7de"; }

.fa-truck-ramp::before {
  content: "\f4e0"; }

.fa-truck-ramp-box::before {
  content: "\f4de"; }

.fa-truck-loading::before {
  content: "\f4de"; }

.fa-truck-ramp-couch::before {
  content: "\f4dd"; }

.fa-truck-couch::before {
  content: "\f4dd"; }

.fa-truck-tow::before {
  content: "\e2b8"; }

.fa-trumpet::before {
  content: "\1f3ba"; }

.fa-tty::before {
  content: "\f1e4"; }

.fa-teletype::before {
  content: "\f1e4"; }

.fa-tty-answer::before {
  content: "\e2b9"; }

.fa-teletype-answer::before {
  content: "\e2b9"; }

.fa-tugrik-sign::before {
  content: "\e2ba"; }

.fa-turkey::before {
  content: "\f725"; }

.fa-turkish-lira-sign::before {
  content: "\e2bb"; }

.fa-try::before {
  content: "\e2bb"; }

.fa-turkish-lira::before {
  content: "\e2bb"; }

.fa-turn-down::before {
  content: "\2935"; }

.fa-level-down-alt::before {
  content: "\2935"; }

.fa-turn-down-left::before {
  content: "\e331"; }

.fa-turn-up::before {
  content: "\2934"; }

.fa-level-up-alt::before {
  content: "\2934"; }

.fa-turntable::before {
  content: "\f8e4"; }

.fa-turtle::before {
  content: "\1f422"; }

.fa-tv::before {
  content: "\f26c"; }

.fa-television::before {
  content: "\f26c"; }

.fa-tv-alt::before {
  content: "\f26c"; }

.fa-tv-music::before {
  content: "\f8e6"; }

.fa-tv-retro::before {
  content: "\1f4fa"; }

.fa-typewriter::before {
  content: "\f8e7"; }

.fa-u::before {
  content: "\55"; }

.fa-ufo::before {
  content: "\1f6f8"; }

.fa-ufo-beam::before {
  content: "\e048"; }

.fa-umbrella::before {
  content: "\f0e9"; }

.fa-umbrella-beach::before {
  content: "\1f3d6"; }

.fa-umbrella-simple::before {
  content: "\e2bc"; }

.fa-umbrella-alt::before {
  content: "\e2bc"; }

.fa-underline::before {
  content: "\f0cd"; }

.fa-unicorn::before {
  content: "\1f984"; }

.fa-uniform-martial-arts::before {
  content: "\e3d1"; }

.fa-union::before {
  content: "\22c3"; }

.fa-universal-access::before {
  content: "\f29a"; }

.fa-unlock::before {
  content: "\1f513"; }

.fa-unlock-keyhole::before {
  content: "\f13e"; }

.fa-unlock-alt::before {
  content: "\f13e"; }

.fa-up::before {
  content: "\f357"; }

.fa-arrow-alt-up::before {
  content: "\f357"; }

.fa-up-down::before {
  content: "\2195"; }

.fa-arrows-alt-v::before {
  content: "\2195"; }

.fa-up-down-left-right::before {
  content: "\f0b2"; }

.fa-arrows-alt::before {
  content: "\f0b2"; }

.fa-up-from-line::before {
  content: "\f346"; }

.fa-arrow-alt-from-bottom::before {
  content: "\f346"; }

.fa-up-left::before {
  content: "\e2bd"; }

.fa-up-long::before {
  content: "\f30c"; }

.fa-long-arrow-alt-up::before {
  content: "\f30c"; }

.fa-up-right::before {
  content: "\e2be"; }

.fa-up-right-and-down-left-from-center::before {
  content: "\f424"; }

.fa-expand-alt::before {
  content: "\f424"; }

.fa-up-right-from-square::before {
  content: "\f35d"; }

.fa-external-link-alt::before {
  content: "\f35d"; }

.fa-up-to-line::before {
  content: "\f34d"; }

.fa-arrow-alt-to-top::before {
  content: "\f34d"; }

.fa-upload::before {
  content: "\f093"; }

.fa-usb-drive::before {
  content: "\f8e9"; }

.fa-user::before {
  content: "\1f464"; }

.fa-user-alien::before {
  content: "\e04a"; }

.fa-user-astronaut::before {
  content: "\f4fb"; }

.fa-user-bounty-hunter::before {
  content: "\e2bf"; }

.fa-user-check::before {
  content: "\f4fc"; }

.fa-user-chef::before {
  content: "\e3d2"; }

.fa-user-clock::before {
  content: "\f4fd"; }

.fa-user-cowboy::before {
  content: "\f8ea"; }

.fa-user-crown::before {
  content: "\f6a4"; }

.fa-user-doctor::before {
  content: "\f0f0"; }

.fa-user-md::before {
  content: "\f0f0"; }

.fa-user-doctor-message::before {
  content: "\f82e"; }

.fa-user-md-chat::before {
  content: "\f82e"; }

.fa-user-gear::before {
  content: "\f4fe"; }

.fa-user-cog::before {
  content: "\f4fe"; }

.fa-user-graduate::before {
  content: "\f501"; }

.fa-user-group::before {
  content: "\1f465"; }

.fa-user-friends::before {
  content: "\1f465"; }

.fa-user-group-crown::before {
  content: "\f6a5"; }

.fa-users-crown::before {
  content: "\f6a5"; }

.fa-user-hair-buns::before {
  content: "\e3d3"; }

.fa-user-headset::before {
  content: "\f82d"; }

.fa-user-helmet-safety::before {
  content: "\1f477"; }

.fa-user-construction::before {
  content: "\1f477"; }

.fa-user-hard-hat::before {
  content: "\1f477"; }

.fa-user-injured::before {
  content: "\f728"; }

.fa-user-large::before {
  content: "\f406"; }

.fa-user-alt::before {
  content: "\f406"; }

.fa-user-large-slash::before {
  content: "\f4fa"; }

.fa-user-alt-slash::before {
  content: "\f4fa"; }

.fa-user-lock::before {
  content: "\f502"; }

.fa-user-minus::before {
  content: "\f503"; }

.fa-user-music::before {
  content: "\f8eb"; }

.fa-user-ninja::before {
  content: "\1f977"; }

.fa-user-nurse::before {
  content: "\f82f"; }

.fa-user-pen::before {
  content: "\f4ff"; }

.fa-user-edit::before {
  content: "\f4ff"; }

.fa-user-pilot::before {
  content: "\e2c0"; }

.fa-user-pilot-tie::before {
  content: "\e2c1"; }

.fa-user-plus::before {
  content: "\f234"; }

.fa-user-police::before {
  content: "\e333"; }

.fa-user-police-tie::before {
  content: "\e334"; }

.fa-user-robot::before {
  content: "\e04b"; }

.fa-user-secret::before {
  content: "\1f575"; }

.fa-user-shakespeare::before {
  content: "\e2c2"; }

.fa-user-shield::before {
  content: "\f505"; }

.fa-user-slash::before {
  content: "\f506"; }

.fa-user-tag::before {
  content: "\f507"; }

.fa-user-tie::before {
  content: "\f508"; }

.fa-user-unlock::before {
  content: "\e058"; }

.fa-user-visor::before {
  content: "\e04c"; }

.fa-user-xmark::before {
  content: "\f235"; }

.fa-user-times::before {
  content: "\f235"; }

.fa-users::before {
  content: "\f0c0"; }

.fa-group::before {
  content: "\f0c0"; }

.fa-users-gear::before {
  content: "\f509"; }

.fa-users-cog::before {
  content: "\f509"; }

.fa-users-medical::before {
  content: "\f830"; }

.fa-users-slash::before {
  content: "\e073"; }

.fa-utensils::before {
  content: "\1f374"; }

.fa-cutlery::before {
  content: "\1f374"; }

.fa-utility-pole::before {
  content: "\e2c3"; }

.fa-utility-pole-double::before {
  content: "\e2c4"; }

.fa-v::before {
  content: "\56"; }

.fa-vacuum::before {
  content: "\e04d"; }

.fa-vacuum-robot::before {
  content: "\e04e"; }

.fa-value-absolute::before {
  content: "\f6a6"; }

.fa-van-shuttle::before {
  content: "\1f690"; }

.fa-shuttle-van::before {
  content: "\1f690"; }

.fa-vault::before {
  content: "\e2c5"; }

.fa-vector-circle::before {
  content: "\e2c6"; }

.fa-vector-polygon::before {
  content: "\e2c7"; }

.fa-vector-square::before {
  content: "\f5cb"; }

.fa-venus::before {
  content: "\2640"; }

.fa-venus-double::before {
  content: "\26a2"; }

.fa-venus-mars::before {
  content: "\26a4"; }

.fa-vest::before {
  content: "\e085"; }

.fa-vest-patches::before {
  content: "\e086"; }

.fa-vial::before {
  content: "\1f9ea"; }

.fa-vials::before {
  content: "\f493"; }

.fa-video::before {
  content: "\f03d"; }

.fa-video-camera::before {
  content: "\f03d"; }

.fa-video-arrow-down-left::before {
  content: "\e2c8"; }

.fa-video-arrow-up-right::before {
  content: "\e2c9"; }

.fa-video-plus::before {
  content: "\f4e1"; }

.fa-video-slash::before {
  content: "\f4e2"; }

.fa-vihara::before {
  content: "\f6a7"; }

.fa-violin::before {
  content: "\1f3bb"; }

.fa-virus::before {
  content: "\e074"; }

.fa-virus-slash::before {
  content: "\e075"; }

.fa-viruses::before {
  content: "\e076"; }

.fa-voicemail::before {
  content: "\f897"; }

.fa-volcano::before {
  content: "\1f30b"; }

.fa-volleyball::before {
  content: "\1f3d0"; }

.fa-volleyball-ball::before {
  content: "\1f3d0"; }

.fa-volume::before {
  content: "\1f509"; }

.fa-volume-medium::before {
  content: "\1f509"; }

.fa-volume-high::before {
  content: "\1f50a"; }

.fa-volume-up::before {
  content: "\1f50a"; }

.fa-volume-low::before {
  content: "\1f508"; }

.fa-volume-down::before {
  content: "\1f508"; }

.fa-volume-off::before {
  content: "\f026"; }

.fa-volume-slash::before {
  content: "\1f507"; }

.fa-volume-xmark::before {
  content: "\f6a9"; }

.fa-volume-mute::before {
  content: "\f6a9"; }

.fa-volume-times::before {
  content: "\f6a9"; }

.fa-vr-cardboard::before {
  content: "\f729"; }

.fa-w::before {
  content: "\57"; }

.fa-wagon-covered::before {
  content: "\f8ee"; }

.fa-walker::before {
  content: "\f831"; }

.fa-walkie-talkie::before {
  content: "\f8ef"; }

.fa-wallet::before {
  content: "\f555"; }

.fa-wand::before {
  content: "\f72a"; }

.fa-wand-magic::before {
  content: "\f0d0"; }

.fa-magic::before {
  content: "\f0d0"; }

.fa-wand-magic-sparkles::before {
  content: "\e2ca"; }

.fa-magic-wand-sparkles::before {
  content: "\e2ca"; }

.fa-wand-sparkles::before {
  content: "\f72b"; }

.fa-warehouse::before {
  content: "\f494"; }

.fa-warehouse-full::before {
  content: "\f495"; }

.fa-warehouse-alt::before {
  content: "\f495"; }

.fa-washing-machine::before {
  content: "\f898"; }

.fa-washer::before {
  content: "\f898"; }

.fa-watch::before {
  content: "\231a"; }

.fa-watch-apple::before {
  content: "\e2cb"; }

.fa-watch-calculator::before {
  content: "\f8f0"; }

.fa-watch-fitness::before {
  content: "\f63e"; }

.fa-watch-smart::before {
  content: "\e2cc"; }

.fa-water::before {
  content: "\f773"; }

.fa-water-arrow-down::before {
  content: "\f774"; }

.fa-water-lower::before {
  content: "\f774"; }

.fa-water-arrow-up::before {
  content: "\f775"; }

.fa-water-rise::before {
  content: "\f775"; }

.fa-water-ladder::before {
  content: "\f5c5"; }

.fa-ladder-water::before {
  content: "\f5c5"; }

.fa-swimming-pool::before {
  content: "\f5c5"; }

.fa-watermelon-slice::before {
  content: "\e337"; }

.fa-wave-pulse::before {
  content: "\f5f8"; }

.fa-heart-rate::before {
  content: "\f5f8"; }

.fa-wave-sine::before {
  content: "\223f"; }

.fa-wave-square::before {
  content: "\f83e"; }

.fa-wave-triangle::before {
  content: "\f89a"; }

.fa-waveform::before {
  content: "\f8f1"; }

.fa-waveform-lines::before {
  content: "\f8f2"; }

.fa-weight-hanging::before {
  content: "\f5cd"; }

.fa-weight-scale::before {
  content: "\f496"; }

.fa-weight::before {
  content: "\f496"; }

.fa-whale::before {
  content: "\1f40b"; }

.fa-wheat::before {
  content: "\f72d"; }

.fa-wheat-awn::before {
  content: "\e2cd"; }

.fa-wheat-alt::before {
  content: "\e2cd"; }

.fa-wheat-awn-slash::before {
  content: "\e338"; }

.fa-wheat-slash::before {
  content: "\e339"; }

.fa-wheelchair::before {
  content: "\f193"; }

.fa-wheelchair-move::before {
  content: "\e2ce"; }

.fa-wheelchair-alt::before {
  content: "\e2ce"; }

.fa-whiskey-glass::before {
  content: "\1f943"; }

.fa-glass-whiskey::before {
  content: "\1f943"; }

.fa-whiskey-glass-ice::before {
  content: "\f7a1"; }

.fa-glass-whiskey-rocks::before {
  content: "\f7a1"; }

.fa-whistle::before {
  content: "\f460"; }

.fa-wifi::before {
  content: "\f1eb"; }

.fa-wifi-3::before {
  content: "\f1eb"; }

.fa-wifi-strong::before {
  content: "\f1eb"; }

.fa-wifi-exclamation::before {
  content: "\e2cf"; }

.fa-wifi-fair::before {
  content: "\f6ab"; }

.fa-wifi-2::before {
  content: "\f6ab"; }

.fa-wifi-slash::before {
  content: "\f6ac"; }

.fa-wifi-weak::before {
  content: "\f6aa"; }

.fa-wifi-1::before {
  content: "\f6aa"; }

.fa-wind::before {
  content: "\f72e"; }

.fa-wind-turbine::before {
  content: "\f89b"; }

.fa-wind-warning::before {
  content: "\f776"; }

.fa-wind-circle-exclamation::before {
  content: "\f776"; }

.fa-window::before {
  content: "\f40e"; }

.fa-window-flip::before {
  content: "\f40f"; }

.fa-window-alt::before {
  content: "\f40f"; }

.fa-window-frame::before {
  content: "\1fa9f"; }

.fa-window-frame-open::before {
  content: "\e050"; }

.fa-window-maximize::before {
  content: "\1f5d6"; }

.fa-window-minimize::before {
  content: "\1f5d5"; }

.fa-window-restore::before {
  content: "\f2d2"; }

.fa-windsock::before {
  content: "\f777"; }

.fa-wine-bottle::before {
  content: "\f72f"; }

.fa-wine-glass::before {
  content: "\1f377"; }

.fa-wine-glass-crack::before {
  content: "\f4bb"; }

.fa-fragile::before {
  content: "\f4bb"; }

.fa-wine-glass-empty::before {
  content: "\f5ce"; }

.fa-wine-glass-alt::before {
  content: "\f5ce"; }

.fa-won-sign::before {
  content: "\20a9"; }

.fa-krw::before {
  content: "\20a9"; }

.fa-won::before {
  content: "\20a9"; }

.fa-wreath::before {
  content: "\f7e2"; }

.fa-wrench::before {
  content: "\1f527"; }

.fa-wrench-simple::before {
  content: "\e2d1"; }

.fa-x::before {
  content: "\58"; }

.fa-x-ray::before {
  content: "\f497"; }

.fa-xmark::before {
  content: "\d7"; }

.fa-close::before {
  content: "\d7"; }

.fa-multiply::before {
  content: "\d7"; }

.fa-remove::before {
  content: "\d7"; }

.fa-times::before {
  content: "\d7"; }

.fa-xmark-to-slot::before {
  content: "\f771"; }

.fa-times-to-slot::before {
  content: "\f771"; }

.fa-vote-nay::before {
  content: "\f771"; }

.fa-y::before {
  content: "\59"; }

.fa-yen-sign::before {
  content: "\a5"; }

.fa-cny::before {
  content: "\a5"; }

.fa-jpy::before {
  content: "\a5"; }

.fa-rmb::before {
  content: "\a5"; }

.fa-yen::before {
  content: "\a5"; }

.fa-yin-yang::before {
  content: "\262f"; }

.fa-z::before {
  content: "\5a"; }

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

/*!
 * Font Awesome Pro 6.0.0-beta1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/styles/fonts/fa-brands-400.woff2") format("woff2"), url("/styles/fonts/fa-brands-400.woff") format("woff"), url("/styles/fonts/fa-brands-400.ttf") format("truetype"); }

.fab,
.fa-brands {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa-500px:before {
  content: "\f26e"; }

.fa-accessible-icon:before {
  content: "\f368"; }

.fa-accusoft:before {
  content: "\f369"; }

.fa-acquisitions-incorporated:before {
  content: "\f6af"; }

.fa-adn:before {
  content: "\f170"; }

.fa-adversal:before {
  content: "\f36a"; }

.fa-affiliatetheme:before {
  content: "\f36b"; }

.fa-airbnb:before {
  content: "\f834"; }

.fa-algolia:before {
  content: "\f36c"; }

.fa-alipay:before {
  content: "\f642"; }

.fa-amazon:before {
  content: "\f270"; }

.fa-amazon-pay:before {
  content: "\f42c"; }

.fa-amilia:before {
  content: "\f36d"; }

.fa-android:before {
  content: "\f17b"; }

.fa-angellist:before {
  content: "\f209"; }

.fa-angrycreative:before {
  content: "\f36e"; }

.fa-angular:before {
  content: "\f420"; }

.fa-app-store:before {
  content: "\f36f"; }

.fa-app-store-ios:before {
  content: "\f370"; }

.fa-apper:before {
  content: "\f371"; }

.fa-apple:before {
  content: "\f179"; }

.fa-apple-pay:before {
  content: "\f415"; }

.fa-artstation:before {
  content: "\f77a"; }

.fa-asymmetrik:before {
  content: "\f372"; }

.fa-atlassian:before {
  content: "\f77b"; }

.fa-audible:before {
  content: "\f373"; }

.fa-autoprefixer:before {
  content: "\f41c"; }

.fa-avianex:before {
  content: "\f374"; }

.fa-aviato:before {
  content: "\f421"; }

.fa-aws:before {
  content: "\f375"; }

.fa-bandcamp:before {
  content: "\f2d5"; }

.fa-battle-net:before {
  content: "\f835"; }

.fa-behance:before {
  content: "\f1b4"; }

.fa-behance-square:before {
  content: "\f1b5"; }

.fa-bimobject:before {
  content: "\f378"; }

.fa-bitbucket:before {
  content: "\f171"; }

.fa-bitcoin:before {
  content: "\f379"; }

.fa-bity:before {
  content: "\f37a"; }

.fa-black-tie:before {
  content: "\f27e"; }

.fa-blackberry:before {
  content: "\f37b"; }

.fa-blogger:before {
  content: "\f37c"; }

.fa-blogger-b:before {
  content: "\f37d"; }

.fa-bluetooth:before {
  content: "\f293"; }

.fa-bluetooth-b:before {
  content: "\f294"; }

.fa-bootstrap:before {
  content: "\f836"; }

.fa-bots:before {
  content: "\e340"; }

.fa-btc:before {
  content: "\f15a"; }

.fa-buffer:before {
  content: "\f837"; }

.fa-buromobelexperte:before {
  content: "\f37f"; }

.fa-buy-n-large:before {
  content: "\f8a6"; }

.fa-buysellads:before {
  content: "\f20d"; }

.fa-canadian-maple-leaf:before {
  content: "\f785"; }

.fa-cc-amazon-pay:before {
  content: "\f42d"; }

.fa-cc-amex:before {
  content: "\f1f3"; }

.fa-cc-apple-pay:before {
  content: "\f416"; }

.fa-cc-diners-club:before {
  content: "\f24c"; }

.fa-cc-discover:before {
  content: "\f1f2"; }

.fa-cc-jcb:before {
  content: "\f24b"; }

.fa-cc-mastercard:before {
  content: "\f1f1"; }

.fa-cc-paypal:before {
  content: "\f1f4"; }

.fa-cc-stripe:before {
  content: "\f1f5"; }

.fa-cc-visa:before {
  content: "\f1f0"; }

.fa-centercode:before {
  content: "\f380"; }

.fa-centos:before {
  content: "\f789"; }

.fa-chrome:before {
  content: "\f268"; }

.fa-chromecast:before {
  content: "\f838"; }

.fa-cloudflare:before {
  content: "\e07d"; }

.fa-cloudscale:before {
  content: "\f383"; }

.fa-cloudsmith:before {
  content: "\f384"; }

.fa-cloudversify:before {
  content: "\f385"; }

.fa-cmplid:before {
  content: "\e360"; }

.fa-codepen:before {
  content: "\f1cb"; }

.fa-codiepie:before {
  content: "\f284"; }

.fa-confluence:before {
  content: "\f78d"; }

.fa-connectdevelop:before {
  content: "\f20e"; }

.fa-contao:before {
  content: "\f26d"; }

.fa-cotton-bureau:before {
  content: "\f89e"; }

.fa-cpanel:before {
  content: "\f388"; }

.fa-creative-commons:before {
  content: "\f25e"; }

.fa-creative-commons-by:before {
  content: "\f4e7"; }

.fa-creative-commons-nc:before {
  content: "\f4e8"; }

.fa-creative-commons-nc-eu:before {
  content: "\f4e9"; }

.fa-creative-commons-nc-jp:before {
  content: "\f4ea"; }

.fa-creative-commons-nd:before {
  content: "\f4eb"; }

.fa-creative-commons-pd:before {
  content: "\f4ec"; }

.fa-creative-commons-pd-alt:before {
  content: "\f4ed"; }

.fa-creative-commons-remix:before {
  content: "\f4ee"; }

.fa-creative-commons-sa:before {
  content: "\f4ef"; }

.fa-creative-commons-sampling:before {
  content: "\f4f0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1"; }

.fa-creative-commons-share:before {
  content: "\f4f2"; }

.fa-creative-commons-zero:before {
  content: "\f4f3"; }

.fa-critical-role:before {
  content: "\f6c9"; }

.fa-css3:before {
  content: "\f13c"; }

.fa-css3-alt:before {
  content: "\f38b"; }

.fa-cuttlefish:before {
  content: "\f38c"; }

.fa-d-and-d:before {
  content: "\f38d"; }

.fa-d-and-d-beyond:before {
  content: "\f6ca"; }

.fa-dailymotion:before {
  content: "\e052"; }

.fa-dashcube:before {
  content: "\f210"; }

.fa-deezer:before {
  content: "\e077"; }

.fa-delicious:before {
  content: "\f1a5"; }

.fa-deploydog:before {
  content: "\f38e"; }

.fa-deskpro:before {
  content: "\f38f"; }

.fa-dev:before {
  content: "\f6cc"; }

.fa-deviantart:before {
  content: "\f1bd"; }

.fa-dhl:before {
  content: "\f790"; }

.fa-diaspora:before {
  content: "\f791"; }

.fa-digg:before {
  content: "\f1a6"; }

.fa-digital-ocean:before {
  content: "\f391"; }

.fa-discord:before {
  content: "\f392"; }

.fa-discourse:before {
  content: "\f393"; }

.fa-dochub:before {
  content: "\f394"; }

.fa-docker:before {
  content: "\f395"; }

.fa-draft2digital:before {
  content: "\f396"; }

.fa-dribbble:before {
  content: "\f17d"; }

.fa-dribbble-square:before {
  content: "\f397"; }

.fa-dropbox:before {
  content: "\f16b"; }

.fa-drupal:before {
  content: "\f1a9"; }

.fa-dyalog:before {
  content: "\f399"; }

.fa-earlybirds:before {
  content: "\f39a"; }

.fa-ebay:before {
  content: "\f4f4"; }

.fa-edge:before {
  content: "\f282"; }

.fa-edge-legacy:before {
  content: "\e078"; }

.fa-elementor:before {
  content: "\f430"; }

.fa-ello:before {
  content: "\f5f1"; }

.fa-ember:before {
  content: "\f423"; }

.fa-empire:before {
  content: "\f1d1"; }

.fa-envira:before {
  content: "\f299"; }

.fa-erlang:before {
  content: "\f39d"; }

.fa-ethereum:before {
  content: "\f42e"; }

.fa-etsy:before {
  content: "\f2d7"; }

.fa-evernote:before {
  content: "\f839"; }

.fa-expeditedssl:before {
  content: "\f23e"; }

.fa-facebook:before {
  content: "\f09a"; }

.fa-facebook-f:before {
  content: "\f39e"; }

.fa-facebook-messenger:before {
  content: "\f39f"; }

.fa-facebook-square:before {
  content: "\f082"; }

.fa-fantasy-flight-games:before {
  content: "\f6dc"; }

.fa-fedex:before {
  content: "\f797"; }

.fa-fedora:before {
  content: "\f798"; }

.fa-figma:before {
  content: "\f799"; }

.fa-firefox:before {
  content: "\f269"; }

.fa-firefox-browser:before {
  content: "\e007"; }

.fa-first-order:before {
  content: "\f2b0"; }

.fa-first-order-alt:before {
  content: "\f50a"; }

.fa-firstdraft:before {
  content: "\f3a1"; }

.fa-flickr:before {
  content: "\f16e"; }

.fa-flipboard:before {
  content: "\f44d"; }

.fa-fly:before {
  content: "\f417"; }

.fa-font-awesome:before {
  content: "\f2b4"; }

.fa-font-awesome-flag:before {
  content: "\f2b4"; }

.fa-font-awesome-logo-full:before {
  content: "\f2b4"; }

.fa-fonticons:before {
  content: "\f280"; }

.fa-fonticons-fi:before {
  content: "\f3a2"; }

.fa-fort-awesome:before {
  content: "\f286"; }

.fa-fort-awesome-alt:before {
  content: "\f3a3"; }

.fa-forumbee:before {
  content: "\f211"; }

.fa-foursquare:before {
  content: "\f180"; }

.fa-free-code-camp:before {
  content: "\f2c5"; }

.fa-freebsd:before {
  content: "\f3a4"; }

.fa-fulcrum:before {
  content: "\f50b"; }

.fa-galactic-republic:before {
  content: "\f50c"; }

.fa-galactic-senate:before {
  content: "\f50d"; }

.fa-get-pocket:before {
  content: "\f265"; }

.fa-gg:before {
  content: "\f260"; }

.fa-gg-circle:before {
  content: "\f261"; }

.fa-git:before {
  content: "\f1d3"; }

.fa-git-alt:before {
  content: "\f841"; }

.fa-git-square:before {
  content: "\f1d2"; }

.fa-github:before {
  content: "\f09b"; }

.fa-github-alt:before {
  content: "\f113"; }

.fa-github-square:before {
  content: "\f092"; }

.fa-gitkraken:before {
  content: "\f3a6"; }

.fa-gitlab:before {
  content: "\f296"; }

.fa-gitter:before {
  content: "\f426"; }

.fa-glide:before {
  content: "\f2a5"; }

.fa-glide-g:before {
  content: "\f2a6"; }

.fa-gofore:before {
  content: "\f3a7"; }

.fa-goodreads:before {
  content: "\f3a8"; }

.fa-goodreads-g:before {
  content: "\f3a9"; }

.fa-google:before {
  content: "\f1a0"; }

.fa-google-drive:before {
  content: "\f3aa"; }

.fa-google-pay:before {
  content: "\e079"; }

.fa-google-play:before {
  content: "\f3ab"; }

.fa-google-plus:before {
  content: "\f2b3"; }

.fa-google-plus-g:before {
  content: "\f0d5"; }

.fa-google-plus-square:before {
  content: "\f0d4"; }

.fa-google-wallet:before {
  content: "\f1ee"; }

.fa-gratipay:before {
  content: "\f184"; }

.fa-grav:before {
  content: "\f2d6"; }

.fa-gripfire:before {
  content: "\f3ac"; }

.fa-grunt:before {
  content: "\f3ad"; }

.fa-guilded:before {
  content: "\e07e"; }

.fa-gulp:before {
  content: "\f3ae"; }

.fa-hacker-news:before {
  content: "\f1d4"; }

.fa-hacker-news-square:before {
  content: "\f3af"; }

.fa-hackerrank:before {
  content: "\f5f7"; }

.fa-hips:before {
  content: "\f452"; }

.fa-hire-a-helper:before {
  content: "\f3b0"; }

.fa-hive:before {
  content: "\e07f"; }

.fa-hooli:before {
  content: "\f427"; }

.fa-hornbill:before {
  content: "\f592"; }

.fa-hotjar:before {
  content: "\f3b1"; }

.fa-houzz:before {
  content: "\f27c"; }

.fa-html5:before {
  content: "\f13b"; }

.fa-hubspot:before {
  content: "\f3b2"; }

.fa-ideal:before {
  content: "\e013"; }

.fa-imdb:before {
  content: "\f2d8"; }

.fa-innosoft:before {
  content: "\e080"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-instagram-square:before {
  content: "\e055"; }

.fa-instalod:before {
  content: "\e081"; }

.fa-intercom:before {
  content: "\f7af"; }

.fa-internet-explorer:before {
  content: "\f26b"; }

.fa-invision:before {
  content: "\f7b0"; }

.fa-ioxhost:before {
  content: "\f208"; }

.fa-itch-io:before {
  content: "\f83a"; }

.fa-itunes:before {
  content: "\f3b4"; }

.fa-itunes-note:before {
  content: "\f3b5"; }

.fa-java:before {
  content: "\f4e4"; }

.fa-jedi-order:before {
  content: "\f50e"; }

.fa-jenkins:before {
  content: "\f3b6"; }

.fa-jira:before {
  content: "\f7b1"; }

.fa-joget:before {
  content: "\f3b7"; }

.fa-joomla:before {
  content: "\f1aa"; }

.fa-js:before {
  content: "\f3b8"; }

.fa-js-square:before {
  content: "\f3b9"; }

.fa-jsfiddle:before {
  content: "\f1cc"; }

.fa-kaggle:before {
  content: "\f5fa"; }

.fa-keybase:before {
  content: "\f4f5"; }

.fa-keycdn:before {
  content: "\f3ba"; }

.fa-kickstarter:before {
  content: "\f3bb"; }

.fa-kickstarter-k:before {
  content: "\f3bc"; }

.fa-korvue:before {
  content: "\f42f"; }

.fa-laravel:before {
  content: "\f3bd"; }

.fa-lastfm:before {
  content: "\f202"; }

.fa-lastfm-square:before {
  content: "\f203"; }

.fa-leanpub:before {
  content: "\f212"; }

.fa-less:before {
  content: "\f41d"; }

.fa-line:before {
  content: "\f3c0"; }

.fa-linkedin:before {
  content: "\f08c"; }

.fa-linkedin-in:before {
  content: "\f0e1"; }

.fa-linode:before {
  content: "\f2b8"; }

.fa-linux:before {
  content: "\f17c"; }

.fa-lyft:before {
  content: "\f3c3"; }

.fa-magento:before {
  content: "\f3c4"; }

.fa-mailchimp:before {
  content: "\f59e"; }

.fa-mandalorian:before {
  content: "\f50f"; }

.fa-markdown:before {
  content: "\f60f"; }

.fa-mastodon:before {
  content: "\f4f6"; }

.fa-maxcdn:before {
  content: "\f136"; }

.fa-mdb:before {
  content: "\f8ca"; }

.fa-medapps:before {
  content: "\f3c6"; }

.fa-medium:before {
  content: "\f23a"; }

.fa-medium-m:before {
  content: "\f23a"; }

.fa-medrt:before {
  content: "\f3c8"; }

.fa-meetup:before {
  content: "\f2e0"; }

.fa-megaport:before {
  content: "\f5a3"; }

.fa-mendeley:before {
  content: "\f7b3"; }

.fa-microblog:before {
  content: "\e01a"; }

.fa-microsoft:before {
  content: "\f3ca"; }

.fa-mix:before {
  content: "\f3cb"; }

.fa-mixcloud:before {
  content: "\f289"; }

.fa-mixer:before {
  content: "\e056"; }

.fa-mizuni:before {
  content: "\f3cc"; }

.fa-modx:before {
  content: "\f285"; }

.fa-monero:before {
  content: "\f3d0"; }

.fa-napster:before {
  content: "\f3d2"; }

.fa-neos:before {
  content: "\f612"; }

.fa-nimblr:before {
  content: "\f5a8"; }

.fa-node:before {
  content: "\f419"; }

.fa-node-js:before {
  content: "\f3d3"; }

.fa-npm:before {
  content: "\f3d4"; }

.fa-ns8:before {
  content: "\f3d5"; }

.fa-nutritionix:before {
  content: "\f3d6"; }

.fa-octopus-deploy:before {
  content: "\e082"; }

.fa-odnoklassniki:before {
  content: "\f263"; }

.fa-odnoklassniki-square:before {
  content: "\f264"; }

.fa-old-republic:before {
  content: "\f510"; }

.fa-opencart:before {
  content: "\f23d"; }

.fa-openid:before {
  content: "\f19b"; }

.fa-opera:before {
  content: "\f26a"; }

.fa-optin-monster:before {
  content: "\f23c"; }

.fa-orcid:before {
  content: "\f8d2"; }

.fa-osi:before {
  content: "\f41a"; }

.fa-page4:before {
  content: "\f3d7"; }

.fa-pagelines:before {
  content: "\f18c"; }

.fa-palfed:before {
  content: "\f3d8"; }

.fa-patreon:before {
  content: "\f3d9"; }

.fa-paypal:before {
  content: "\f1ed"; }

.fa-penny-arcade:before {
  content: "\f704"; }

.fa-perbyte:before {
  content: "\e083"; }

.fa-periscope:before {
  content: "\f3da"; }

.fa-phabricator:before {
  content: "\f3db"; }

.fa-phoenix-framework:before {
  content: "\f3dc"; }

.fa-phoenix-squadron:before {
  content: "\f511"; }

.fa-php:before {
  content: "\f457"; }

.fa-pied-piper:before {
  content: "\f2ae"; }

.fa-pied-piper-alt:before {
  content: "\f1a8"; }

.fa-pied-piper-hat:before {
  content: "\f4e5"; }

.fa-pied-piper-pp:before {
  content: "\f1a7"; }

.fa-pied-piper-square:before {
  content: "\e01e"; }

.fa-pinterest:before {
  content: "\f0d2"; }

.fa-pinterest-p:before {
  content: "\f231"; }

.fa-pinterest-square:before {
  content: "\f0d3"; }

.fa-playstation:before {
  content: "\f3df"; }

.fa-product-hunt:before {
  content: "\f288"; }

.fa-pushed:before {
  content: "\f3e1"; }

.fa-python:before {
  content: "\f3e2"; }

.fa-qq:before {
  content: "\f1d6"; }

.fa-quinscape:before {
  content: "\f459"; }

.fa-quora:before {
  content: "\f2c4"; }

.fa-r-project:before {
  content: "\f4f7"; }

.fa-raspberry-pi:before {
  content: "\f7bb"; }

.fa-ravelry:before {
  content: "\f2d9"; }

.fa-react:before {
  content: "\f41b"; }

.fa-reacteurope:before {
  content: "\f75d"; }

.fa-readme:before {
  content: "\f4d5"; }

.fa-rebel:before {
  content: "\f1d0"; }

.fa-red-river:before {
  content: "\f3e3"; }

.fa-reddit:before {
  content: "\f1a1"; }

.fa-reddit-alien:before {
  content: "\f281"; }

.fa-reddit-square:before {
  content: "\f1a2"; }

.fa-redhat:before {
  content: "\f7bc"; }

.fa-renren:before {
  content: "\f18b"; }

.fa-replyd:before {
  content: "\f3e6"; }

.fa-researchgate:before {
  content: "\f4f8"; }

.fa-resolving:before {
  content: "\f3e7"; }

.fa-rev:before {
  content: "\f5b2"; }

.fa-rocketchat:before {
  content: "\f3e8"; }

.fa-rockrms:before {
  content: "\f3e9"; }

.fa-rust:before {
  content: "\e07a"; }

.fa-safari:before {
  content: "\f267"; }

.fa-salesforce:before {
  content: "\f83b"; }

.fa-sass:before {
  content: "\f41e"; }

.fa-schlix:before {
  content: "\f3ea"; }

.fa-scribd:before {
  content: "\f28a"; }

.fa-searchengin:before {
  content: "\f3eb"; }

.fa-sellcast:before {
  content: "\f2da"; }

.fa-sellsy:before {
  content: "\f213"; }

.fa-servicestack:before {
  content: "\f3ec"; }

.fa-shirtsinbulk:before {
  content: "\f214"; }

.fa-shopify:before {
  content: "\e057"; }

.fa-shopware:before {
  content: "\f5b5"; }

.fa-simplybuilt:before {
  content: "\f215"; }

.fa-sistrix:before {
  content: "\f3ee"; }

.fa-sith:before {
  content: "\f512"; }

.fa-sketch:before {
  content: "\f7c6"; }

.fa-skyatlas:before {
  content: "\f216"; }

.fa-skype:before {
  content: "\f17e"; }

.fa-slack:before {
  content: "\f198"; }

.fa-slack-hash:before {
  content: "\f198"; }

.fa-slideshare:before {
  content: "\f1e7"; }

.fa-snapchat:before {
  content: "\f2ab"; }

.fa-snapchat-ghost:before {
  content: "\f2ab"; }

.fa-snapchat-square:before {
  content: "\f2ad"; }

.fa-soundcloud:before {
  content: "\f1be"; }

.fa-sourcetree:before {
  content: "\f7d3"; }

.fa-speakap:before {
  content: "\f3f3"; }

.fa-speaker-deck:before {
  content: "\f83c"; }

.fa-spotify:before {
  content: "\f1bc"; }

.fa-square-font-awesome:before {
  content: "\f425"; }

.fa-square-font-awesome-stroke:before {
  content: "\f35c"; }

.fa-font-awesome-alt:before {
  content: "\f35c"; }

.fa-squarespace:before {
  content: "\f5be"; }

.fa-stack-exchange:before {
  content: "\f18d"; }

.fa-stack-overflow:before {
  content: "\f16c"; }

.fa-stackpath:before {
  content: "\f842"; }

.fa-staylinked:before {
  content: "\f3f5"; }

.fa-steam:before {
  content: "\f1b6"; }

.fa-steam-square:before {
  content: "\f1b7"; }

.fa-steam-symbol:before {
  content: "\f3f6"; }

.fa-sticker-mule:before {
  content: "\f3f7"; }

.fa-strava:before {
  content: "\f428"; }

.fa-stripe:before {
  content: "\f429"; }

.fa-stripe-s:before {
  content: "\f42a"; }

.fa-studiovinari:before {
  content: "\f3f8"; }

.fa-stumbleupon:before {
  content: "\f1a4"; }

.fa-stumbleupon-circle:before {
  content: "\f1a3"; }

.fa-superpowers:before {
  content: "\f2dd"; }

.fa-supple:before {
  content: "\f3f9"; }

.fa-suse:before {
  content: "\f7d6"; }

.fa-swift:before {
  content: "\f8e1"; }

.fa-symfony:before {
  content: "\f83d"; }

.fa-teamspeak:before {
  content: "\f4f9"; }

.fa-telegram:before {
  content: "\f2c6"; }

.fa-telegram-plane:before {
  content: "\f2c6"; }

.fa-tencent-weibo:before {
  content: "\f1d5"; }

.fa-the-red-yeti:before {
  content: "\f69d"; }

.fa-themeco:before {
  content: "\f5c6"; }

.fa-themeisle:before {
  content: "\f2b2"; }

.fa-think-peaks:before {
  content: "\f731"; }

.fa-tiktok:before {
  content: "\e07b"; }

.fa-trade-federation:before {
  content: "\f513"; }

.fa-trello:before {
  content: "\f181"; }

.fa-tripadvisor:before {
  content: "\f262"; }

.fa-tumblr:before {
  content: "\f173"; }

.fa-tumblr-square:before {
  content: "\f174"; }

.fa-twitch:before {
  content: "\f1e8"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-twitter-square:before {
  content: "\f081"; }

.fa-typo3:before {
  content: "\f42b"; }

.fa-uber:before {
  content: "\f402"; }

.fa-ubuntu:before {
  content: "\f7df"; }

.fa-uikit:before {
  content: "\f403"; }

.fa-umbraco:before {
  content: "\f8e8"; }

.fa-uncharted:before {
  content: "\e084"; }

.fa-uniregistry:before {
  content: "\f404"; }

.fa-unity:before {
  content: "\e049"; }

.fa-unsplash:before {
  content: "\e07c"; }

.fa-untappd:before {
  content: "\f405"; }

.fa-ups:before {
  content: "\f7e0"; }

.fa-usb:before {
  content: "\f287"; }

.fa-usps:before {
  content: "\f7e1"; }

.fa-ussunnah:before {
  content: "\f407"; }

.fa-vaadin:before {
  content: "\f408"; }

.fa-viacoin:before {
  content: "\f237"; }

.fa-viadeo:before {
  content: "\f2a9"; }

.fa-viadeo-square:before {
  content: "\f2aa"; }

.fa-viber:before {
  content: "\f409"; }

.fa-vimeo:before {
  content: "\f40a"; }

.fa-vimeo-square:before {
  content: "\f194"; }

.fa-vimeo-v:before {
  content: "\f27d"; }

.fa-vine:before {
  content: "\f1ca"; }

.fa-vk:before {
  content: "\f189"; }

.fa-vnv:before {
  content: "\f40b"; }

.fa-vuejs:before {
  content: "\f41f"; }

.fa-watchman-monitoring:before {
  content: "\e087"; }

.fa-waze:before {
  content: "\f83f"; }

.fa-weebly:before {
  content: "\f5cc"; }

.fa-weibo:before {
  content: "\f18a"; }

.fa-weixin:before {
  content: "\f1d7"; }

.fa-whatsapp:before {
  content: "\f232"; }

.fa-whatsapp-square:before {
  content: "\f40c"; }

.fa-whmcs:before {
  content: "\f40d"; }

.fa-wikipedia-w:before {
  content: "\f266"; }

.fa-windows:before {
  content: "\f17a"; }

.fa-wirsindhanderk:before {
  content: "\e2d0"; }

.fa-wsh:before {
  content: "\e2d0"; }

.fa-wix:before {
  content: "\f5cf"; }

.fa-wizards-of-the-coast:before {
  content: "\f730"; }

.fa-wodu:before {
  content: "\e088"; }

.fa-wolf-pack-battalion:before {
  content: "\f514"; }

.fa-wordpress:before {
  content: "\f19a"; }

.fa-wordpress-simple:before {
  content: "\f411"; }

.fa-wpbeginner:before {
  content: "\f297"; }

.fa-wpexplorer:before {
  content: "\f2de"; }

.fa-wpforms:before {
  content: "\f298"; }

.fa-wpressr:before {
  content: "\f3e4"; }

.fa-xbox:before {
  content: "\f412"; }

.fa-xing:before {
  content: "\f168"; }

.fa-xing-square:before {
  content: "\f169"; }

.fa-y-combinator:before {
  content: "\f23b"; }

.fa-yahoo:before {
  content: "\f19e"; }

.fa-yammer:before {
  content: "\f840"; }

.fa-yandex:before {
  content: "\f413"; }

.fa-yandex-international:before {
  content: "\f414"; }

.fa-yarn:before {
  content: "\f7e3"; }

.fa-yelp:before {
  content: "\f1e9"; }

.fa-yoast:before {
  content: "\f2b1"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-youtube-square:before {
  content: "\f431"; }

.fa-zhihu:before {
  content: "\f63f"; }

/*!
 * Font Awesome Pro 6.0.0-beta1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("/styles/fonts/fa-light-300.woff2") format("woff2"), url("/styles/fonts/fa-light-300.woff") format("woff"), url("/styles/fonts/fa-light-300.ttf") format("truetype"); }

.fal,
.fa-light {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 300; }

/*!
 * Font Awesome Pro 6.0.0-beta1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/styles/fonts/fa-regular-400.woff2") format("woff2"), url("/styles/fonts/fa-regular-400.woff") format("woff"), url("/styles/fonts/fa-regular-400.ttf") format("truetype"); }

.far,
.fa-regular {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

/*!
 * Font Awesome Pro 6.0.0-beta1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("/styles/fonts/fa-solid-900.woff2") format("woff2"), url("/styles/fonts/fa-solid-900.woff") format("woff"), url("/styles/fonts/fa-solid-900.ttf") format("truetype"); }

.fas,
.fa-solid {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900; }

/*!
 * Font Awesome Pro 6.0.0-beta1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("/styles/fonts/fa-thin-100.woff2") format("woff2"), url("/styles/fonts/fa-thin-100.woff") format("woff"), url("/styles/fonts/fa-thin-100.ttf") format("truetype"); }

.fat,
.fa-thin {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 100; }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0s; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0s; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0s; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0s; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0s; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0s; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0s; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0s; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0s; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0s; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0s; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0s; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0s; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0s; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0s; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0s; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0s; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0s; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0s; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0s; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0s; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0s; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0s; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0s; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0s; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0s; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0s; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0s; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0s; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0s; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0s; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0s; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0s; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0s; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0s; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0s; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0s; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0s; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0s; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0s; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0s; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0s; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0s; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0s; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0s; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0s; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0s; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0s; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0s; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0s; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0s; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0s; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0s; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0s; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0s; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0s; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0s; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0s; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0s; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0s; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

[data-aos] {
  pointer-events: none; }
  [data-aos].aos-animate {
    pointer-events: auto; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

@media screen {
  html:not(.no-js) {
    /**
    * Fade animations:
    * fade
    * fade-up, fade-down, fade-left, fade-right
    * fade-up-right, fade-up-left, fade-down-right, fade-down-left
    */
    /**
    * Zoom animations:
    * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
    * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
    */
    /**
    * Slide animations
    */
    /**
    * Flip animations:
    * flip-left, flip-right, flip-up, flip-down
    */ }
    html:not(.no-js) [data-aos^='fade'][data-aos^='fade'] {
      opacity: 0;
      transition-property: opacity, transform; }
      html:not(.no-js) [data-aos^='fade'][data-aos^='fade'].aos-animate {
        opacity: 1;
        transform: none; }
    html:not(.no-js) [data-aos='fade-up'] {
      transform: translate3d(0, 100px, 0); }
    html:not(.no-js) [data-aos='fade-down'] {
      transform: translate3d(0, -100px, 0); }
    html:not(.no-js) [data-aos='fade-right'] {
      transform: translate3d(-100px, 0, 0); }
    html:not(.no-js) [data-aos='fade-left'] {
      transform: translate3d(100px, 0, 0); }
    html:not(.no-js) [data-aos='fade-up-right'] {
      transform: translate3d(-100px, 100px, 0); }
    html:not(.no-js) [data-aos='fade-up-left'] {
      transform: translate3d(100px, 100px, 0); }
    html:not(.no-js) [data-aos='fade-down-right'] {
      transform: translate3d(-100px, -100px, 0); }
    html:not(.no-js) [data-aos='fade-down-left'] {
      transform: translate3d(100px, -100px, 0); }
    html:not(.no-js) [data-aos^='zoom'][data-aos^='zoom'] {
      opacity: 0;
      transition-property: opacity, transform; }
      html:not(.no-js) [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1); }
    html:not(.no-js) [data-aos='zoom-in'] {
      transform: scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-up'] {
      transform: translate3d(0, 100px, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-down'] {
      transform: translate3d(0, -100px, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-right'] {
      transform: translate3d(-100px, 0, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-left'] {
      transform: translate3d(100px, 0, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-out'] {
      transform: scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-up'] {
      transform: translate3d(0, 100px, 0) scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-down'] {
      transform: translate3d(0, -100px, 0) scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-right'] {
      transform: translate3d(-100px, 0, 0) scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-left'] {
      transform: translate3d(100px, 0, 0) scale(1.2); }
    html:not(.no-js) [data-aos^='slide'][data-aos^='slide'] {
      transition-property: transform;
      visibility: hidden; }
      html:not(.no-js) [data-aos^='slide'][data-aos^='slide'].aos-animate {
        visibility: visible;
        transform: translate3d(0, 0, 0); }
    html:not(.no-js) [data-aos='slide-up'] {
      transform: translate3d(0, 100%, 0); }
    html:not(.no-js) [data-aos='slide-down'] {
      transform: translate3d(0, -100%, 0); }
    html:not(.no-js) [data-aos='slide-right'] {
      transform: translate3d(-100%, 0, 0); }
    html:not(.no-js) [data-aos='slide-left'] {
      transform: translate3d(100%, 0, 0); }
    html:not(.no-js) [data-aos^='flip'][data-aos^='flip'] {
      backface-visibility: hidden;
      transition-property: transform; }
    html:not(.no-js) [data-aos='flip-left'] {
      transform: perspective(2500px) rotateY(-100deg); }
      html:not(.no-js) [data-aos='flip-left'].aos-animate {
        transform: perspective(2500px) rotateY(0); }
    html:not(.no-js) [data-aos='flip-right'] {
      transform: perspective(2500px) rotateY(100deg); }
      html:not(.no-js) [data-aos='flip-right'].aos-animate {
        transform: perspective(2500px) rotateY(0); }
    html:not(.no-js) [data-aos='flip-up'] {
      transform: perspective(2500px) rotateX(-100deg); }
      html:not(.no-js) [data-aos='flip-up'].aos-animate {
        transform: perspective(2500px) rotateX(0); }
    html:not(.no-js) [data-aos='flip-down'] {
      transform: perspective(2500px) rotateX(100deg); }
      html:not(.no-js) [data-aos='flip-down'].aos-animate {
        transform: perspective(2500px) rotateX(0); } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/styles/images/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: #e3000b;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dots {
  position: absolute;
  bottom: 0;
  left: 50%;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0 0 0 -10px; }
  .slick-dots li {
    position: relative;
    display: block;
    height: 20px;
    width: 20px;
    margin: 6px 0;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 4px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 10px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: #ffffff;
      opacity: 0.75; }

@media (max-width: 3400px) and (min-width: 575px) {
  .translateX {
    transform: translateX(6rem); } }

.stay-open {
  display: block !important; }

.zoom {
  transition: transform .2s;
  /* Animation */
  margin: 0 auto; }

.zoom:hover {
  transform: scale(1.5);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */ }

.equal:before {
  content: '';
  padding-top: 100%; }

.spacer {
  white-space: nowrap; }

.flexi {
  flex: none !important; }

.box-shadow {
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -ms-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out; }

.box-shadow:hover {
  -webkit-box-shadow: 0 20px 40px rgba(72, 78, 85, 0.6);
  box-shadow: 0 20px 40px rgba(72, 78, 85, 0.6);
  -webkit-transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  -o-transform: translateY(-15px);
  transform: translateY(-15px); }

.card {
  border-radius: 25px; }

.card img {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px; }

.card svg {
  position: absolute;
  top: 19rem;
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -ms-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
  filter: drop-shadow(2px -9px 4px rgba(0, 69, 134, 0.2)); }

.card:hover svg {
  filter: drop-shadow(2px -9px 4px rgba(0, 69, 134, 0.4)); }

.stage {
  width: 100vw;
  height: 50.00vh;
  background-position: 50% 70%;
  background-repeat: no-repeat;
  background-size: cover; }
  .stage figure {
    display: none; }

.navbar.fixed-left {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030; }

.text-stroke {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #19191c;
  color: transparent !important; }

.text-primary-shadow {
  text-shadow: 2px 2px 0 #e3000b; }

.text-light-muted {
  color: #a1a1a8; }

.main-navigation .nav-link {
  font-family: "Dosis", Helvetica, Arial, sans-serif;
  font-size: 1.25rem; }

.dropdown-item {
  text-transform: none; }

.company-navigation .nav-link {
  font-family: "Dosis", Helvetica, Arial, sans-serif;
  color: #a1a1a8; }
  .company-navigation .nav-link:hover {
    color: white; }

.block-link {
  display: block;
  text-decoration: none; }

.font-family-brand {
  font-family: "Dosis", Helvetica, Arial, sans-serif !important; }

.footer {
  transform: translateY(-4rem);
  border-radius: 0.25rem; }

.btn-paint {
  position: relative;
  overflow: hidden;
  padding: 16px 62px; }
  .btn-paint .stroke {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    width: 50%;
    height: 120px;
    transition: transform 0.4s ease;
    background-color: #e3000b; }
    .btn-paint .stroke:nth-child(1) {
      transform-origin: 50% 0;
      transform: translate(0%, -25%) rotate(20deg) scale(1, 0); }
    .btn-paint .stroke:nth-child(2) {
      transform-origin: 50% 100%;
      transform: translate(75%, -25%) rotate(-20deg) scale(1, 0);
      transition-delay: 0.2s; }
    .btn-paint .stroke:nth-child(3) {
      transform-origin: 50% 0;
      transform: translate(125%, -25%) rotate(20deg) scale(1, 0);
      transition-delay: 0.4s; }
    .btn-paint .stroke:nth-child(4) {
      transform-origin: 50% 100%;
      transform: translate(175%, -25%) rotate(-20deg) scale(1, 0);
      transition-delay: 0.6s; }
  .btn-paint:hover .stroke:nth-child(1) {
    transform: translate(0%, -25%) rotate(20deg) scale(1, 1); }
  .btn-paint:hover .stroke:nth-child(2) {
    transform: translate(75%, -25%) rotate(-20deg) scale(1, 1); }
  .btn-paint:hover .stroke:nth-child(3) {
    transform: translate(125%, -25%) rotate(20deg) scale(1, 1); }
  .btn-paint:hover .stroke:nth-child(4) {
    transform: translate(175%, -25%) rotate(-20deg) scale(1, 1); }
  .btn-paint .label {
    position: relative;
    z-index: 10;
    color: #ffffff; }

.line-animation-outer {
  position: relative;
  display: block;
  width: 100%;
  height: 100%; }
  .line-animation-outer:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 2px;
    height: 0;
    transition: all 0.1s cubic-bezier(0.7, 0, 0.3, 1);
    background: #e3000b; }
  .line-animation-outer:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 0;
    height: 2px;
    transition: all 0.1s cubic-bezier(0.7, 0, 0.3, 1);
    transition-delay: 0.4s;
    background: #e3000b; }
  .line-animation-outer:hover:before {
    height: 100%; }
  .line-animation-outer:hover:after {
    width: 100%;
    transition-delay: 0.10s; }

.line-animation-inner {
  position: relative;
  display: block;
  width: 100%;
  height: 100%; }
  .line-animation-inner:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 2px;
    height: 0;
    transition: all 0.1s cubic-bezier(0.7, 0, 0.3, 1);
    transition-delay: 0.3s;
    background: #e3000b; }
  .line-animation-inner:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 0;
    height: 2px;
    transition: all 0.1s cubic-bezier(0.7, 0, 0.3, 1);
    transition-delay: 0.2s;
    background: #e3000b; }
  .line-animation-inner:hover:before {
    height: 100%;
    transition-delay: 0.2s; }
  .line-animation-inner:hover:after {
    width: 100%;
    transition-delay: 0.3s; }

.accordion .accordion-item .accordion-header {
  color: white; }
  .accordion .accordion-item .accordion-header .accordion-button {
    font-weight: 500; }
    .accordion .accordion-item .accordion-header .accordion-button:focus {
      box-shadow: none; }
    .accordion .accordion-item .accordion-header .accordion-button:after {
      color: white; }
    .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
      color: white;
      border: 0;
      background-color: #e3000b; }
    .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.accordion .accordion-item .accordion-body {
  background-color: white; }

.lavalamp__object {
  position: absolute;
  border-width: 0 0 2px 0;
  border-style: solid;
  border-color: #e3000b; }

.nav-tabs .nav-link {
  background: transparent !important;
  border-color: transparent !important;
  text-transform: uppercase;
  color: #61616d;
  font-weight: 300; }
  .nav-tabs .nav-link.active {
    color: #19191c; }

.tab-content {
  position: relative; }
  .tab-content .tab-pane {
    transition: all 0.2s;
    width: 100%;
    display: block; }
    .tab-content .tab-pane:not(.active) {
      visibility: hidden;
      position: absolute;
      top: 0;
      animation: fadeOutBottom .25s ease forwards;
      -webkit-animation: fadeOutBottom .25s ease forwards; }
    .tab-content .tab-pane.active {
      top: 0;
      position: relative;
      z-index: 90;
      animation: fadeInTop .5s ease forwards;
      -webkit-animation: fadeInTop .5s ease forwards; }

@keyframes fadeOutBottom {
  from {
    opacity: 1;
    transform: translate(0, 0) scale(1); }
  to {
    opacity: 0;
    transform: translate(0, 10%) scale(0.8);
    -webkit-font-smoothing: antialiased; } }

@-webkit-keyframes fadeOutBottom {
  from {
    opacity: 1;
    transform: translate(0, 0); }
  to {
    opacity: 0;
    transform: translate(0, 10%);
    -webkit-font-smoothing: antialiased; } }

@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translate(0, 10%); }
  to {
    opacity: 1;
    transform: translate(0, 0);
    -webkit-font-smoothing: antialiased; } }

@-webkit-keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translate(0, 10%); }
  to {
    opacity: 1;
    transform: translate(0, 0);
    -webkit-font-smoothing: antialiased; } }

.list-group-borderless .list-group-item {
  border: none;
  background-color: transparent; }

.accordion.accordion-icon .accordion-item {
  border: none;
  background-color: transparent; }
  .accordion.accordion-icon .accordion-item .accordion-body {
    background-color: transparent; }

.separator-top {
  border-top: 1px solid rgba(25, 25, 28, 0.1); }

.separator-bottom {
  border-top: 1px solid rgba(25, 25, 28, 0.1); }

.tile {
  position: relative;
  overflow: hidden;
  border-radius: 0.25rem;
  mask-image: radial-gradient(white, red); }
  .tile:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%; }
  .tile-header, .tile-footer {
    transition: all 0.2s;
    transition-delay: 0s;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 1.875rem; }
  .tile-footer {
    top: auto;
    bottom: 0; }
  .tile-content:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background: rgba(0, 0, 0, 0.2); }
  .tile-image {
    transition: all 0.2s;
    transition-delay: 0s;
    background-size: cover;
    background-position: 50% 50%; }
    .tile-image img {
      display: block;
      object-fit: cover;
      height: 200px; }
  .tile:hover .tile-image {
    transform: scale(1.05); }
  .tile:hover .tile-header {
    transform: translate(0, 5%); }
  .tile:hover .tile-footer {
    transform: translate(0, -5%); }

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0; }

.carousel-gallery-item figure img {
  display: block;
  width: 100%;
  height: auto; }

.carousel-gallery-item header {
  position: absolute;
  bottom: 20%;
  left: 0; }
